<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div class="error-block">
        <span v-if="validated && errors" class="error-message">{{ errors }}</span>
      </div>
      <ckeditor
        @input="onChange"
        :editor="editor.type"
        :modelValue="text"
        :config="editor.Config"
      />
    </div>
    <label v-if="label"> {{ label }}</label>
  </div>
</template>
<script>
import { getError } from "../../utils/validate"; // méthode qui rétourne le message d'erreur d'une règle de validation

import MyUploadAdapter from "../../utils/MyUploadAdapter.js";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

export default {
  emits: ["update:modelValue"],
  props: {
    label: {
      // label du champ
      type: String,
      default: null,
    },
    modelValue: {
      // valeur du champ qui sera mise à jour par la méthode onChange
      type: String,
      default: null,
    },
    rules: {
      // Règles de validation du champ
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      validated: false,
      text: this.modelValue,
      editor: {
        type: ClassicEditor,
        Config: {
          fontFamily: {
            options: [
              "default",
              "Ubuntu, Arial, sans-serif",
              "Ubuntu Mono, Courier New, Courier, monospace",
            ],
          },
          extraPlugins: [this.MyCustomUploadAdapterPlugin],
          image: {
            styles: ["alignLeft", "alignCenter", "alignRight", "full"],
            toolbar: [
              "imageStyle:alignLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignRight",
              "imageStyle:full",
            ],
          },
          toolbar: {
            items: [
              "heading",
              "paragraph",
              "|",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "|",
              "bold",
              "italic",
              "underline",
              "alignment",
              "|",
              "numberedList",
              "bulletedList",
              "|",
              "link",
              "imageUpload",
              "insertTable",
            ],
            shouldNotGroupWhenFull: true,
          },
          link: {
            decorators: {
              addTargetToExternalLinks: {
                mode: "automatic",
                callback: url => /^(https?:)?\/\//.test(url),
                attributes: {
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    errors() {
      // valider le champ par les règles qui ont été fournises dans les props
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },
  methods: {
    MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new MyUploadAdapter(loader);
      };
    },
    onChange(e) {
      this.$emit("update:modelValue", e);
    },
    validate() {
      // Indiquer qu'on peut appeler la validation du champ
      this.validated = true;
    },
  },
};
</script>
