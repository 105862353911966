<template>
  <div class="row" v-if="!loading">
    <div class="col-12 col-sm-12 col-lg-7 col-xl-6">
      <custom-input-type
        class="inline inline-right"
        label="Nom *"
        type="text"
        ref="image.nom"
        v-model="image.nom"
        :rules="validations.nom"
      />
      <custom-input-type
        class="inline inline-right"
        label="Description"
        type="text"
        v-model="image.description"
        ref="image.description"
      />
      <field-radio-buttons
        class="inline inline-right"
        label="Type *"
        :buttons="optionsType"
        v-model="image.type"
        ref="image.type"
        :rules="validations.type"
      />
      <field-radio-buttons
        class="inline inline-right"
        label="Source *"
        :buttons="optionsSource"
        v-model="image.source"
        ref="image.source"
        :rules="validations.source"
      />
      <custom-input-type
        class="inline inline-right subfield-no_label"
        v-if="image.source === 'AUTRE'"
        label=""
        source="text"
        v-model="image.source_autre"
        ref="image.source_autre"
        :rules="validations.source_autre"
      />
      <custom-multi-check-box
        class="inline inline-right"
        label="Marché *"
        :buttons="optionsMarche"
        v-model="image.marche"
        ref="image.marche"
        :rules="validations.marche"
      />
    </div>
    <div class="col-12 col-sm-12 col-lg-5 col-xl-6">
      <field-dropzone-file
        specificPath="referentiel"
        label="Image *"
        v-model="image.image"
        ref="image.image"
        :rules="validations.image"
      />
    </div>
  </div>
  <div class="form-footer flex-btn-group center">
    <router-link class="btn btn-login btn-secondary" :to="{ name: 'Admin.Referentiel.Images' }"
      >Annuler</router-link
    >
    <button class="btn btn-login btn-secondary" @click="onSubmit">Valider</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../../components/customize/FieldSelect.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";

import Loading from "../../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../../utils/validate";

import { map, omit, isString } from "lodash";
import CustomCheckBox from "../../../../components/customize/CustomCheckBox.vue";
import CustomMultiCheckBox from "../../../../components/customize/CustomMultiCheckBox.vue";

export default {
  components: {
    CustomInputType,
    Loading,
    FieldRadioButtons,
    FieldSelect,
    FieldDropzoneFile,
    CustomCheckBox,
    CustomMultiCheckBox,
  },
  name: "FormImage",
  emits: ["annuler"],
  data() {
    return {
      optionsType: [
        { label: "Logo", value: "LOGO" },
        { label: "Visuel", value: "VISUEL" },
        { label: "Bandeau", value: "BANDEAU" },
      ],
      optionsSource: [
        { label: "Cerise", value: "CERISE" },
        { label: "Shutterstock", value: "SHUTTERSTOCK" },
        { label: "Adobstock", value: "ADOBSTOCK" },
        { label: "Autre", value: "AUTRE" },
      ],
      optionsMarche: [
        { label: "PART", value: "PART" },
        { label: "AGRI", value: "AGRI" },
        { label: "ACPS", value: "ACPS" },
        { label: "ENT", value: "ENT" },
        { label: "COLL", value: "COLL" },
        { label: "ASSO", value: "ASSO" },
        { label: "TOUS", value: "TOUS" },
      ],
      image: {
        nom: "",
        description: "",
        type: null,
        type_autre: null,
        source: "",
        marche: [],
        image: null,
      },
      validations: {
        nom: {
          required: "Merci de renseigner un nom",
        },
        description: {
          required: "Merci de renseigner une description",
        },
        type: {
          required: "Merci de renseigner un type",
        },
        source: {
          required: "Merci de renseigner une source",
        },
        marche: {
          required: "Merci de renseigner un marché",
        },
        image: {
          required: "Merci de télécharger une image",
          accept: {
            message: "Les formats autorisés sont : jpg, jpeg et png",
            extensions: ["jpg", "jpeg", "png"],
          },
          weight: {
            message: "la taille maximum est de 3Mo",
            max: 3,
          },
        },
      },
      formValid: true,
    };
  },
  computed: {
    ...mapGetters(["usersListe", "loading", "referentielImage"]),
    users() {
      return map(this.usersListe, u => {
        return {
          label: `${u.prenom} ${u.nom}`,
          value: u.user_id,
        };
      });
    },
  },
  methods: {
    ...mapActions([
      "addReferentielImagesListe",
      "setLoading",
      "getReferentielImage",
      "downloadImageReferentiel",
    ]),
    onSubmit() {
      this.checkValidations();
      if (!this.formValid) {
        return document.getElementsByClassName("content")[0].scroll(0, 0);
      }
      let formData = new FormData();
      if (!isString(this.image.image)) {
        formData.append("file", this.image.image);
      }
      return (
        this.setLoading(true)
          // on créé le referentiel sans l'image dans les params
          .then(() => this.addReferentielImagesListe(omit(this.image, "image")))
          .then(referentielId => {
            if (!isString(this.image.image)) {
              formData.append("referentiel_id", referentielId);
              return this.downloadImageReferentiel(formData);
            }
          })
          .then(res => {
            return this.$router.push({ name: "Admin.Referentiel.Images" });
          })
          .catch(e => {
            this.setLoading(false);
            alert(e.response?.data?.message || e);
          })
          // puis on télécharge l'image sur le serveur
          .catch(e => {
            this.setLoading(false);
            alert(e.response.data.message);
          })
      );
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },

  mounted() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielImage = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielImage = this.$route.params.id;
        }
        if (idReferentielImage) {
          return this.getReferentielImage({ id: idReferentielImage });
        }
      })
      .then(e => {
        console.log("image", e);
        if (e) this.image = { ...e };
      })
      .then(() => this.setLoading(false));
  },
};
</script>

<style></style>
