<template>
  <div class="content-transparent flex-grow-1">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
        <div class="row justify-content-md-center" v-if="!loading">
          <div class="col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8">
            <div class="bilan-title text-center" v-if="isEmail">
              <span v-if="statGlobale.objet">Objet : </span><strong> {{ statGlobale.objet }} </strong>
            </div>
            <div class="bilan-title text-center" v-if="isRichSMS">
              <span v-if="statGlobale.texte_sms">Texte du sms: </span><strong> {{ statGlobale.texte_sms }} </strong>
            </div>
            <div id="stats">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                  <table class="table table-stats">
                    <tr>
                      <td>Nb d'envoi</td>
                      <td>{{ statGlobale.nbr_envois }}</td>
                    </tr>
                    <tr>
                      <td>Taux d'ouverture</td>
                      <td>{{ convertFloat(statGlobale.tx_ouverture) }} %</td>
                    </tr>
                    <tr>
                      <td>Nbre d'ouvreurs</td>
                      <td>{{ statGlobale.nbr_ouverture }}</td>
                    </tr>
                    <tr v-if="isEmail">
                      <td>Taux d'HB</td>
                      <td>{{ convertFloat(statGlobale.tx_hardbounce) }} %</td>
                    </tr>
                    <tr v-if="isRichSMS">
                      <td>Taux d'ouverture android</td>
                      <td>{{ convertFloat(statGlobale.tx_ouv_android) }} %</td>
                    </tr>
                  </table>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                  <table class="table table-stats">
                    <tr v-if="isEmail">
                      <td>Tx désinscription</td>
                      <td>{{ convertFloat(statGlobale.tx_desinscription) }} %</td>
                    </tr>
                    <tr v-if="isRichSMS">
                      <td>Taux d'ouverture ios</td>
                      <td>{{ convertFloat(statGlobale.tx_ouv_ios) }} %</td>
                    </tr>
                    <tr>
                      <td>Taux de clics</td>
                      <td>{{ convertFloat(statGlobale.tx_clic) }} %</td>
                    </tr>
                    <tr>
                      <td>Nbre de cliqueurs</td>
                      <td>{{ statGlobale.nbr_clic }}</td>
                    </tr>
                    <tr>
                      <td>Tx réactivité</td>
                      <td>{{ convertFloat(statGlobale.tx_reactivite) }} %</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <template v-if="statPerformance.length > 0">
              <div class="bilan-title text-center mt-3">
                <strong> Performance du ciblage </strong>
              </div>
              <div id="stats_performance">
                <div class="row">
                  <table class="table table-stats">
                    <tr>
                      <th>Variable +++</th>
                      <th>Variable ---</th>
                    </tr>
                    <tr v-for="(performance, index) in statPerformance" :key="index">
                      <td>{{ performance.var_positive }}</td>
                      <td>{{ performance.var_negative }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </template>

          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 col-xl-4">
        <div class="minH-100" v-if="LienMiroir">
          <a class="img-link" :href="LienMiroir" target="_blank"
            ><img :src="UrlImageMiroir" alt="Image Miroir"
          /></a>
        </div>
        <div class="minH-100" v-else>
          <support-url-visuel />
        </div>
      </div>
      <div id="cta">
        <div v-if="statCTA.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        sorting.nbr_clic = !sorting.nbr_clic;
                        sortListe('nbr_clic', sorting.nbr_clic);
                      "
                    >
                      <span>Nombre de clic</span>
                      <i :class="`far  ${sorting.nbr_clic ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="th-title">
                    <a
                      @click="
                        sorting.url = !sorting.url;
                        sortListe('url', sorting.url);
                      "
                    >
                      <span>Lien du CTA</span>
                      <i :class="`far  ${sorting.url ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                    </a>
                  </div>
                  <input type="text" v-model="searching.url" @input="filterListe()" />
                </th>
              </tr>
            </thead>
            <tbody v-if="pageListe && pageListe.length > 0">
              <tr v-for="(cta, index) in pageListe" :key="index">
                <td>{{ cta.nbr_clic }}</td>
                <td>{{ cta.url }}</td>
              </tr>
            </tbody>
            <div v-else>Aucun résultat</div>
          </table>
        </div>
        <div class="pagination-wrapper">
          <div class="pagination" v-if="nbPages > 1">
            <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
              <i class="far fa-chevron-left" />
            </button>
            <button
              :class="[i === currentPage + 1 && 'active', 'page-number']"
              v-for="i in nbPages"
              :key="i"
              @click="nextPage(i)"
            >
              <span>{{ i }}</span>
            </button>
            <button
              class="page-number"
              v-if="currentPage < nbPages - 1"
              @click="nextPage(currentPage + 2)"
            >
              <i class="far fa-chevron-right" />
            </button>
          </div>
          <custom-basic-select :options="nbpageOptions" v-model="nbPerPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supportUrlVisuel from "../shared/supportUrlVisuel.vue";
import { map } from "lodash";
import CustomBasicSelect from "../../../../components/customize/CustomBasicSelect.vue";
import { mixinAction } from "../../utils";
export default {
  components: { supportUrlVisuel, CustomBasicSelect },
  name: "DetailsStats",
  mixins: [mixinAction],
  data() {
    return {
      nbPages: 0,
      nbPerPage: 7,
      currentPage: 0,
      currentListe: [...this.statCTA],
      nbpageOptions: { 7: "7/page", 14: "14/page", 21: "21/page", 28: "28/page" },
      sorting: {
        nbr_clic: false,
        url: false,
      },
      searching: {
        url: null,
      },
    };
  },
  props: {
    statCTA: {
      type: Array,
      default: () => [],
    },
    statGlobale: {
      type: Object,
      default: () => {},
    },
    statPerformance: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    LienMiroir() {
      return this.workflow?.envoi_lien;
    },
    UrlImageMiroir() {
      return `${process.env.VUE_APP_API_URL}/uploads/image-miroir-${this.workflow.support_id}.png`;
    },
    pageListe() {
      let n = this.currentPage * this.nbPerPage;
      let m = n + this.nbPerPage;
      if (this.currentListe) {
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
      }
      return this.currentListe.slice(n, m);
    },
  },
  methods: {
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = [...this.statCTA];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(user => {
            return regex.test(user[key]);
          });
        }
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key] < b[key]) {
        return sortKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortKey ? 1 : -1;
      }
      return 0;
    },
    convertFloat(f) {
      return (Math.round(f * 100) / 100).toString().replace(".", ",");
    },
  },
  watch: {
    statCTA: {
      handler(val) {
        this.currentListe = [...val];
      },
    },
  },
};
</script>

<style></style>
