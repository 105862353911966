<template>
  <div :class="['field dropzone', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <div class="dropzone-container">
        <div ref="dropDiv" class="btn" v-if="!getUrl" @drop="loadFile" @click="$refs.file.click()">
          <i class="fas fa-cloud-upload-alt"></i>
          <span>Drag & Drop</span>
        </div>
        <div v-if="getUrl">
          <div v-if="isImage(getUrl.fileName)" class="uploaded-picture">
            <img :src="getUrl.url" alt="Visuel" />
            <a class="remove" @click="removeFile">
              <i class="far fa-times"></i>
            </a>
          </div>
          <div v-else class="uploaded-file">
            <a class="file-name" :href="getUrl.url" target="_blank">
              {{ getUrl.fileName }}
            </a>
            <a class="remove" @click="removeFile">
              <i class="far fa-times"></i>
            </a>
          </div>
        </div>
        <input
          type="file"
          v-else
          id="file"
          name="file"
          ref="file"
          @change="loadFile"
          :accept="acceptFilesType"
        />
      </div>
    </div>
    <label>{{ label }}</label>
  </div>
</template>

<script>
import { isString, includes } from "lodash";
import { getError } from "../../utils/validate";

export default {
  emits: ["update:modelValue"],
  props: {
    specificPath: {
      // si l'url de l'image est dans un repertoire spécifique
      type: String,
      default: null,
    },
    acceptFilesType: {
      type: String,
      default: "['image/png', 'image/jpeg', 'image/jpg', 'image/svg']",
    },

    label: {
      // label du champ
      type: String,
      default: null,
    },
    modelValue: {
      // valeur du champ qui sera mise à jour par la méthode onChange
      type: [String, Number, Date, Boolean, Object],
      default: null,
    },
    rules: {
      // Règles de validation du champ
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      file: null,
      validated: false,
    };
  },
  computed: {
    getUrl() {
      if (!this.modelValue) {
        return null;
      }
      if (isString(this.modelValue)) {
        return {
          url: `${process.env.VUE_APP_API_URL}/uploads/${
            this.specificPath ? this.specificPath + "/" : ""
          }${this.modelValue}`,
          fileName: this.modelValue,
        };
      } else {
        return { url: URL.createObjectURL(this.modelValue), fileName: this.modelValue.name };
      }
    },
    errors() {
      // valider le champ par les règles qui ont été fournises dans les props
      // Si on revient en edition sur le referentiel image, nous avons pas le fichier, mais nous avons l'url du fichier si la personne n'a pas fais de modification
      if (!isString(this.modelValue)) {
        return getError(this.file ? { file: this.file } : null, { ...this.rules }, {});
      }
    },
  },
  methods: {
    isImage(url) {
      if (isString(url)) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
      } else {
        return url;
      }
    },
    loadFile(e) {
      const file = e.dataTransfer
        ? e.dataTransfer.files[0]
        : e.srcElement
        ? e.srcElement.files[0]
        : null;
      if (file) {
        if (includes(this.acceptFilesType, file.type)) {
          this.file = file;
          this.$emit("update:modelValue", file);
        }
      }
    },
    removeFile() {
      this.file = null;
      this.$emit("update:modelValue", this.file);
    },
    validate() {
      this.validated = true;
    },
  },
  mounted() {
    [
      /* Listen to all of the drag events and bind an event listener to each for the fileform. */
      ("drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"),
    ].forEach(
      function (evt) {
        if (this.$refs && this.$refs.dropDiv) {
          this.$refs.dropDiv.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        }
      }.bind(this)
    );
  },
};
</script>
<style scoped></style>
