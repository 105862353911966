<template>
  <div class="content tab-content">
    <div v-if="showStat">
      <details-stats :statCTA="statCTA" :statGlobale="statGlobale" :statPerformance="statPerformance" />
    </div>
    <div class="row justify-content-between" v-else>
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7">
        <div v-if="toDo || canEdit">
          <form @submit.prevent="submit">
            <div class="form-col-title">STATS</div>
            <field-dropzone-file
              class="inline"
              label="Stats globales * :"
              acceptFilesType="['text/csv','.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']"
              v-model="stats.globales"
              ref="stats.globales"
              :rules="validations.file"
            />
            <field-dropzone-file
              class="inline"
              label="Stats CTA * :"
              acceptFilesType="['text/csv','.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']"
              v-model="stats.cta"
              ref="stats.cta"
              :rules="validations.file"
            />
            <field-dropzone-file
              class="inline"
              label="Performance :"
              acceptFilesType="['text/csv','.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']"
              v-model="stats.performance"
              ref="stats.performance"
            />
            <div class="flex-btn-group center mt-4">
              <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
              <button class="btn btn-secondary">Valider</button>
            </div>
          </form>
        </div>
        <div v-else>
          <div class="form-col-title">Statut</div>
          <div class="text-center">Statistiques en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { SUPPORT_STATUS } from "../../../../../config/Properties";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import { mixinAction } from "../../utils";
import DetailsStats from "./DetailsStats.vue";

export default {
  components: {
    FieldDropzoneFile,
    SupportUrlVisuel,
    DetailsStats,
  },
  name: "WorkflowStats",
  data() {
    return {
      stats: { globales: null, cta: null, performance: null },
      showStat: false,
      idStat: null,
    };
  },
  mixins: [mixinAction],
  computed: {
    validations() {
      return {
        file: {
          required: "Merci de choisir un fichier",
        },
      };
    },
    toDo() {
      return (
        this.workflow.statut === SUPPORT_STATUS.STATS_A_FAIRE && (this.isDATA || this.hasAction)
      );
    },
    isDATA() {
      return this.supportEquipe?.DATA?.user?.email === this.currentUser?.email;
    },
    canEdit() {
      return (
        [SUPPORT_STATUS.TERMINE, SUPPORT_STATUS.A_ANALYSER].includes(this.workflow.statut) &&
        (this.isDATA || this.hasAction)
      );
    },
    statGlobale() {
      return this.statsGlobales[this.idStat - 1] || [];
    },
    statCTA() {
      return (
        this.statsCTAs.filter(cta => {
          return cta.libelle === this.statGlobale.libelle;
        }) || null
      );
    },
    statPerformance() {
      return (
        this.statsPerformance.filter(cta => {
          return cta.libelle === this.statGlobale.libelle;
        }) || null
      );
    },
  },
  methods: {
    ...mapActions(["updateSupportStatut", "uploadStats"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.uploadStats({
        stats_globales: this.stats.globales,
        stats_cta: this.stats.cta,
        stats_performance: this.stats.performance,
        support_id: this.workflow.support_id,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    setStatView(index) {
      // index = 1,2...n
      if (index) {
        this.showStat = true;
        this.idStat = index;
      } else {
        this.showStat = false;
        this.idStat = null;
      }
    },
  },
  watch: {
    $route: {
      handler(val) {
        let index = val.params.id_stat;
        this.setStatView(index);
      },
    },
    hasStats: {
      handler(val) {
        if (val) {
          this.$router.push({
            name: "Workflow.WorkflowEtape",
            params: { etape: "stats", id_stat: 1 },
          });
        }
      },
    },
  },
  mounted() {
    this.setStatView(this.$route.params.id_stat);
  },
};
</script>
