<template>
  <div :class="['field dropzone', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <div class="flex-grow-1">
        <div class="row">
          <div class="col-4">
            <div class="dropzone-container">
              <div ref="dropDiv" class="btn" @drop="loadFiles" @click="$refs.files.click()">
                <i class="fas fa-cloud-upload-alt" />
                <span>Drag & Drop</span>
              </div>
              <input type="file" id="files" name="files" ref="files" @change="loadFiles" multiple />
            </div>
          </div>
          <div class="col-12">
            <div class="uploaded-files-wrapper">
              <template v-for="(file, index) in files" :key="index">
                <template v-if="file">
                  <div class="uploaded-file" v-if="hasLink">
                    <div class="uploaded-file-infos">
                      <i class="far fa-file" />
                      <a :href="getUrl(file)" target="_blank">{{ getFileName(file) }}</a>
                    </div>
                    <a class="remove" @click="removeFile(index)">
                      <i class="far fa-xmark" />
                    </a>
                  </div>
                  <div class="uploaded-picture" v-else>
                    <img :src="getUrl(file)" alt="Visuel" />
                    <a class="remove" @click="removeFile(index)">
                      <i class="far fa-xmark" />
                    </a>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <label>{{ label }}</label>
  </div>
</template>

<script>
import { isString, compact } from "lodash";
import { getError } from "../../utils/validate";

export default {
  emits: ["update:modelValue"],
  props: {
    specificPath: {
      // si l'url de l'image est dans un repertoire spécifique
      type: String,
      default: null,
    },
    label: {
      // label du champ
      type: String,
      default: null,
    },
    modelValue: {
      // valeur du champ qui sera mise à jour par la méthode onChange
      type: [Array],
      default: [],
    },
    rules: {
      // Règles de validation du champ
      type: Object,
      default: {},
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: this.modelValue || [],
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(
        this.modelValue && this.modelValue[0] ? { files: this.files } : null,
        { ...this.rules },
        {}
      );
    },
  },
  methods: {
    getUrl(file) {
      if (!file) {
        return null;
      }

      if (isString(file)) {
        return `${process.env.VUE_APP_API_URL}/uploads/${
          this.specificPath ? this.specificPath + "/" : ""
        }${file}`;
      } else {
        return URL.createObjectURL(file);
      }
    },
    loadFiles(e) {
      const filesDrop = e.target.files;
      console.log("Drop", filesDrop, e);
      if (filesDrop) {
        this.files = [...this.files, ...filesDrop];
        console.log(this.files, compact(this.files));
        this.$emit("update:modelValue", compact(this.files));
      }
    },
    removeFile(index) {
      delete this.files[index];

      this.files = compact(this.files);
      this.$emit("update:modelValue", this.files);
    },
    validate() {
      // Indiquer qu'on peut appeler la validation du champ
      this.validated = true;
    },
    getFileName(file) {
      return isString(file) ? file : file.name;
    },
  },
  mounted() {
    [
      /* Listen to all of the drag events and bind an event listener to each for the fileform. */
      ("drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"),
    ].forEach(
      function (evt) {
        if (this.$refs && this.$refs.dropDiv) {
          this.$refs.dropDiv.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        }
      }.bind(this)
    );
  },
};
</script>
<style scoped></style>
