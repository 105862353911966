<template>
  <loading v-if="loading" />
  <div class="row justify-content-center" v-else>
    <div class="col-9 col-md-12">
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
          <custom-input-type
            class="inline inline-right"
            label="Nom * :"
            type="text"
            ref="boite.nom"
            v-model="boite.nom"
            :rules="validations.nom"
          />
          <custom-input-type
            class="inline inline-right"
            label="Id adobe * :"
            type="text"
            ref="boite.id_adobe"
            v-model="boite.id_adobe"
            :rules="validations.id_adobe"
          />
          <custom-input-type
            class="inline inline-right"
            label="description:"
            type="text"
            v-model="boite.description"
          />
          <field-radio-buttons
            label="Précoché"
            class="inline center"
            :buttons="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            v-model="boite.pre_selectionne"
          />
        </div>
      </div>
    </div>
    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-login btn-secondary" :to="{ name: 'Admin.Referentiel.Boite' }"
        >Annuler</router-link
      >
      <button class="btn btn-login btn-secondary" @click="onSubmit">Valider</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import Loading from "../../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../../utils/validate";

export default {
  components: {
    CustomInputType,
    Loading,
    FieldRadioButtons,
  },
  name: "FormBoite",
  data() {
    return {
      boite: {
        nom: null,
        id_adobe: null,
        description: null,
      },
      validations: {
        nom: {
          required: "Merci de renseigner un nom",
        },
        id_adobe: {
          required: "Merci de renseigner un id adobe",
        },
      },
      formValid: true,
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["setLoading", "addReferentielBoite", "getReferentielBoite"]),
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    onSubmit() {
      this.checkValidations();
      if (!this.formValid) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() => this.addReferentielBoite({ ...this.boite }))
        .then(() => this.$router.push({ name: "Admin.Referentiel.Boite" }))
        .catch(e => alert(e.response?.data?.message || e))
        .then(() => this.setLoading(false));
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielBoite = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielBoite = this.$route.params.id;
        }
        if (idReferentielBoite) {
          return this.getReferentielBoite({ id: idReferentielBoite });
        }
      })
      .then(boite => {
        if (boite) this.boite = { ...boite };
      })
      .then(() => this.setLoading(false));
  },
};
</script>

<style></style>
