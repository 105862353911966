<template>
  <loading v-if="loading" />
  <router-view></router-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../../components/layouts/Loading.vue";
export default {
  components: { Loading },
  name: "AdminSupports",
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["setSupportWorkflow"]),
  },
  mounted() {
    return this.setSupportWorkflow({});
  },
};
</script>

<style></style>
