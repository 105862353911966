// users
import { roles } from "../../authentification";
import AdminUsers from "./IndexUsers";
// import AdminCreateUser from "./CreateUser.vue";
// import AdminEditUser from "./EditUser.vue";
import AdminListeUsers from "./ListeUsers.vue";

export const routes = [
  {
    path: "users",
    name: "Admin.Users",
    component: AdminUsers,
    redirect: "/admin/users/listeusers",
    children: [
      {
        path: "listeusers",
        name: "Admin.ListeUsers",
        component: AdminListeUsers,
      },
      // {
      //   path: "createuser",
      //   name: "Admin.CreateUser",
      //   component: AdminCreateUser,
      // },
      // {
      //   path: "edituser/:id?",
      //   name: "Admin.EditUser",
      //   component: AdminEditUser,
      // },
    ],
    meta: {
      authRequired: true,
      roles: [roles.ADMIN, roles.COORDINATEUR],
    },
  },
];
