<template>
  <div class="followup">
    <div class="filters">
      <div class="type-filter filter">
        <span class="filter-title">Type de support : </span>
        <field-radio-buttons :buttons="filterTypeBtns" v-model="filterTypeActive" @click="filterList" />
      </div>
      <div class="date-filter filter">
        <span class="filter-title">Echéance : </span>
        <field-radio-buttons :buttons="filterDateBtns" v-model="filterDateActive" @click="filterList" />
      </div>
    </div>
    <div class="content tab-content">
      <table class="table">
        <thead>
          <tr>
            <!-- Type -->
            <th></th>

            <!-- Nom -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.nom = !sorting.nom;
                    sortList('nom', sorting.nom);
                  "
                >
                  <span>Nom</span>
                  <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <custom-input-type type="text" v-model="searching.nom" @input="filterList()" />
            </th>

            <!-- Date Brief -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.brief_date = !sorting.brief_date;
                    sortList('brief_date', sorting.brief_date);
                  "
                >
                  <span>Brief</span>
                  <i :class="`far  ${sorting.brief_date ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.brief_date" @input="filterList()" />
            </th>

            <!-- Date Intégration -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.integration_date = !sorting.integration_date;
                    sortList('integration_date', sorting.integration_date);
                  "
                >
                  <span>Inté. / Créa.</span>
                  <i :class="`far  ${sorting.integration_date ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.integration_date" @input="filterList()" />
            </th>

            <!-- Date ML -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.mentions_legales_date = !sorting.mentions_legales_date;
                    sortList('mentions_legales_date', sorting.mentions_legales_date);
                  "
                >
                  <span>ML</span>
                  <i :class="`far  ${sorting.mentions_legales_date ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.mentions_legales_date" @input="filterList()" />
            </th>

            <!-- Date Validation Num. -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.html_date = !sorting.html_date;
                    sortList('html_date', sorting.html_date);
                  "
                >
                  <span>Validation</span>
                  <i :class="`far  ${sorting.html_date ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.html_date" @input="filterList()" />
            </th>

            <!-- Date BAT -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.bat_date = !sorting.bat_date;
                    sortList('bat_date', sorting.bat_date);
                  "
                >
                  <span>BAT</span>
                  <i :class="`far  ${sorting.bat_date ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.bat_date" @input="filterList()" />
            </th>

            <!-- Date Envoi -->
            <th>
              <div class="th-title">
                <a
                  @click="
                    sorting.date_envoi_souhaitee = !sorting.date_envoi_souhaitee;
                    sortList('date_envoi_souhaitee', sorting.date_envoi_souhaitee);
                  "
                >
                  <span>Envoi</span>
                  <i :class="`far  ${sorting.date_envoi_souhaitee ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
                </a>
              </div>
              <field-date-interval v-model="searching.date_envoi_souhaitee" @input="filterList()" />
            </th>

            <!-- Actions-->
            <th>
              <button class="btn" @click="resetFilter">
                <i class="far fa-sync" /><span>Réinitialiser</span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody v-if="pageList.length > 0">
          <follow-up-details
            v-for="support in pageList"
            :key="support.support_id"
            :support="support"
            @editSupport="onEdit"
            @relancerSupport="onRelancer"
          />
        </tbody>
        <div v-else>Aucun résultat</div>
      </table>
    </div>
    <div v-if="nbPages > 1">
      <div class="pagination">
        <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
          <i class="far fa-chevron-left" />
        </button>
        <button
          :class="[i === currentPage + 1 && 'active', 'page-number']"
          v-for="i in nbPages"
          :key="i"
          @click="nextPage(i)"
        >
          <span>{{ i }}</span>
        </button>
        <button
          class="page-number"
          v-if="currentPage < nbPages - 1"
          @click="nextPage(currentPage + 2)"
        >
          <i class="far fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
  <custom-modal
    v-if="modal_props.showModal"
    @close="modal_props.showModal = false"
    @confirm="onModalAction(idSupportToAction, modal_props.modalAction)"
    :hasFooter="modal_props.hasFooter"
  >
    <template v-slot:modalTitle> Confirmation </template>
    <template v-slot:modalBody> {{ modal_props.modalMessages }}</template>
  </custom-modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { map } from "lodash";
import {
  SUPPORT_STATUS,
  SUPPORT_TYPES,
  FOLLOWUP_DATE_FILTERS,
  FOLLOWUP_ACTION_TYPES
} from "../../config/Properties";
import CustomInputType from "./customize/CustomInputType.vue";
import FieldDateInterval from "./customize/FieldDateInterval";
import CustomModal from "./customize/CustomModal.vue";
import FollowUpDetails from "./FollowUpDetails.vue";
import FieldRadioButtons from "./customize/FieldRadioButtons.vue";

moment.locale("fr");

export default {
  name: "FollowUp",
  components: { FollowUpDetails, CustomInputType, FieldRadioButtons, CustomModal, FieldDateInterval },
  data() {
    return {
      nbPages: 0,
      nbPerPage: 30,
      currentPage: 0,
      sorting: {},
      searching: {},
      events: [],
      filterTypeBtns: [
        { label: "Email", value: SUPPORT_TYPES.EMAIL },
        { label: "Rich SMS", value: SUPPORT_TYPES.RICH_SMS },
        { label: "PLV", value: SUPPORT_TYPES.PLV }
      ],
      filterDateBtns: [
        { label: FOLLOWUP_DATE_FILTERS.TODAY, value: FOLLOWUP_DATE_FILTERS.TODAY },
        { label: FOLLOWUP_DATE_FILTERS.THIS_WEEK, value: FOLLOWUP_DATE_FILTERS.THIS_WEEK },
        { label: FOLLOWUP_DATE_FILTERS.LATE, value: FOLLOWUP_DATE_FILTERS.LATE }
      ],
      filterTypeActive: null,
      filterDateActive: null,
      modal_props: {
        showModal: false,
        modalMessages: null,
        modaleAction: null,
        hasFooter: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      "supportsListe",
      "loading"
    ]),
    pageList() {
      let n = this.currentPage * this.nbPerPage;
      let m = n + this.nbPerPage;
      this.nbPages = Math.ceil(this.events.length / this.nbPerPage);
      return this.events.slice(n, m);
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setSupportsListe",
      "relancerSupport"
    ]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterList() {
      this.currentPage = 0;
      this.events = this.filterActiveSupports(this.supportsListe);
      this.events = this.filterSupportsByType();
      this.events = this.filterSupportsByDate();
      map(this.searching, (search, key) => {
        if (search) {
          search = ["statut", "analyse_stats_statut"].includes(key) ? "^" + search : search;
          let regex = new RegExp(search, "i");
          this.events = this.events.filter(support => {
            switch (key) {
              case "brief_date":
              case "integration_date":
              case "mentions_legales_date":
              case "html_date":
              case "bat_date":
              case "date_envoi_souhaitee":
              case "date_validite":
                let searchedDate = support[key] ? this.$moment(support[key], "YYYY-MM-DD") : support.etapes_dates[key] ? this.$moment(support.etapes_dates[key], "YYYY-MM-DD") : "";
                if (searchedDate === "") {
                  return false;
                }
                return search.startDate && search.endDate
                  ? searchedDate.isBetween(search.startDate, search.endDate, null, "[]")
                  : search.startDate
                  ? searchedDate.isSameOrAfter(search.startDate)
                  : search.endDate
                  ? searchedDate.isSameOrBefore(search.endDate)
                  : true;
              default:
                return regex.test(support[key]);
            }
          });
        }
      });
    },
    filterActiveSupports(supports) {
      return supports.filter(support =>
        !support.is_archive &&
        !support.statut !== SUPPORT_STATUS.A_ANALYSER &&
        !support.statut !== SUPPORT_STATUS.A_LIVRER
      );
    },
    filterSupportsByType() {
      return this.filterTypeActive === null ? this.events : this.events.filter(support =>
        support.type === this.filterTypeActive
      );
    },
    filterSupportsByDate() {
      return this.filterDateActive === null ? this.events : this.events.filter(support => {
        let supportDate = moment(support.date_envoi_souhaitee).endOf("day");

        if (this.filterDateActive === FOLLOWUP_DATE_FILTERS.TODAY) {
          const startDateDay = moment().startOf("day");
          const endDateDay = moment().endOf("day");
          return supportDate.isBetween(startDateDay, endDateDay);
        } else if (this.filterDateActive === FOLLOWUP_DATE_FILTERS.THIS_WEEK) {
          const startDateWeek = moment().startOf("week");
          const endDateWeek = moment().endOf("week");
          return supportDate.isBetween(startDateWeek, endDateWeek);
        } else if (this.filterDateActive === FOLLOWUP_DATE_FILTERS.LATE) {
          return supportDate.isBefore(moment().endOf("day"));
        } else {
          return true;
        }
      });
    },
    sortList(key, sortKey) {
      this.currentPage = 0;
      this.events = this.events.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      // certaines dates sont dans une sous-propriété
      if (["brief_date", "integration_date", "mentions_legales_date", "html_date", "bat_date"].includes(key)) {
        a = a.etapes_dates;
        b = b.etapes_dates;
      }
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
        return sortKey ? -1 : 1;
      }
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
        return sortKey ? 1 : -1;
      }
      return a[key] === null ? 1 : b[key] === null ? -1 : 0;
    },
    resetFilter() {
      this.searching = {};
      this.filterTypeActive = null;
      this.filterDateActive = null;
      this.filterList();
    },
    onEdit(id) {
      this.isCreate = false;
      this.isEdit = true;
      this.idSupportToAction = id;
    },
    onRelancer(id) {
      return this.relancerSupport(id)
        .then(() => {
          this.modal_props.showModal = true;
          this.modal_props.modalMessages = "L'email de relance a été envoyé";
          this.modal_props.modalAction = FOLLOWUP_ACTION_TYPES.RELANCE;
          this.modal_props.hasFooter = false;
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        });
    },
  },
  mounted() {
    // on recupere tous les supports
    return this.setSupportsListe()
      .then(() => {
        map(this.supportsListe, support => {
          if (
            ![SUPPORT_STATUS.TERMINE, SUPPORT_STATUS.BROUILLON].includes(support.statut) &&
            !support.is_archive &&
            support.date_envoi_souhaitee
          ) {
            this.events.push(support);
            this.filterList();
          }
        });
      })
      .then(() => this.setLoading(false));
  },
};
</script>

<style>
</style>
