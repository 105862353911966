<template>
  <div class="navbar">
    <nav>
      <div class="user-profil">{{ (currentUser && currentUser.prenom) || "" }}</div>
      <router-link :to="{ name: 'Principale' }" hidden>Bigoudenn</router-link>
      <router-link :to="{ name: 'Admin.Accueil.Principale' }">
        <i class="menu-icon-accueil"></i>
        <span>Accueil</span>
      </router-link>
      <router-link
        :to="{
          name: 'Admin.Supports',
          query: { type: 'actifs' },
          params: { typeSupport: supportTypes.EMAIL },
        }"
      >
        <i class="menu-icon-emails"></i>
        <span>Emails</span>
      </router-link>
      <router-link
        :to="{
          name: 'Admin.Supports',
          query: { type: 'actifs' },
          params: { typeSupport: supportTypes.RICH_SMS },
        }"
      >
        <i class="menu-icon-rich-sms"></i>
        <span>Rich SMS</span>
      </router-link>
      <router-link
        :to="{
          name: 'Admin.Supports',
          query: { type: 'actifs' },
          params: { typeSupport: supportTypes.PLV },
        }"
      >
        <i class="menu-icon-plv"></i>
        <span>PLV</span>
      </router-link>
      <router-link :to="{ name: 'Admin.Referentiel' }">
        <i class="menu-icon-referentiel"></i>
        <span>Référentiel</span>
      </router-link>
      <router-link :to="{ name: 'Admin.ListeUsers' }" v-if="isAdmin || isCoordinateur">
        <i class="menu-icon-utilisateurs"></i>
        <span>Utilisateurs</span>
      </router-link>
    </nav>
    <img
      src="@/assets/images/logo-groupama-loire-bretagne.svg"
      id="logo"
      alt="Logo Groupama Loire Bretagne"
    />
  </div>
  <main>
    <div class="main-header">
      <div class="main-header-left"></div>
      <div class="main-header-right">
        <button class="btn btn-primary" v-if="isUserLogged" @click="onLogOff">
          Se déconnecter
        </button>
      </div>
    </div>
    <section class="workspace">
      <router-view />
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SUPPORT_TYPES } from "../../../config/Properties";
export default {
  computed: {
    ...mapGetters(["isUserLogged", "currentUser", "isAdmin", "isCoordinateur"]),
    supportTypes() {
      return SUPPORT_TYPES;
    },
  },
  methods: {
    ...mapActions(["logOff", "setAppFooter", "setAppHeader", "setUserLogged"]),
    onLogOff() {
      return this.logOff().then(() => this.$router.push({ name: "Auth.Login" }));
    },
  },
  created() {
    return this.setAppHeader(false).then(() => this.setAppFooter(false));
  },
};
</script>

<style></style>
