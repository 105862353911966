import Admin from "./IndexAdmin.vue";
import { routes as accueilRoutes } from "./accueil";
import { routes as userRoutes } from "./users";
import { routes as referentielRoutes } from "./referentiel";
import { routes as workflowRoutes } from "./workflow";
import { routes as compteRoutes } from "./compte";
import { routes as supportsRoutes } from "./supports";
import { roles } from "../authentification";

export const routes = [
  {
    path: "/admin",
    name: "Admin.Principale",
    component: Admin,
    redirect: "/admin/accueil",
    children: [
      ...referentielRoutes,
      ...accueilRoutes,
      ...userRoutes,
      ...workflowRoutes,
      ...compteRoutes,
      ...supportsRoutes,
    ],
    meta: {
      authRequired: true,
      roles: [roles.ADMIN, roles.USER, roles.COORDINATEUR],
    },
  },
];
