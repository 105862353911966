<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div v-if="validated && errors" class="error-block">
      <span class="error-message">{{ errors }}</span>
    </div>

    <div
      @click="onChange"
      :class="['checkbox', valueRef && modelValue === valueRef ? 'checked' : '']"
    >
      <i class="fas fa-check" />
    </div>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    valueRef: {
      type: [Number, String],
      default: null,
    },

    modelValue: {
      type: [String, Number, Date, Boolean],
      default: null,
    },

    rules: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(e) {
      this.$emit("update:modelValue", this.valueRef);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
