<template>
  <loading v-if="loading" />
  <div class="row justify-content-center" v-else>
    <div class="col-9 col-md-12">
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
          <custom-input-type
            class="inline inline-right"
            label="Nom * :"
            type="text"
            ref="ml.nom"
            v-model="ml.nom"
            :rules="validations.nom"
          />
        </div>
        <div class="col-12 col-sm-12 col-lg-6 col-xl-6">
          <custom-input-type
            class="inline inline-right date"
            label="Date de validité :"
            type="date"
            v-model="ml.date_validite"
          />
        </div>
        <div class="col-12">
          <custom-multi-check-box
            class="inline inline-right"
            label="Type * :"
            :buttons="optionsType"
            v-model="ml.type"
            ref="ml.type"
            :rules="validations.type"
          />
        </div>
        <div class="col-12">
          <custom-editor-text
            class="inline inline-right ck-editor__big"
            label="Contenu * :"
            v-model="ml.contenu"
            ref="ml.contenu"
            :rules="validations.contenu"
          />
        </div>
      </div>
    </div>
    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-login btn-secondary" :to="{ name: 'Admin.Referentiel.ML' }"
        >Annuler</router-link
      >
      <button class="btn btn-login btn-secondary" @click="onSubmit">Valider</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import Loading from "../../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import CustomMultiCheckBox from "../../../../components/customize/CustomMultiCheckBox.vue";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";

export default {
  components: {
    CustomInputType,
    Loading,
    CustomMultiCheckBox,
    CustomEditorText,
  },
  name: "FormML",
  emits: ["annuler"],
  data() {
    return {
      optionsType: [
        { label: "Accélérateur", value: "ACCELERATEUR" },
        { label: "Orange banque", value: "ORANGE_BANQUE" },
        { label: "Email", value: "EMAIL" },
        { label: "RGPD", value: "RGPD" },
        { label: "SMS", value: "SMS" },
        { label: "Produits / Services", value: "PRODUITS_SSERVICES" },
        { label: "Avantage Fid", value: "AVANTAGE_FID" },
        { label: "Autre", value: "AUTRE" },
      ],
      ml: {
        nom: null,
        contenu: null,
        type: [],
        date_validite: null,
      },
      validations: {
        nom: {
          required: "Merci de renseigner un nom",
        },
        contenu: {
          required: "Merci de renseigner un contenu",
        },
        type: {
          required: "Merci de renseigner un type",
        },
      },
      formValid: true,
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["setLoading", "addReferentielML", "getReferentielML"]),
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    onSubmit() {
      this.checkValidations();
      if (!this.formValid) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() => this.addReferentielML({ ...this.ml }))
        .then(() => this.$router.push({ name: "Admin.Referentiel.ML" }))
        .catch(e => alert(e.response?.data?.message || e))
        .then(() => this.setLoading(false));
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielML = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielML = this.$route.params.id;
        }
        if (idReferentielML) {
          return this.getReferentielML({ id: idReferentielML });
        }
      })
      .then(ml => {
        if (ml) this.ml = { ...ml };
      })
      .then(() => this.setLoading(false));
  },
};
</script>

<style></style>
