import * as VueRouter from "vue-router";
import store from "../store";

// import { routes as homeRoutes } from "./home/index";
import { routes as authRoutes } from "./authentification/index";
import { routes as adminRoutes } from "./admin/index";

import NotFound from "../components/layouts/NotFound.vue";
import { CIBLAGE_STATUTS, SUPPORT_STATUS } from "../../config/Properties";

export function getRouteEtapeByStatut(statut) {
  switch (statut) {
    case SUPPORT_STATUS.BRIEF_A_FAIRE:
      return "brief";
    case SUPPORT_STATUS.A_INTEGRER:
    case SUPPORT_STATUS.INTEGRATION_A_VALIDER:
    case SUPPORT_STATUS.INTEGRATION_A_MODIFIER:
      return "integration";
    case SUPPORT_STATUS.ML_A_FAIRE:
    case SUPPORT_STATUS.ML_A_INTEGRER:
    case SUPPORT_STATUS.ML_A_VALIDER:
    case SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER:
      return "ml";
    case SUPPORT_STATUS.HTML_A_VALIDER:
      return "html";
    case SUPPORT_STATUS.BAT_A_FAIRE:
    case SUPPORT_STATUS.BAT_A_VALIDER:
    case SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER:
    case SUPPORT_STATUS.A_ENVOYER:
    case SUPPORT_STATUS.BAT_PRINT_A_VALIDER:
      return "bat";
    case SUPPORT_STATUS.A_LIVRER:
    case SUPPORT_STATUS.LIVRE:
      return "livraison";
    case SUPPORT_STATUS.STATS_A_FAIRE:
    case SUPPORT_STATUS.A_ANALYSER:
    case SUPPORT_STATUS.TERMINE:
      return "stats";
    case CIBLAGE_STATUTS.CIBLAGE_A_FAIRE:
    case CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE:
      return "ciblage";
    default:
      return "index";
  }
}

const routes = [
  // uncomment si nous avons pas un route home ou principale et faire un redirecte
  {
    path: "/",
    name: "Principale",
    redirect: "/admin",
  },
  // ...homeRoutes,
  ...adminRoutes,
  ...authRoutes,
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    (to.meta?.authRequired && !store.getters.isUserLogged) ||
    (to.meta?.roles && !to.meta?.roles?.includes(store.getters.userRole))
  ) {
    next({ name: "Auth.Login" });
  } else {
    next();
  }
});

export default router;
