<template>
  <div class="support-info-highlight-block">
    <field-radio-buttons
      label="Compte tenu de mon analyse des taux d’ouverture, taux de réactivité, taux de clics, cet email est :"
      :buttons="analysesOptions"
      v-model="formBilan.analyse_stats_statut"
      ref="formBilan.analyse_stats_statut"
      :rules="validations.analyse_stats_statut"
      class="center"
    />
    <custom-text-area
      v-model="formBilan.analyse_stats_adaptation"
      label="Commentaires :"
      ref="formBilan.analyse_stats_adaptation"
      :rules="validations.analyse_stats_adaptation"
    />
    <button class="btn btn-secondary" @click="submit">Valider</button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SUPPORT_STATUS } from "../../../../../config/Properties";
import CustomTextArea from "../../../../components/customize/CustomTextArea.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
export default {
  name: "FormBilanAnalyse",
  components: { FieldRadioButtons, CustomTextArea },
  mixins: [mixinAction],
  data() {
    return {
      analysesOptions: [
        { label: "À reconduire", value: "A_RECONDUIRE" },
        { label: "À adapter", value: "A_ADAPTER" },
        { label: "À arrêter", value: "A_ARRETER" },
        { label: "Non concerné", value: "NON_CONCERNE" },
      ],
      formBilan: {
        analyse_stats_statut: null,
        analyse_stats_adaptation: null,
      },
    };
  },
  computed: {
    validations() {
      return {
        analyse_stats_statut: { required: "Merci de choisir une option" },
        analyse_stats_adaptation: {
          requiredIf: {
            model: this.formBilan.analyse_stats_statut === "A_ADAPTER",
            message: "Merci de choisir une option",
          },
        },
      };
    },
  },
  methods: {
    ...mapActions(["updateSupportDetailsInfosBD", "updateSupportStatut"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.updateSupportDetailsInfosBD({
        ...this.formBilan,
        support_id: this.workflow.support_id,
      })
        .then(() =>
          this.updateSupportStatut({
            support_id: this.workflow.support_id,
            statut: SUPPORT_STATUS.TERMINE,
          })
        )
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
  watch: {
    "formBilan.analyse_stats_statut": {
      handler(val) {
        if (val !== this.analysesOptions[1].value) {
          this.formBilan.analyse_stats_adaptation = null;
        }
      },
    },
  },
};
</script>
