import { api } from "./api";
import moment from "moment";

export default class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.options = {};
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          //   this._initListeners(resolve, reject, file);
          this._sendRequest(file, resolve, reject);
        })
    );
  }
  
  // Prepares the data and sends the request.
  _sendRequest(file, resolve, reject) {
    // Prepare the form data.
    const formData = new FormData();
    let fileU = new File([file], `${moment().format("YYYYMMDD-HHmmss")}_${file.name}`);
    formData.append("attachment", fileU);

    api({
      method: "POST",
      url: "/uploads",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
      if (res.status !== 200) {
        return reject(res.data.message);
      }

      return resolve({
        default: `${process.env.VUE_APP_API_URL}/uploads/${res.data.data[0]}`,
      });
    });
  }
}
