<template>
  <div class="commentaire" v-for="(commentaire, index) in commentaires" :key="index">
    <div class="commentaire-user">{{ commentaireUser(commentaire.user_id) }}</div>
    <div class="commentaire-message">
      <div class="commentaire-text" v-sane-html="commentaire.contenu"></div>
      <div class="commentaire-date">
        {{ $moment(commentaire.created_at).format("DD/MM/YYYY - HH:mm") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RetourValidation",
  props: {
    commentairesModel: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      desc: true,
      commentaires: this.commentairesModel,
    };
  },
  computed: {
    ...mapGetters(["getUserFromListe"]),
  },
  methods: {
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom[0]}${this.getUserFromListe(id)?.nom[0]}`;
    },
    sortCommentaire() {
      this.commentaires = this.commentaires?.sort((a, b) =>
        this.callbackSort(a, b, "created_at", this.desc)
      );
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key] < b[key]) {
        return sortKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortKey ? 1 : -1;
      }
      return 0;
    },
  },
  mounted() {
    this.sortCommentaire();
  },
};
</script>

<style></style>
