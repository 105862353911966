<template>
  <div class="commentaires-sidebar">
    <button class="btn-close-sidebar" @click="$emit('close')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <div class="commentaires-title"><i class="fa-thin fa-comment"></i>Tous les commentaires</div>
    <div class="commentaires-container" v-if="showCommentaires">
      <div class="commentaire" v-for="(commentaire, index) in commentaires" :key="index">
        <div class="commentaire-user">{{ commentaireUser(commentaire.user_id) }}</div>
        <div class="commentaire-message">
          <div class="commentaire-text" v-sane-html="commentaire.contenu"></div>
          <div class="commentaire-date">
            [{{ commentaire.etape }}]
            {{ $moment(commentaire.created_at).format("DD/MM/YYYY - HH:mm") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["close"],
  name: "WorkflowCommentaires",
  props: {
    commentairesModel: {
      type: Object,
      default: [],
    },
  },
  data() {
    return {
      desc: true,
      showCommentaires: true,
    };
  },
  computed: {
    ...mapGetters(["getUserFromListe"]),
    commentaires() {
      return this.commentairesModel.sort((a, b) =>
        this.callbackSort(a, b, "created_at", this.desc)
      );
    },
  },
  methods: {
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom[0]}${this.getUserFromListe(id)?.nom[0]}`;
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key] < b[key]) {
        return sortKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortKey ? 1 : -1;
      }
      return 0;
    },
  },
};
</script>
