<template>
  <div class="content tab-content" v-if="!loading">
    <form-create-brief v-if="toEdit" :briefModel="model" :hasProperties="hasProperties" />
    <view-brief v-else-if="isView" :briefModel="model" :hasProperties="hasProperties" />
    <form-create-brief v-else-if="isForm" :briefModel="model" :hasProperties="hasProperties" />
    <form-create-brief v-else-if="isBrouillon" :briefModel="model" :hasProperties="hasProperties" />
    <div class="encours" v-else>Brief en cours</div>
    <div class="form-footer flex-btn-group center" v-if="canBeModify">
      <button class="btn btn-secondary" @click="toEdit = true">Modifier</button>
    </div>
    <div>
  </div>
  </div>
</template>

<script>
import { SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../../config/Properties";
import { mixinAction } from "../../utils";

import FormCreateBrief from "./FormCreateBrief.vue";
import ViewBrief from "./ViewBrief.vue";
export default {
  components: { FormCreateBrief, ViewBrief },
  name: "WorkflowBrief",
  data() {
    return {
      toEdit: false
    };
  },
  mixins: [mixinAction],
  computed: {
    isView() {
      return this.workflow?.statut !== SUPPORT_STATUS.BRIEF_A_FAIRE;
    },
    isBrouillon() {
      return this.workflow?.etapes.brief?.pre_header?.length > 0;
    },
    isForm() {
      return (
        !this.isBrouillon &&
        this.workflow?.statut === SUPPORT_STATUS.BRIEF_A_FAIRE &&
        (this.supportEquipe?.CM?.user_id === this.currentUser?.user_id ||
          this.supportEquipe?.COM.user_id === this.currentUser?.user_id ||
          this.hasAction)
      );
    },
    model() {
      return {
        ...this.workflow?.etapes?.BRIEF,
      };
    },
    canBeModify() {
      if (this.toEdit || this.workflow?.statut === SUPPORT_STATUS.BRIEF_A_FAIRE) return false;
      if (this.hasAction) return true;
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
        case SUPPORT_TYPES.PLV:
          return (
            this.supportEquipe?.CM?.user_id === this.currentUser?.user_id ||
            this.supportEquipe?.COM.user_id === this.currentUser?.user_id
          );
        default:
          return false;
      }
    },
    hasProperties() {
      return {
        type: true,
        logo: this.isEmail || this.isRichSMS,
        entete: this.isEmail || this.isRichSMS,
        creation_page_groupama: this.isEmail || this.isRichSMS,
        perso: this.isEmail || this.isRichSMS,
        signature: this.isEmail || this.isRichSMS,
        bandeau_multicanal: this.isEmail || this.isPlv,
        visuels: this.isEmail || this.isRichSMS || this.isPlv,
        objet: this.isEmail,
        sms: this.isRichSMS,
        pre_header: this.isRichSMS || this.isEmail,
        mentions_orange_banque: this.isPlv,
        refm: this.isPlv,
        marche: this.isPlv,
      };
    },
  },
};
</script>
