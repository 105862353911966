/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  USER: 'UTILISATEUR',
  ADMIN: 'ADMIN',
  COORDINATEUR: 'COORDINATEUR'
};

export const SUPPORT_TYPES = {
  EMAIL: 'EMAIL',
  RICH_SMS: 'RICH_SMS',
  OAV: 'OAV',
  AFFICHE: 'AFFICHE',
  PLV: 'PLV',
  MARKETING_DIRECT: 'MARKETING_DIRECT',
  PAGE_G_FR: 'PAGE_G_FR',
  AUTRE: 'AUTRE'
};

export const SUPPORT_STATUT_TEXT = {
  BRIEF_A_FAIRE: {
    [SUPPORT_TYPES.EMAIL]: 'Brief à faire',
    [SUPPORT_TYPES.RICH_SMS]: 'Brief à faire',
    [SUPPORT_TYPES.PLV]: 'Brief à faire'
  },
  A_INTEGRER: {
    [SUPPORT_TYPES.EMAIL]: 'A intégrer',
    [SUPPORT_TYPES.RICH_SMS]: 'A intégrer',
    [SUPPORT_TYPES.PLV]: 'A Créer'
  },
  INTEGRATION_A_VALIDER: {
    [SUPPORT_TYPES.EMAIL]: 'Intégration à valider',
    [SUPPORT_TYPES.RICH_SMS]: 'Intégration à valider',
    [SUPPORT_TYPES.PLV]: 'Création graphique à valider'
  },
  INTEGRATION_A_MODIFIER: {
    [SUPPORT_TYPES.EMAIL]: 'Intégration à modifier',
    [SUPPORT_TYPES.RICH_SMS]: 'Intégration à modifier',
    [SUPPORT_TYPES.PLV]: 'Création graphique à modifier'
  },
  ML_A_FAIRE: {
    [SUPPORT_TYPES.EMAIL]: 'ML à faire',
    [SUPPORT_TYPES.RICH_SMS]: 'ML à faire',
    [SUPPORT_TYPES.PLV]: 'ML à faire'
  },
  ML_A_INTEGRER: {
    [SUPPORT_TYPES.EMAIL]: 'ML à intégrer',
    [SUPPORT_TYPES.RICH_SMS]: 'ML à intégrer',
    [SUPPORT_TYPES.PLV]: 'ML à intégrer'
  },
  ML_A_VALIDER: {
    [SUPPORT_TYPES.EMAIL]: 'ML à valider',
    [SUPPORT_TYPES.RICH_SMS]: 'ML à valider',
    [SUPPORT_TYPES.PLV]: 'ML à valider'
  },
  INTEGRATION_ML_A_MODIFIER: {
    [SUPPORT_TYPES.EMAIL]: 'Intégration ML à modifier',
    [SUPPORT_TYPES.RICH_SMS]: 'Intégration ML à modifier',
    [SUPPORT_TYPES.PLV]: 'Intégration ML à modifier'
  },
  HTML_A_VALIDER: {
    [SUPPORT_TYPES.EMAIL]: 'HTML à valider',
    [SUPPORT_TYPES.RICH_SMS]: 'HTML à valider',
    [SUPPORT_TYPES.PLV]: ''
  },
  BAT_A_FAIRE: {
    [SUPPORT_TYPES.EMAIL]: 'BAT à faire',
    [SUPPORT_TYPES.RICH_SMS]: 'BAT à faire',
    [SUPPORT_TYPES.PLV]: 'BAT à faire'
  },
  BAT_A_VALIDER: {
    [SUPPORT_TYPES.EMAIL]: 'BAT à valider',
    [SUPPORT_TYPES.RICH_SMS]: 'BAT à valider',
    [SUPPORT_TYPES.PLV]: 'BAT numérique à valider'
  },
  INTEGRATION_BAT_A_MODIFIER: {
    [SUPPORT_TYPES.EMAIL]: 'Intégration BAT à modifier',
    [SUPPORT_TYPES.RICH_SMS]: 'Intégration BAT à modifier',
    [SUPPORT_TYPES.PLV]: 'Intégration BAT num à modifier'
  },
  BAT_PRINT_A_VALIDER: {
    [SUPPORT_TYPES.EMAIL]: '',
    [SUPPORT_TYPES.RICH_SMS]: '',
    [SUPPORT_TYPES.PLV]: 'BAT Print à valider'
  },
  A_LIVRER: {
    [SUPPORT_TYPES.EMAIL]: '',
    [SUPPORT_TYPES.RICH_SMS]: '',
    [SUPPORT_TYPES.PLV]: 'A Livrer'
  },
  A_ENVOYER: {
    [SUPPORT_TYPES.EMAIL]: 'A envoyer',
    [SUPPORT_TYPES.RICH_SMS]: 'A envoyer',
    [SUPPORT_TYPES.PLV]: ''
  },
  STATS_A_FAIRE: {
    [SUPPORT_TYPES.EMAIL]: 'Stats à faire',
    [SUPPORT_TYPES.RICH_SMS]: 'Stats à faire',
    [SUPPORT_TYPES.PLV]: ''
  },
  A_ANALYSER: {
    [SUPPORT_TYPES.EMAIL]: 'A analyser',
    [SUPPORT_TYPES.RICH_SMS]: 'A analyser',
    [SUPPORT_TYPES.PLV]: ''
  },
  TERMINE: {
    [SUPPORT_TYPES.EMAIL]: 'Analyses des stats',
    [SUPPORT_TYPES.RICH_SMS]: 'Analyses des stats',
    [SUPPORT_TYPES.PLV]: ''
  },
  LIVRE: {
    [SUPPORT_TYPES.EMAIL]: '',
    [SUPPORT_TYPES.RICH_SMS]: '',
    [SUPPORT_TYPES.PLV]: 'Livré'
  }
};

export const SUPPORT_STATUS = {
  BRIEF_A_FAIRE: 'BRIEF_A_FAIRE',
  A_INTEGRER: 'A_INTEGRER',
  INTEGRATION_A_VALIDER: 'INTEGRATION_A_VALIDER',
  INTEGRATION_A_MODIFIER: 'INTEGRATION_A_MODIFIER',
  ML_A_FAIRE: 'ML_A_FAIRE',
  ML_A_INTEGRER: 'ML_A_INTEGRER',
  ML_A_VALIDER: 'ML_A_VALIDER',
  INTEGRATION_ML_A_MODIFIER: 'INTEGRATION_ML_A_MODIFIER',
  HTML_A_VALIDER: 'HTML_A_VALIDER',
  BAT_A_FAIRE: 'BAT_A_FAIRE',
  BAT_A_VALIDER: 'BAT_A_VALIDER',
  INTEGRATION_BAT_A_MODIFIER: 'INTEGRATION_BAT_A_MODIFIER',
  BAT_PRINT_A_VALIDER: 'BAT_PRINT_A_VALIDER',
  A_ENVOYER: 'A_ENVOYER',
  STATS_A_FAIRE: 'STATS_A_FAIRE',
  A_LIVRER: 'A_LIVRER',
  A_ANALYSER: 'A_ANALYSER',
  LIVRE: 'LIVRE',
  TERMINE: 'TERMINE',
  BROUILLON: 'BROUILLON'
};
export const CIBLAGE_STATUTS = {
  FICHE_CIBLAGE_A_FAIRE: 'FICHE_CIBLAGE_A_FAIRE',
  CIBLAGE_A_FAIRE: 'CIBLAGE_A_FAIRE',
  CIBLAGE_FAIT: 'CIBLAGE_FAIT'
};

export const SUPPORT_ETAPES = {
  ML: 'Mentions légales',
  INTEGRATION: 'Intégration',
  BAT: 'BAT',
  HTML: 'HTML',
  BAT_NUM: 'BAT numérique',
  BAT_PRINT: 'BAT print',
  LIVRAISON: 'Livraison'
};
export const USER_TYPES = {
  CM: 'Chef de marché',
  COM: 'Chargé de com',
  DATA: 'Data',
  ML: 'Mentions légales',
  INTEGRATION: 'Intégration',
  LOGIST: 'Logistique'
};

export const TYPE_USER_TO_SUPPORT_STATUT = {
  [SUPPORT_STATUS.BRIEF_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['CM', 'COM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM', 'COM'],
    [SUPPORT_TYPES.PLV]: ['CM', 'COM']
  },
  [SUPPORT_STATUS.A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: ['CM']
  },
  [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.ML_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['ML'],
    [SUPPORT_TYPES.RICH_SMS]: ['ML'],
    [SUPPORT_TYPES.PLV]: ['ML']
  },
  [SUPPORT_STATUS.ML_A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.ML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['ML'],
    [SUPPORT_TYPES.RICH_SMS]: ['ML'],
    [SUPPORT_TYPES.PLV]: ['ML']
  },
  [SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.HTML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.BAT_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.BAT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM', 'COM', 'INTEGRATION', 'ML', 'DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM', 'COM', 'INTEGRATION', 'ML', 'DATA'],
    [SUPPORT_TYPES.PLV]: ['CM']
  },
  [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.A_ENVOYER]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.A_LIVRER]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: ['LOGIST']
  },
  [SUPPORT_STATUS.LIVRE]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: ['COM', 'CM']
  },
  [SUPPORT_STATUS.STATS_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.CIBLAGE_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.CIBLAGE_FAIT]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.A_ANALYSER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.TERMINE]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: []
  }
};
export const DATE_TYPE_FROM_STATUT = {
  [SUPPORT_STATUS.BRIEF_A_FAIRE]: 'brief_date',
  [SUPPORT_STATUS.A_INTEGRER]: 'integration_date',
  [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: 'integration_date',
  [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: 'integration_date',
  [SUPPORT_STATUS.ML_A_FAIRE]: 'mentions_legales_date',
  [SUPPORT_STATUS.ML_A_INTEGRER]: 'mentions_legales_date',
  [SUPPORT_STATUS.ML_A_VALIDER]: 'mentions_legales_date',
  [SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER]: 'mentions_legales_date',
  [SUPPORT_STATUS.HTML_A_VALIDER]: 'html_date',
  [SUPPORT_STATUS.BAT_A_FAIRE]: 'bat_date',
  [SUPPORT_STATUS.BAT_A_VALIDER]: 'bat_date',
  [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: 'bat_date',
  [SUPPORT_STATUS.A_ENVOYER]: 'date_envoi_souhaitee',
  [SUPPORT_STATUS.STATS_A_FAIRE]: 'date_envoi_souhaitee',
  [CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE]: 'ciblage_date',
  [CIBLAGE_STATUTS.CIBLAGE_A_FAIRE]: 'ciblage_date',
  [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: 'bat_print_date',
  [SUPPORT_STATUS.A_LIVRER]: 'livraison_date'
};

export const CIBLAGE_STATUTS_TEXT = {
  FICHE_CIBLAGE_A_FAIRE: 'Fiche ciblage à faire',
  CIBLAGE_A_FAIRE: 'Ciblage à faire',
  CIBLAGE_FAIT: 'Ciblage fait'
};
export const CIBLAGE_NOTIF_TEXT = {
  FICHE_CIBLAGE_A_FAIRE: 'Fiche ciblage à faire',
  CIBLAGE_A_FAIRE: 'Ciblage à faire',
  CIBLAGE_FAIT: 'HTML à valider'
};

export const TYPES_TAG = {
  NOUVELLE_CREATION: 'Nouvelle création',
  GMA: 'Reprise GMA',
  CIBLAGE_FAIT: 'Ciblage fait',
  EMAIL_EXISTANT: 'Email existant',
  GESTION: 'Gestion',
  RELATIONNEL: 'Relationnel',
  COMMERCIAL: 'Commercial'
};

export const SUPPORT_STATUS_INDEX = {
  [SUPPORT_STATUS.BRIEF_A_FAIRE]: 1,
  [SUPPORT_STATUS.A_INTEGRER]: 2,
  [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: 3,
  [SUPPORT_STATUS.ML_A_FAIRE]: 4,
  [SUPPORT_STATUS.ML_A_INTEGRER]: 5,
  [SUPPORT_STATUS.HTML_A_VALIDER]: 6,
  [SUPPORT_STATUS.BAT_A_FAIRE]: 7,
  [SUPPORT_STATUS.BAT_A_VALIDER]: 8,
  [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: 9,
  [SUPPORT_STATUS.A_ENVOYER]: 10,
  [SUPPORT_STATUS.A_LIVRER]: 11,
  [SUPPORT_STATUS.STATS_A_FAIRE]: 12,
  [SUPPORT_STATUS.A_ANALYSER]: 13
};
export const CIBLAGE_STATUTS_INDEX = {
  [CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE]: 1,
  [CIBLAGE_STATUTS.CIBLAGE_A_FAIRE]: 2
};
export const USER_HAS_ACTION_TO_SUPPORT_STATUT = {
  [SUPPORT_STATUS.BRIEF_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['CM', 'COM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM', 'COM'],
    [SUPPORT_TYPES.PLV]: ['CM', 'COM']
  },
  [SUPPORT_STATUS.A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: ['CM']
  },
  [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.ML_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['ML'],
    [SUPPORT_TYPES.RICH_SMS]: ['ML'],
    [SUPPORT_TYPES.PLV]: ['ML']
  },
  [SUPPORT_STATUS.ML_A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.ML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['ML'],
    [SUPPORT_TYPES.RICH_SMS]: ['ML'],
    [SUPPORT_TYPES.PLV]: ['ML']
  },
  [SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.HTML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.BAT_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: ['INTEGRATION'],
    [SUPPORT_TYPES.RICH_SMS]: ['INTEGRATION'],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.A_ENVOYER]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.STATS_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.CIBLAGE_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: ['DATA'],
    [SUPPORT_TYPES.RICH_SMS]: ['DATA'],
    [SUPPORT_TYPES.PLV]: []
  },
  [CIBLAGE_STATUTS.CIBLAGE_FAIT]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: []
  },
  [SUPPORT_STATUS.BAT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: ['CM']
  },
  [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: ['COM']
  },
  [SUPPORT_STATUS.A_LIVRER]: {
    [SUPPORT_TYPES.EMAIL]: [],
    [SUPPORT_TYPES.RICH_SMS]: [],
    [SUPPORT_TYPES.PLV]: ['LOGIST']
  },
  [SUPPORT_STATUS.A_ANALYSER]: {
    [SUPPORT_TYPES.EMAIL]: ['CM'],
    [SUPPORT_TYPES.RICH_SMS]: ['CM'],
    [SUPPORT_TYPES.PLV]: []
  }
};

export const REF_ML_TYPE = {
  ACCELERATEUR: 'ACCELERATEUR',
  ORANGE_BANQUE: 'ORANGE_BANQUE',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  RGPD: 'RGPD',
  PRODUITS_SSERVICES: 'PRODUITS_SSERVICES',
  AUTRE: 'AUTRE'
};
