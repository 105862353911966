<template>
  <form-boite />
</template>

<script>
import FormBoite from "./FormBoite.vue";
export default {
  components: { FormBoite },
  name: "CreateBoite",
};
</script>
