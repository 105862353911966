import { roles } from "../../authentification";

import AccueilPrincipale from "./IndexAccueil";
import UserAccueil from "./UserAccueil";
import AdminAccueil from "./AdminAccueil";

export const routes = [
  {
    path: "accueil",
    name: "Admin.Accueil.Principale",
    component: AccueilPrincipale,
    children: [
      {
        path: "admin",
        name: "Admin.Accueil",
        component: AdminAccueil,
        meta: {
          authRequired: true,
          roles: [roles.ADMIN, roles.COORDINATEUR],
        },
      },
      {
        path: "user",
        name: "User.Accueil",
        component: UserAccueil,
        meta: {
          authRequired: true,
          roles: [roles.USER],
        },
      },
    ],
    // meta: {
    //   authRequired: true,
    //   roles: [roles.ADMIN, roles.USER, roles.COORDINATEUR],
    // },
  },
];
