<template>
  <tr :class="{ tr: true, 'tr-click': trClickable }" :ref="`tr-${support.support_id}`">
    <td data-label="Référence" v-if="hasProperties.reference">
      <span>{{ support.reference }}</span>
    </td>
    <td data-label="Nom" v-if="hasProperties.nom">
      <span>{{ support.nom }}</span>
    </td>
    <td data-label="Date" v-if="hasProperties.date_envoi_souhaitee">
      <span>{{
        support.date_envoi_souhaitee
          ? this.$moment(support.date_envoi_souhaitee).format("DD/MM/yyyy")
          : "-"
      }}</span>
    </td>
    <td data-label="Référent" v-if="hasProperties.referent_nom">
      <span>{{ support.referent_nom }}</span>
    </td>
    <td data-label="Date de validité" v-if="hasProperties.date_validite">
      <span>{{
        support.date_validite ? this.$moment(support.date_validite).format("DD/MM/yyyy") : "-"
      }}</span>
    </td>
    <td data-label="Statut" v-if="hasProperties.statut">
      <span>{{ renderStatut(support.statut) }}</span>
    </td>
    <td data-label="Analyse" v-if="hasProperties.analyse_stats_statut">
      <span :class="['statut', support.analyse_stats_statut]">{{
        renderAnalyseStatut(support.analyse_stats_statut)
      }}</span>
    </td>
    <td class="buttons">
      <button
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        @click="$emit('relancerSupport', support.support_id)"
        v-if="!support.is_archive && hasAction"
      >
        <i class="fal fa-envelope"></i>
        <span class="tooltip-text">Relancer</span>
      </button>
      <button
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        @click="$emit('dupliquerSupport', support.support_id)"
        v-if="hasAction"
      >
        <i class="fal fa-copy"></i>
        <span class="tooltip-text">Dupliquer</span>
      </button>
      <button
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        @click="$emit('archiverSupport', support.support_id)"
        v-if="!support.is_archive && hasAction"
      >
        <i class="fal fa-archive"></i>
        <span class="tooltip-text">Archiver</span>
      </button>

      <router-link
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        :to="{
          name: 'Workflow.WorkflowEtape',
          params: { etape: workflowEtape, id: support.support_id },
        }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>

      <router-link
        class="btn tooltip-btn"
        :to="{
          name: 'Admin.EditSupport',
          params: { typeSupport: support.type, id: support.support_id || 1 },
        }"
        v-if="hasAction"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Modifier</span>
      </router-link>
      <button
        class="btn tooltip-btn"
        @click="$emit('deleteSupport', support.support_id)"
        v-if="hasAction"
      >
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
  </tr>
</template>

<script>
import { getRouteEtapeByStatut } from "../..";
import {
  ANALYSE_STATUT_TEXT,
  SUPPORT_STATUS,
  SUPPORT_STATUT_TEXT,
} from "../../../../config/Properties";
import { mixinAction } from "../utils";
export default {
  name: "SupportDetails",
  mixins: [mixinAction],
  emits: [
    "relancerSupport",
    "dupliquerSupport",
    "visualiserSupport",
    "archiverSupport",
    "editSupport",
    "deleteSupport",
  ],
  props: {
    support: {
      type: Object,
      default: {},
    },
    hasProperties: {
      type: Object,
      default: {},
    },
  },
  computed: {
    workflowEtape() {
      return getRouteEtapeByStatut(this.support.statut);
    },
    isBrouillon() {
      return this.support.statut === SUPPORT_STATUS.BROUILLON;
    },
    trClickable() {
      return !this.hasAction && !this.isBrouillon;
    },
  },
  methods: {
    renderStatut(statut) {
      if (this.isPlv && [SUPPORT_STATUS.A_INTEGRER, SUPPORT_STATUS.I]) {
      }
      return SUPPORT_STATUT_TEXT[statut][this.typeSupport];
    },
    renderAnalyseStatut(statut) {
      return ANALYSE_STATUT_TEXT[statut];
    },
    onTrClick() {
      this.$router.push({
        name: "Workflow.WorkflowEtape",
        params: { etape: this.workflowEtape, id: this.support.support_id },
      });
    },
  },
  mounted() {
    if (this.trClickable) {
      let tr = this.$refs[`tr-${this.support.support_id}`];
      tr.addEventListener("click", this.onTrClick);
    }
  },
};
</script>

<style lang="scss" scoped>
.tr-click {
  cursor: pointer;
}

.statut {
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: white;
  white-space: nowrap;
}
.A_ADAPTER {
  background-color: orange;
}
.A_RECONDUIRE {
  background-color: green;
}
.A_ARRETER {
  background-color: red;
}
.NON_CONCERNE {
  background-color: gray;
}
</style>
