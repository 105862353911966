<template>
  <main>
    <loading v-if="loading" />

    <div class="table-toolbar flex-btn-group right">
      <router-link class="btn btn-primary" :to="{ name: 'Admin.Referentiel.Boite.Create' }">
        Ajouter une boîte d'exlusion
      </router-link>
    </div>

    <table class="table table-referentiel">
      <thead>
        <tr>
          <!-- nom -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.nom = !sorting.nom;
                  sortListe('nom', sorting.nom);
                "
              >
                <span>Nom</span>
                <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.nom" @input="filterListe()" />
          </th>
          <!-- id Adobe -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.id_adobe = !sorting.id_adobe;
                  sortListe('id_adobe', sorting.id_adobe);
                "
              >
                <span>Id Adobe</span>
                <i :class="`far  ${sorting.id_adobe ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.id_adobe" @input="filterListe()" />
          </th>
          <!-- date MAJ -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.updated_at = !sorting.updated_at;
                  sortListe('updated_at', sorting.updated_at);
                "
              >
                <span>Date de MAj</span>
                <i :class="`far  ${sorting.updated_at ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <field-date-interval v-model="searching.updated_at" @input="filterListe()" />
          </th>
          <!-- date creation -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.created_at = !sorting.created_at;
                  sortListe('created_at', sorting.created_at);
                "
              >
                <span>Date de création</span>
                <i :class="`far  ${sorting.created_at ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <field-date-interval v-model="searching.created_at" @input="filterListe()" />
          </th>
          <!-- description -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.description = !sorting.description;
                  sortListe('description', sorting.description);
                "
              >
                <span>Description</span>
                <i :class="`far  ${sorting.description ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.description" @input="filterListe()" />
          </th>
          <!-- précoché -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.pre_selectionne = !sorting.pre_selectionne;
                  sortListe('pre_selectionne', sorting.pre_selectionne);
                "
              >
                <span>Précoché</span>
                <i :class="`far  ${sorting.pre_selectionne ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.pre_selectionne" @input="filterListe()" />
          </th>
          <!-- actif -->
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.is_actif = !sorting.is_actif;
                  sortListe('is_actif', sorting.is_actif);
                "
              >
                <span>Actif</span>
                <i :class="`far  ${sorting.is_actif ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.is_actif" @input="filterListe()" />
          </th>
          <th>
            <button class="btn" @click="resetFilter">
              <i class="far fa-sync" /><span>Réinitialiser</span>
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="pageListe && pageListe.length > 0">
        <item-boite
          v-for="(boite, index) in pageListe"
          :key="index"
          :boite="boite"
          @deleteBoite="onDelete"
          @desactiverBoite="onDesactiver"
        />
      </tbody>
      <div v-else>Aucun résultat</div>
    </table>
    <div class="pagination-wrapper">
      <div v-if="nbPages > 1">
        <div class="pagination">
          <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
            <i class="far fa-chevron-left" />
          </button>
          <button
            :class="[i === currentPage + 1 && 'active', 'page-number']"
            v-for="i in nbPages"
            :key="i"
            @click="nextPage(i)"
          >
            <span>{{ i }}</span>
          </button>
          <button
            class="page-number"
            v-if="currentPage < nbPages - 1"
            @click="nextPage(currentPage + 2)"
          >
            <i class="far fa-chevron-right" />
          </button>
        </div>
      </div>
      <custom-basic-select :options="nbpageOptions" v-model="nbPerPage" />
    </div>
    <custom-modal
      v-if="showModalDelete"
      @close="showModalDelete = false"
      @confirm="deleteReferentiel(idReferentielBoite)"
    >
      <template v-slot:modalTitle> Confirmation </template>
      <template v-slot:modalBody> Merci de confirmer la suppression</template>
    </custom-modal>
    <!-- Modal related support -->
    <custom-modal
      v-if="showModalRelatedSupports"
      @close="
        showModalRelatedSupports = false;
        relatedSupports = [];
      "
      :isConfirmDisabled="true"
    >
      <template v-slot:modalTitle> Informations </template>
      <template v-slot:modalBody>
        <div>
          Impossible de supprimer ou désactiver cette boite d'exclusion. Plusieurs supports y sont
          liés :
        </div>
        <ul>
          <li v-for="support in relatedSupports" :key="support.support_id">
            <router-link
              class="btn btn-link"
              :to="{
                name: 'Workflow.WorkflowEtape',
                params: { etape: 'ciblage', id: support.support_id },
              }"
            >
              <span>{{ support.nom }}</span>
            </router-link>
          </li>
        </ul>
      </template>
    </custom-modal>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomModal from "../../../../components/customize/CustomModal.vue";
import { map } from "lodash";
import Loading from "../../../../components/layouts/Loading.vue";
import FieldDateInterval from "../../../../components/customize/FieldDateInterval.vue";
import CustomBasicSelect from "../../../../components/customize/CustomBasicSelect.vue";
import ItemBoite from "./ItemBoite.vue";

export default {
  components: { CustomModal, Loading, FieldDateInterval, CustomBasicSelect, ItemBoite },
  name: "ListeReferentielBoite",
  data() {
    return {
      nbPages: 0,
      nbPerPage: 15,
      currentPage: 0,
      showModalDelete: false,
      idReferentielBoite: 0,
      currentListe: [],
      relatedSupports: [],
      showModalRelatedSupports: false,
      sorting: {},
      searching: {
        nom: null,
        created_at: { startDate: null, endDate: null },
        updated_at: { startDate: null, endDate: null },
      },
      nbpageOptions: { 15: "15/page", 30: "30/page", 50: "50/page" },
    };
  },
  computed: {
    ...mapGetters(["referentielBoitesListe", "loading"]),
    pageListe() {
      if (this.currentListe?.length > 0) {
        let n = this.currentPage * this.nbPerPage;
        let m = n + this.nbPerPage;
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
        return this.currentListe.slice(n, m);
      }
      return this.currentListe;
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setReferentielBoitesListe",
      "deleteReferentielBoite",
      "addReferentielBoite",
      "getSupportsByBoiteExclusion",
    ]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = [...this.referentielBoitesListe];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(ml => {
            switch (key) {
              case "created_at":
              case "updated_at":
                let dateML = this.$moment(ml[key], "YYYY-MM-DD");
                return search.startDate && search.endDate
                  ? dateML.isBetween(search.startDate, search.endDate, null, "[]")
                  : search.startDate
                  ? dateML.isSameOrAfter(search.startDate)
                  : search.endDate
                  ? dateML.isSameOrBefore(search.endDate)
                  : true;
              default:
                return regex.test(ml[key]);
            }
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = [...this.referentielBoitesListe];
      map(this.searching, (value, key) => {
        if (["created_at", "updated_at", "date_validite"].includes(key)) {
          this.searching[key].startDate = null;
          this.searching[key].endDate = null;
        } else {
          this.searching[key] = null;
        }
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key]?.toString().toLowerCase() < b[key]?.toString().toLowerCase()) {
        return sortKey ? -1 : 1;
      }
      if (a[key]?.toString().toLowerCase() > b[key]?.toString().toLowerCase()) {
        return sortKey ? 1 : -1;
      }
      return a[key] === null ? 1 : b[key] === null ? -1 : 0;
    },
    onDelete(id) {
      this.showModalDelete = true;
      this.idReferentielBoite = id;
    },
    deleteReferentiel(id) {
      this.showModalDelete = false;
      this.relatedSupports = [];
      let nom = this.referentielBoitesListe.find(b => b.referentiel_id === id).nom;
      return this.setLoading(true)
        .then(() => this.getSupportsByBoiteExclusion({ nom }))
        .then(supports => {
          if (supports.length === 0) return this.deleteReferentielBoite(id);
          this.showModalRelatedSupports = true;
          this.relatedSupports = supports;
          return false;
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        })
        .then(() => this.setLoading(false));
    },
    onDesactiver(id, isActif) {
      this.relatedSupports = [];
      let nom = this.referentielBoitesListe.find(b => b.referentiel_id === id).nom;
      return this.setLoading(true)
        .then(() => this.getSupportsByBoiteExclusion({ nom }))
        .then(supports => {
          if (supports.length === 0) {
            return this.addReferentielBoite({ referentiel_id: id, is_actif: isActif });
          }
          this.showModalRelatedSupports = true;
          this.relatedSupports = supports;
          return false;
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        })
        .then(() => this.setLoading(false));
    },
  },
  watch: {
    referentielBoitesListe: {
      handler(val) {
        this.currentListe = [...val];
      },
      deep: true,
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.setReferentielBoitesListe())
      .then(() => {
        this.currentListe = this.referentielBoitesListe;
      })
      .catch(e => {
        alert(e.response?.data?.message || e);
      })
      .then(() => this.setLoading(false));
  },
};
</script>
