import { api } from "../../utils/api";

import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_REF_BOITES_LISTE = "SET_REF_BOITES_LISTE";
export const ADD_REF_BOITE_TO_LISTE = "ADD_REF_BOITE_TO_LISTE";
export const REMOVE_REF_BOITE_FROM_LISTE = "REMOVE_REF_BOITE_FROM_LISTE";

const baseUrl = "/referentiel/boites-exclusion";

export const mutations = {
  [SET_REF_BOITES_LISTE](state, data) {
    state.globals.referentielBoitesListe = data;
  },
  [REMOVE_REF_BOITE_FROM_LISTE](state, data) {
    state.globals.referentielBoitesListe = state.globals.referentielBoitesListe.filter(
      ml => ml.referentiel_id !== data.referentiel_id
    );
  },
  [ADD_REF_BOITE_TO_LISTE](state, data) {
    state.globals.referentielBoitesListe.push(data);
  },
};
export const actions = {
  setReferentielBoitesListe({ commit }, data) {
    return api
      .get(baseUrl)
      .then(res => commit(SET_REF_BOITES_LISTE, res.data.referentiel_boites_exclusion))
      .catch(e => {
        console.log(e);
        commit(SET_REF_BOITES_LISTE, []);
        throw e;
      });
  },
  getReferentielBoite({ commit }, data) {
    return api
      .get(`${baseUrl}/${data.id}`)
      .then(res => res.data.referentiel_boite_exclusion)
      .catch(e => {
        console.log(e);
        commit(SET_REF_BOITES_LISTE, []);
        throw e;
      });
  },
  deleteReferentielBoite({ commit }, id) {
    return api
      .delete(`${baseUrl}/${id}`)
      .then(res => {
        commit(REMOVE_REF_BOITE_FROM_LISTE, { referentiel_id: id });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  addReferentielBoite({ commit }, data) {
    let id = data.referentiel_id;
    return api({
      method: id ? "PUT" : "POST",
      url: id ? `${baseUrl}/${id}` : baseUrl,
      data: { ...data },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(REMOVE_REF_BOITE_FROM_LISTE, { referentiel_id: id });
        commit(ADD_REF_BOITE_TO_LISTE, res.data.referentiel_boite_exclusion);
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  getSupportsByBoiteExclusion({ commit }, data) {
    return api
      .get(`${baseUrl}/${encodeURIComponent(data.nom)}/supports`)
      .then(res => res.data.supports)
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
};
export const getters = {
  referentielBoitesListe: state => {
    return state.globals.referentielBoitesListe;
  },
};
