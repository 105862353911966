<template>
  <div class="content tab-content">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7 minH-100">
        <div v-if="!toDo">
          <div class="form-col-title">ML</div>
          <div class="field" v-if="canEditML && isEditML">
            <div class="field">
              <custom-input-type
                class="inline"
                :label="`${this.isPlv ? 'URL :' : 'Lien :'}`"
                type="text"
                v-model="mentions_legales.lien"
                ref="mentions_legales.lien"
                :rules="validations.lien"
              />
            </div>
            <div class="flex-btn-group center" v-if="isEditML">
              <a class="btn btn-secondary" @click="resetEditML">Annuler</a>
              <a class="btn btn-secondary" @click="submitEditML">Valider</a>
            </div>
          </div>
          <div class="flex-btn-group column center" v-if="mentions_legales.lien">
            <a class="btn btn-secondary" :href="mentions_legales.lien" target="_blank">Voir les mentions légales</a>
            <button class="btn-link" v-if="canEditML && !isEditML" @click="this.isEditML = true">Modifier le lien</button>
          </div>
          <div class="flex-btn-group center" v-else>
            <a class="btn btn-secondary" v-if="canEditML && !isEditML" @click="this.isEditML = true">Ajouter le lien</a>
          </div>
        </div>
        <div v-if="isDone">
          <div class="form-col-title mt-5">Validation</div>
          <div class="text-center">
            Mentions légales validées par {{ this.mentions_legales.user.prenom }}
            {{ this.mentions_legales.user.nom }} le
            {{ $moment(this.mentions_legales.date_validation).format("DD/MM/YYYY") }}
          </div>
        </div>
        <div v-else class="h-100">
          <!-- Form ML -->
          <div v-if="toDo">
            <div class="form-col-title mt-4">ML</div>
            <div class="row">
              <custom-input-type
                class="inline"
                :label="`${this.isPlv ? 'URL *:' : 'Lien * :'}`"
                type="text"
                v-model="mentions_legales.lien"
                ref="mentions_legales.lien"
                :rules="validations.lien"
              />
              <custom-input-type
                v-if="isPlv"
                class="inline date"
                label="Date de validité :"
                type="date"
                v-model="date_validite"
              />
            </div>

            <div class="list-commentaires">
              <retour-validation :commentairesModel="mentions_legales.commentaires" />
            </div>
            <div class="field">
              <custom-editor-text
                label="Commentaire :"
                v-model="mentions_legales.commentaire_validation"
                ref="mentions_legales.commentaire_validation"
              />
            </div>

            <div v-if="canBypassValidation" class="flex-btn-group center">
              <button class="btn btn-secondary" @click="submit('ML_A_FAIRE')">ML à intégrer</button>
              <button class="btn btn-secondary" @click="submit('HTML_A_VALIDER')">Validation RAS</button>
            </div>
            <div v-else class="flex-btn-group center">
              <button class="btn btn-secondary" @click="submit('classic')">Valider</button>
            </div>

          </div>
          <!-- Form Integration -->
          <div v-else-if="toIntegrate">
            <div class="flex-btn-group center p-3">
              <button class="btn btn-secondary" @click="onRetourValidation">
                ML prises en compte
              </button>
            </div>
          </div>
          <!-- Form Modification -->
          <div v-else-if="toModify">
            <div class="form-col-title mt-4">Retour</div>
            <div class="list-commentaires">
              <retour-validation :commentairesModel="mentions_legales.commentaires" />
            </div>
            <div class="field">
              <custom-editor-text
                label="Commentaire :"
                v-model="mentions_legales.commentaire_validation"
                ref="mentions_legales.commentaire_validation"
              />
            </div>
            <div class="flex-btn-group center">
              <button class="btn btn-secondary" @click="submit('classic')">
                Retour ML pris en compte
              </button>
            </div>
          </div>
          <!-- Form Validation -->
          <div v-else-if="toValidate">
            <div class="form-col-title mt-4">Validation</div>
            <field-radio-buttons
              label="Je valide ML :"
              class="inline center"
              :buttons="[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]"
              v-model="mentions_legales.statut_validation"
              ref="mentions_legales.statut_validation"
              :rules="validations.statut_validation"
            />
            <div class="field" v-if="mentions_legales.statut_validation === false">
              <custom-editor-text
                label="Commentaire * :"
                v-model="mentions_legales.commentaire_validation"
                ref="mentions_legales.commentaire_validation"
                :rules="validations.commentaire_validation"
              />
            </div>
            <div class="flex-btn-group center">
              <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
              <button class="btn btn-secondary" @click="submit('classic')">Valider</button>
            </div>
          </div>
          <div class="encours" v-else>Mention légales en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import RetourValidation from "../shared/RetourValidation.vue";
import { mixinAction } from "../../utils";

export default {
  components: {
    FieldDropzoneFile,
    CustomInputType,
    FieldRadioButtons,
    CustomEditorText,
    SupportUrlVisuel,
    RetourValidation,
  },
  name: "WorkflowML",
  data() {
    return {
      mentions_legales: {},
      date_validite: null,
      isEditML: false,
    };
  },
  mixins: [mixinAction],
  computed: {
    ...mapGetters(["getUserFromListe", "isAdmin", "isCoordinateur"]),
    validations() {
      return {
        lien: { required: `Merci de renseigner ${this.isPlv ? "l'URL" : "le lien"}` },
        statut_validation: { required: "Merci de répondre à la validation" },
        commentaire_validation: {
          requiredIf: {
            model: !this.mentions_legales.statut_validation,
            message: "Merci de saisir un commentaire",
          },
        },
      };
    },
    toDo() {
      if (this.workflow.statut === SUPPORT_STATUS.ML_A_FAIRE) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.ML?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    toValidate() {
      if (this.workflow.statut === SUPPORT_STATUS.ML_A_VALIDER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.ML?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    toModify() {
      if (this.workflow.statut === SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email;
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    toIntegrate() {
      if (this.workflow.statut === SUPPORT_STATUS.ML_A_INTEGRER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email;
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    isDone() {
      return this.workflow.statut !== SUPPORT_STATUS.ML_A_FAIRE && this.mentions_legales?.is_valide;
    },
    canEditML() {
      return (
        this.hasAction
      );
    },
    canBypassValidation() {
      return (
        this.hasAction || this.currentUser?.email === this.supportEquipe?.ML?.user.email
      );
    },
  },
  methods: {
    ...mapActions(["addSupportEtapeBD", "updateSupportStatut", "updateSupportDetailsInfosBD", "updateSupportEtape"]),
    submitEditML() {
      return this.addSupportEtapeBD({
        ...this.mentions_legales,
      }).then(() => {
        this.workflow.etapes.ML.lien = this.mentions_legales.lien;
        this.isEditML = false;
      }
      ).catch(e => alert(e.response?.data?.message || e));
    },
    resetEditML() {
      this.mentions_legales.lien = this.workflow?.etapes?.ML.lien;
      this.isEditML = false;
    },
    submit(action) {
      switch (action) {
        case "classic" :
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.mentions_legales,
        statut: this.statutToApi(),
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => {
          if (this.date_validite) {
            return this.updateSupportDetailsInfosBD({
              support_id: this.mentions_legales.support_id,
              date_validite: this.date_validite,
            });
          }
        })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
        case "ML_A_FAIRE":
          this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.mentions_legales,
        statut: SUPPORT_STATUS.ML_A_INTEGRER,
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => {
          if (this.date_validite) {
            return this.updateSupportDetailsInfosBD({
              support_id: this.mentions_legales.support_id,
              date_validite: this.date_validite,
            });
          }
        })
        .then(() => this.mentions_legales?.statut_validation)
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
        case "HTML_A_VALIDER":
      return this.addSupportEtapeBD({
        ...this.mentions_legales,
        statut: this.isPlv
          ? SUPPORT_STATUS.BAT_A_FAIRE
          : SUPPORT_STATUS.HTML_A_VALIDER,
        statut_validation: true,
        is_valide: true,
        date_validation: this.$moment(),
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => {
          if (this.date_validite) {
            return this.updateSupportDetailsInfosBD({
              support_id: this.mentions_legales.support_id,
              date_validite: this.date_validite,
            });
          }
        })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
        default:
          break;
    }
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    statutToApi() {
      return this.toDo
        ? SUPPORT_STATUS.ML_A_INTEGRER
        : this.toIntegrate || this.toModify
        ? SUPPORT_STATUS.ML_A_VALIDER
        : this.toValidate && this.mentions_legales.statut_validation
        ? this.isPlv
          ? SUPPORT_STATUS.BAT_A_FAIRE
          : SUPPORT_STATUS.HTML_A_VALIDER
        : SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER;
    },
    isValidated() {
      return this.mentions_legales?.statut_validation;
    },
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    onRetourValidation() {
      return this.updateSupportStatut({
        support_id: this.mentions_legales.support_id,
        statut: this.statutToApi(),
      })
      .then(() => this.onCommentaireWritted)
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    onCommentaireWritted() {
      if (this.mentions_legales.commentaire_validation !== null) {
        return this.updateSupportEtape({
        support_etape_id: this.mentions_legales.support_etape_id,
        ...this.mentions_legales,
      });
      }
    },
  },
  mounted() {
    this.mentions_legales = this.workflow?.etapes?.ML || {};
  },
  watch: {
    workflow: {
      handler(val) {
        this.mentions_legales = { ...val?.etapes?.ML };
      },
      deep: true,
    },
    "mentions_legales.statut_validation": {
      handler(val) {
        if (val !== "Non" && SUPPORT_STATUS.ML_A_VALIDER) this.mentions_legales.commentaire_validation = null;
      },
    },
  },
};
</script>
