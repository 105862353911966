<template>
  <div>
    <div v-if="referentielSelected" class="field">
      <div class="uploaded-picture">
        <div @click="deleteReferentiel()" class="remove"><i class="far fa-times"></i></div>
        <img :src="getUrl()" />
      </div>
    </div>

    <div v-else class="field" @click="isOpen = true">
      <div class="form-control">
        <span style="font-style: italic">Choisir dans le référentiel</span>
      </div>
    </div>

    <custom-modal
      classModalGlobal="modal-library"
      classModal="modal-fullscreen"
      v-if="isOpen"
      @close="closeModal()"
      :hasFooter="true"
      @confirm="confirmReferentielSelected()"
    >
      <template v-slot:modalTitle>Bibliothèque de média</template>

      <template v-slot:modalBody>
        <div class="modal-search">
          <input
            type="text"
            class="form-control"
            v-model="searchReferentiel"
            @input="onChangeSearch()"
            placeholder="Rechercher un referentiel"
          />
        </div>
        <div class="row">
          <div
            v-for="referentiel in listeImages"
            :key="referentiel.referentiel_id"
            class="media-col col-12 col-xs-6 col-md-4 col-lg-4 col-xl-3"
          >
            <div
              @click="onChangeReferentiel(referentiel)"
              class="media-block"
              :class="
                referentielSelected &&
                referentielSelected.referentiel_id === referentiel.referentiel_id
                  ? 'media-block-selected'
                  : ''
              "
            >
              <div class="media-block-img">
                <div class="media-block-img-mask">
                  <span>
                    <i class="fas fa-plus" />
                  </span>
                </div>
                <img :src="apiUrlUpload + referentiel.image" />
              </div>
              <div class="media-block-title">{{ referentiel.nom }}</div>
            </div>
          </div>
        </div>
      </template>
    </custom-modal>
  </div>
</template>
<script>
import CustomModal from "./customize/CustomModal.vue";
import { find } from "lodash";
export default {
  components: { CustomModal },
  name: "ModalSelectImage",
  emits: ["update:modelValue"],
  props: {
    images: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null,
    },
  },

  data() {
    return {
      listeImages: this.images,
      searchReferentiel: null,
      referentielSelected: this.modelValue,
      apiUrlUpload: `${process.env.VUE_APP_API_URL}/uploads/referentiel/`,
      isOpen: false,
    };
  },
  methods: {
    getUrl() {
      if (this.referentielSelected.image) {
        return this.apiUrlUpload + this.referentielSelected.image;
      } else {
        const referentiel = find(this.images, i => i.referentiel_id === this.referentielSelected);
        if (referentiel) {
          return this.apiUrlUpload + referentiel.image;
        }
      }
    },
    onChangeSearch() {
      if (this.searchReferentiel) {
        let regex = new RegExp(this.searchReferentiel, "i");
        this.listeImages = this.listeImages.filter(i => {
          return regex.test(i["nom"]);
        });
      } else {
        this.listeImages = this.images;
      }
    },
    closeModal() {
      this.isOpen = false;
      this.listeImages = this.images;
      this.searchReferentiel = null;
      this.referentielSelected = null;
    },
    onChangeReferentiel(value) {
      this.referentielSelected = value;
    },
    deleteReferentiel() {
      this.referentielSelected = null;
      this.$emit("update:modelValue", null);
    },
    confirmReferentielSelected() {
      this.isOpen = false;
      this.$emit("update:modelValue", this.referentielSelected.referentiel_id);
      this.searchReferentiel = null;
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
