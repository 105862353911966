import { map } from "lodash";
import "moment/locale/fr";

export const SET_APP_HEADER = "SET_APP_HEADER";
export const SET_LOADING = "SET_LOADING";
export const SET_APP_FOOTER = "SET_APP_FOOTER";
export const SET_WORKFLOW = "SET_WORKFLOW";
export const FETCH = "FETCH";

// PAS DE PROMISE DANS LES MUTATIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// tous les changements du store doivent être fait ici
export const mutations = {
  [SET_LOADING]: (state, data) => {
    state.globals.loading = data;
  },
  [SET_APP_FOOTER]: (state, data) => {
    state.globals.showAppFooter = data;
  },

  [SET_WORKFLOW]: (state, data) => {
    state.globals.workflow = data;
  },
  [SET_APP_HEADER]: (state, data) => {
    state.globals.showAppHeader = data;
  },
  [FETCH]: (state, data) => {
    // TODO create function key, value pour mettre à jour le state avec state[key] = value
  },
};
export const actions = {
  setAppFooter({ commit }, data) {
    return commit(SET_APP_FOOTER, data);
  },
  setAppHeader({ commit }, data) {
    return commit(SET_APP_HEADER, data);
  },
  setLoading({ commit }, data) {
    return commit(SET_LOADING, data);
  },
  setWorkflow({ commit }, data) {
    return commit(SET_WORKFLOW, data);
  },
};
export const getters = {
  showAppHeader: state => {
    return state.globals.showAppHeader;
  },
  showAppFooter: state => {
    return state.globals.showAppFooter;
  },
  loading: state => {
    return state.globals.loading;
  },
  workflow: state => {
    return state.globals.workflow;
  },
  workflowCommentaires: state => {
    let w = state.globals.workflow;
    let commentaires = [];
    map(w.etapes, (etape, key) => {
      etape.commentaire && commentaires.push({ etape: key, contenu: etape.commentaire, ...etape, user_id: etape.user.user_id });
      map(etape.commentaires, commentaire => {
        commentaires.push({ etape: key, ...commentaire });
      });
    });
    return commentaires || [];
  },
};
