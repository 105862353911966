<template>
  <div :class="['card stat mb-4', type, classCard]">
    <router-link
      :to="{
        name: 'Workflow.WorkflowEtape',
        params: { etape: routeEtape, id: support.support_id },
      }"
    >
      <div class="stat-header">
        <div class="stat-title">{{ label }}</div>
        <div class="stat-subtitle">{{ formatDateCreation(date) }}</div>
      </div>

      <chart-donut :sections="renderPourcentage()" />
    </router-link>
    <div class="stat-etat">
      <span>{{ subLabel }} ({{ formatDateStatut(dateRenvoi) }})</span>
      <button @click="relanceSupportUser()">
        <i class="fal fa-envelope" />
      </button>
    </div>

    <custom-modal v-if="sendRelance" @close="sendRelance = false" :hasFooter="false">
      <template v-slot:modalTitle> Relance envoyée </template>

      <template v-slot:modalBody>
        <div v-if="sendRelance"><span>L'email de relance a été envoyé</span><br /></div>
      </template>
    </custom-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import ChartDonut from "./ChartDonut.vue";
import moment from "moment";
import CustomModal from "./customize/CustomModal.vue";
import { SUPPORT_STATUS } from "../../config/Properties";

export default {
  components: { ChartDonut, CustomModal },
  name: "CardStat",
  props: [
    "support",
    "type",
    "date",
    "dateRenvoi",
    "label",
    "subLabel",
    "statut",
    "routeEtape",
    "classCard",
  ],
  data() {
    return {
      sendRelance: false,
    };
  },
  methods: {
    ...mapActions(["relancerSupport"]),
    formatDateCreation(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateStatut(date) {
      return moment(date).format("DD/MM");
    },
    relanceSupportUser() {
      this.relancerSupport(this.support.support_id).then(() => {
        this.sendRelance = true;
      });
    },
    renderPourcentage() {
      let sections = [{ value: 0, color: "#35DBBB" }];
      switch (this.statut) {
        case SUPPORT_STATUS.BRIEF_A_FAIRE:
          sections[0].value = 0;
          break;
        case SUPPORT_STATUS.A_INTEGRER:
        case SUPPORT_STATUS.INTEGRATION_A_VALIDER:
        case SUPPORT_STATUS.INTEGRATION_A_MODIFIER:
          sections[0].value = 10;
          break;
        case SUPPORT_STATUS.ML_A_FAIRE:
        case SUPPORT_STATUS.ML_A_INTEGRER:
        case SUPPORT_STATUS.ML_A_VALIDER:
        case SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER:
          sections[0].value = 40;
          break;
        case SUPPORT_STATUS.HTML_A_VALIDER:
        case SUPPORT_STATUS.BAT_A_FAIRE:
        case SUPPORT_STATUS.BAT_A_VALIDER:
        case SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER:
          sections[0].value = 60;
          break;
        case SUPPORT_STATUS.A_ENVOYER:
          sections[0].value = 80;
          break;
        case SUPPORT_STATUS.STATS_A_FAIRE:
          sections[0].value = 90;
          break;
        case SUPPORT_STATUS.TERMINE:
          sections[0].value = 100;
          break;
      }
      return sections;
    },
  },
};
</script>
