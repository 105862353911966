import AdminReferentiel from "./IndexReferentiel";

import ListeReferentielImages from "./images/ListeReferentielImages.vue";
import CreateImage from "./images/CreateImage.vue";
import EditImage from "./images/EditImage.vue";
import AdminSignatures from "./signatures/AdminSignatures.vue";
import IndexML from "./ml/IndexML.vue";
import ListeReferentielML from "./ml/ListeML.vue";
import CreateML from "./ml/CreateML.vue";
import EditML from "./ml/EditML.vue";
import DetailsML from "./ml/DetailsML.vue";

import IndexBoite from "./boite_exclusion/IndexBoite.vue";
import ListeReferentielBoite from "./boite_exclusion/ListeBoites.vue";
import CreateBoite from "./boite_exclusion/CreateBoite.vue";
import EditBoite from "./boite_exclusion/EditBoite.vue";
import DetailsBoite from "./boite_exclusion/DetailsBoite.vue";

export const routes = [
  {
    path: "referentiel",
    name: "Admin.Referentiel",
    component: AdminReferentiel,
    redirect: "/admin/referentiel/images",
    children: [
      // Images
      {
        path: "images",
        name: "Admin.Referentiel.Images",
        component: ListeReferentielImages,
      },
      {
        path: "images/create",
        name: "Admin.Referentiel.Images.Create",
        component: CreateImage,
      },
      {
        path: "images/edit/:id",
        name: "Admin.Referentiel.Images.Edit",
        component: EditImage,
      },
      // Signatures
      {
        path: "signatures",
        name: "Admin.Referentiel.Signatures",
        component: AdminSignatures,
      },
      // ML
      {
        path: "ml",
        name: "Admin.Referentiel.ML",
        component: IndexML,
        redirect: {
          name: "Admin.Referentiel.ML.Liste",
        },
        children: [
          {
            path: "liste",
            name: "Admin.Referentiel.ML.Liste",
            component: ListeReferentielML,
          },
          {
            path: "create",
            name: "Admin.Referentiel.ML.Create",
            component: CreateML,
          },
          {
            path: "edit/:id",
            name: "Admin.Referentiel.ML.Edit",
            component: EditML,
          },
          {
            path: "details/:id",
            name: "Admin.Referentiel.ML.Details",
            component: DetailsML,
          },
        ],
      },
      // Boite exclusion
      {
        path: "boite-exclusion",
        name: "Admin.Referentiel.Boite",
        component: IndexBoite,
        redirect: {
          name: "Admin.Referentiel.Boite.Liste",
        },
        children: [
          {
            path: "liste",
            name: "Admin.Referentiel.Boite.Liste",
            component: ListeReferentielBoite,
          },
          {
            path: "create",
            name: "Admin.Referentiel.Boite.Create",
            component: CreateBoite,
          },
          {
            path: "edit/:id",
            name: "Admin.Referentiel.Boite.Edit",
            component: EditBoite,
          },
          {
            path: "details/:id",
            name: "Admin.Referentiel.Boite.Details",
            component: DetailsBoite,
          },
        ],
      },
    ],
  },
];
