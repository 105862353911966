<template>
  <form-ml />
</template>

<script>
import FormMl from "./FormML.vue";
export default {
  components: { FormMl },
  name: "EditMl",
};
</script>
