<template>
  <vc-donut
    :sections="sections"
    :background="background"
    :size="size"
    unit="px"
    :thickness="thickness"
    :foreground="foreground"
    :has-legend="hasLegend"
  >
  </vc-donut>
  <slot></slot>
</template>
<script>
export default {
  name: "ChartDonut",
  props: {
    sections: {
      type: Array,
      default: [{ value: 100, color: "gray" }],
    },
    size: {
      type: Number,
      default: 100,
    },
    thickness: {
      type: Number,
      default: 30,
    },
    foreground: {
      type: String,
      default: "#E1F5FD",
    },
    background: {
      type: String,
      default: "white",
    },
    hasLegend: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
