<template>
<div>Admin Signatures</div>
</template>

<script>
export default {
    props: {
    props: {
      label: {
        // label du champ
        type: String,
        default: null,
      },
      modelValue: {
        // valeur du champ qui sera mise à jour par la méthode onChange
        type: [String, Number, Date, Boolean, Object],
        default: null,
      },
      rules: {
        // Règles de validation du champ
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        // variable pour appeler la validation
        validated: false,
      };
    },
    computed: {
      errors() {
        // valider le champ par les règles qui ont été fournises dans les props
        return false;
      },
    },
    methods: {
      onChange(e) {
        // Manipuller le changement du champs
        // mise à jour le modelValue
      },
      validate() {
        // Indiquer qu'on peut appeler la validation du champ
        this.validated = true;
      },
    },
  },
};
</script>

<style></style>
