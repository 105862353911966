<template>
  <router-view></router-view>
</template>

<script>
import { mixinAction } from "../utils";
export default {
  name: "Accueil",
  mixins: [mixinAction],
  methods: {
    redirectRoute() {
      if (this.isAdmin || this.isCoordinateur) {
        return this.$router.push({ name: "Admin.Accueil" });
      }

      if (this.isUser) {
        return this.$router.push({ name: "User.Accueil" });
      }
    },
  },
  mounted() {
    this.redirectRoute();
  },
};
</script>

<style></style>
