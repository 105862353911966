import { SUPPORT_TYPES } from "../../../../config/Properties";
import IndexSupports from "./IndexSupports";
import ListeSupports from "./ListeSupports";
import CreateSupport from "./CreateSupport";
import EditSupport from "./EditSupport";

export const routes = [
  {
    path: "supports/:typeSupport",
    name: "Admin.Supports",
    component: IndexSupports,
    redirect: to => {
      return {
        name: "Admin.ListeSupports",
        query: {
          type: to.query.type || "actifs",
        },
        params: { typeSupport: to.params?.typeSupport || SUPPORT_TYPES.EMAIL },
      };
    },
    children: [
      {
        path: "",
        name: "Admin.ListeSupports",
        component: ListeSupports,
      },
      {
        path: "creation",
        name: "Admin.CreateSupport",
        component: CreateSupport,
      },
      {
        path: "edit/:id",
        name: "Admin.EditSupport",
        component: EditSupport,
      },
    ],
  },
];
