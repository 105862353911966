/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  USER: "USER",
  ADMIN: "ADMIN",
};

export const TYPES_PERSO = {
  CIVILITE_NOM_PRENOM: "Civ Nom Prénom",
  SOCIETAIRE: "Cher(e) sociétaire",
  BONJOUR: "Bonjour",
  RIEN: "Rien",
};

export const TYPES_SIGNATURE = {
  CONSEILLER: "Votre conseiller(e)",
  CH_CLI: "Votre chargé(e) de clientèle",
  CH_CLI_AGRICOLE: "Chargé(e) de clientèle Agricole",
  CH_CLI_ARTISAN_COMMERCANT: "Chargé de clientèle Artisans commerçants",
  CH_AFF_AGRICOLE: "Chargé d'affaires agricoles",
  CH_AFF_COLLECTIVE: "Chargé d'affaires collectivités",
  CH_AFF_ENTREPRISE: "Chargé d'affaires entreprises",
  Groupama_Loire_Bretagne: "Groupama Loire Bretagne",
  AUTRES: "Autres",
  RIEN: "Rien",
};

export const SUPPORT_TYPES = {
  EMAIL: "EMAIL",
  RICH_SMS: "RICH_SMS",
  OAV: "OAV",
  AFFICHE: "AFFICHE",
  PLV: "PLV",
  MARKETING_DIRECT: "MARKETING_DIRECT",
  PAGE_G_FR: "PAGE_G_FR",
  AUTRE: "AUTRE",
};
export const SUPPORT_TYPES_TEXT = {
  EMAIL: "un Email",
  RICH_SMS: "un Rich SMS",
  OAV: "un OAV",
  AFFICHE: "une Affiche",
  PLV: "une PLV",
  MARKETING_DIRECT: "un Marketing direct",
  PAGE_G_FR: "un Page G Fr",
  AUTRE: "Autre",
};

export const DATE_TYPE_FROM_STEP_TYPE = {
  BRIEF: "brief_date",
  CIBLAGE: "ciblage_date",
  INTEGRATION: "integration_date",
  MENTIONS_LEGALES: "mentions_legales_date",
  VALIDATION_HTML: "html_date",
  BAT_ENVOIE: "bat_date",
  BAT_PRINT: "bat_print_date",
  BAT_NUM: "bat_num_date",
  LIVRAISON: "livraison_date",
  STATS: "date_envoie_souhaitee",
};

export const RETRO_PLANING_DAYS = {
  BRIEF: {
    [SUPPORT_TYPES.EMAIL]: -14,
    [SUPPORT_TYPES.RICH_SMS]: -14,
    [SUPPORT_TYPES.PLV]: -45,
  },
  CIBLAGE: {
    [SUPPORT_TYPES.EMAIL]: -14,
    [SUPPORT_TYPES.RICH_SMS]: -14,
    [SUPPORT_TYPES.PLV]: 0,
  },
  INTEGRATION: {
    [SUPPORT_TYPES.EMAIL]: -9,
    [SUPPORT_TYPES.RICH_SMS]: -9,
    [SUPPORT_TYPES.PLV]: -38,
  },
  ML: {
    [SUPPORT_TYPES.EMAIL]: -5,
    [SUPPORT_TYPES.RICH_SMS]: -5,
    [SUPPORT_TYPES.PLV]: -34,
  },
  HTML: {
    [SUPPORT_TYPES.EMAIL]: -3,
    [SUPPORT_TYPES.RICH_SMS]: -3,
    [SUPPORT_TYPES.PLV]: 0,
  },
  BAT_ENVOI: {
    [SUPPORT_TYPES.EMAIL]: -2,
    [SUPPORT_TYPES.RICH_SMS]: -2,
    [SUPPORT_TYPES.PLV]: -0,
  },
  BAT_PRINT: {
    [SUPPORT_TYPES.EMAIL]: 0,
    [SUPPORT_TYPES.RICH_SMS]: 0,
    [SUPPORT_TYPES.PLV]: -30,
  },
  BAT_NUM: {
    [SUPPORT_TYPES.EMAIL]: 0,
    [SUPPORT_TYPES.RICH_SMS]: 0,
    [SUPPORT_TYPES.PLV]: -32,
  },
};

export const DATE_TYPE_FROM_STATUT = {
  BRIEF_A_FAIRE: "brief_date",
  A_INTEGRER: "integration_date",
  INTEGRATION_A_VALIDER: "integration_date",
  INTEGRATION_A_MODIFIER: "integration_date",
  ML_A_FAIRE: "mentions_legales_date",
  ML_A_VALIDER: "mentions_legales_date",
  INTEGRATION_ML_A_MODIFIER: "mentions_legales_date",
  HTML_A_VALIDER: "html_date",
  BAT_A_FAIRE: "bat_date",
  BAT_A_VALIDER: "bat_date",
  INTEGRATION_BAT_A_MODIFIER: "bat_date",
  A_ENVOYER: "date_envoie_souhaitee",
};
export const STEP_CIBLAGE_CLASS = {
  FICHE_CIBLAGE_A_FAIRE: "active",
  CIBLAGE_A_FAIRE: "active",
  CIBLAGE_FAIT: "done",
};

export const STEP_CREATION_CLASS = {
  BRIEF_A_FAIRE: {
    BRIEF: "active",
    INTEGRATION: "inactive",
    MENTIONS_LEGALES: "inactive",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  A_INTEGRER: {
    BRIEF: "done",
    INTEGRATION: "active",
    MENTIONS_LEGALES: "inactive",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  INTEGRATION_A_VALIDER: {
    BRIEF: "done",
    INTEGRATION: "active",
    MENTIONS_LEGALES: "inactive",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  INTEGRATION_A_MODIFIER: {
    BRIEF: "done",
    INTEGRATION: "active",
    MENTIONS_LEGALES: "inactive",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  ML_A_FAIRE: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "active",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  ML_A_INTEGRER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "active",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  ML_A_VALIDER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "done",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  INTEGRATION_ML_A_MODIFIER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "active",
    VALIDATION_HTML: "inactive",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  HTML_A_VALIDER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "active",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  BAT_A_FAIRE: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "inactive",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  BAT_A_VALIDER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "active",
    BAT_NUM: "active",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  BAT_PRINT_A_VALIDER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "active",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  INTEGRATION_BAT_A_MODIFIER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "active",
    BAT_NUM: "active",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  A_ENVOYER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "active",
    BAT_NUM: "inactive",
    BAT_PRINT: "inactive",
    LIVRAISON: "inactive",
    STATS: "inactive",
  },
  A_LIVRER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "validated",
    LIVRAISON: "active",
    STATS: "inactive",
  },
  LIVRE: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "validated",
    LIVRAISON: "done",
    STATS: "inactive",
  },
  STATS_A_FAIRE: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "validated",
    LIVRAISON: "validated",
    STATS: "active",
  },
  A_ANALYSER: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "validated",
    LIVRAISON: "validated",
    STATS: "active",
  },
  TERMINE: {
    BRIEF: "done",
    INTEGRATION: "validated",
    MENTIONS_LEGALES: "validated",
    VALIDATION_HTML: "validated",
    BAT_ENVOIE: "validated",
    BAT_NUM: "validated",
    BAT_PRINT: "validated",
    LIVRAISON: "validated",
    STATS: "done",
  },
};

export const SUPPORT_STATUS = {
  BRIEF_A_FAIRE: "BRIEF_A_FAIRE",
  A_INTEGRER: "A_INTEGRER",
  INTEGRATION_A_VALIDER: "INTEGRATION_A_VALIDER",
  INTEGRATION_A_MODIFIER: "INTEGRATION_A_MODIFIER",
  ML_A_FAIRE: "ML_A_FAIRE",
  ML_A_INTEGRER: "ML_A_INTEGRER",
  ML_A_VALIDER: "ML_A_VALIDER",
  INTEGRATION_ML_A_MODIFIER: "INTEGRATION_ML_A_MODIFIER",
  HTML_A_VALIDER: "HTML_A_VALIDER",
  BAT_A_FAIRE: "BAT_A_FAIRE",
  BAT_A_VALIDER: "BAT_A_VALIDER",
  INTEGRATION_BAT_A_MODIFIER: "INTEGRATION_BAT_A_MODIFIER",
  BAT_PRINT_A_VALIDER: "BAT_PRINT_A_VALIDER",
  A_ENVOYER: "A_ENVOYER",
  STATS_A_FAIRE: "STATS_A_FAIRE",
  A_LIVRER: "A_LIVRER",
  LIVRE: "LIVRE",
  A_ANALYSER: "A_ANALYSER",
  TERMINE: "TERMINE",
  BROUILLON: "BROUILLON",
};

export const CIBLAGE_STATUTS = {
  FICHE_CIBLAGE_A_FAIRE: "FICHE_CIBLAGE_A_FAIRE",
  CIBLAGE_A_FAIRE: "CIBLAGE_A_FAIRE",
  CIBLAGE_FAIT: "CIBLAGE_FAIT",
};
export const CIBLAGE_STATUTS_TEXT = {
  FICHE_CIBLAGE_A_FAIRE: "Fiche ciblage à faire",
  CIBLAGE_A_FAIRE: "Ciblage à faire",
  CIBLAGE_FAIT: "Fait",
};

export const SUPPORT_STATUT_TEXT = {
  [SUPPORT_STATUS.BRIEF_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: "Brief à faire",
    [SUPPORT_TYPES.RICH_SMS]: "Brief à faire",
    [SUPPORT_TYPES.PLV]: "Brief à faire",
  },
  [SUPPORT_STATUS.A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: "A intégrer",
    [SUPPORT_TYPES.RICH_SMS]: "A intégrer",
    [SUPPORT_TYPES.PLV]: "A Créer",
  },
  [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: "Intégration à valider",
    [SUPPORT_TYPES.RICH_SMS]: "Intégration à valider",
    [SUPPORT_TYPES.PLV]: "Création graphique à valider",
  },
  [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: "Intégration à modifier",
    [SUPPORT_TYPES.RICH_SMS]: "Intégration à modifier",
    [SUPPORT_TYPES.PLV]: "Création graphique à modifier",
  },
  [SUPPORT_STATUS.ML_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: "ML à faire",
    [SUPPORT_TYPES.RICH_SMS]: "ML à faire",
    [SUPPORT_TYPES.PLV]: "ML à faire",
  },
  [SUPPORT_STATUS.ML_A_INTEGRER]: {
    [SUPPORT_TYPES.EMAIL]: "ML à intégrer",
    [SUPPORT_TYPES.RICH_SMS]: "ML à intégrer",
    [SUPPORT_TYPES.PLV]: "ML à intégrer",
  },
  [SUPPORT_STATUS.ML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: "ML à valider",
    [SUPPORT_TYPES.RICH_SMS]: "ML à valider",
    [SUPPORT_TYPES.PLV]: "ML à valider",
  },
  [SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: "Intégration ML à modifier",
    [SUPPORT_TYPES.RICH_SMS]: "Intégration ML à modifier",
    [SUPPORT_TYPES.PLV]: "Intégration ML à modifier",
  },
  [SUPPORT_STATUS.HTML_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: "HTML à valider",
    [SUPPORT_TYPES.RICH_SMS]: "HTML à valider",
    [SUPPORT_TYPES.PLV]: "",
  },
  [SUPPORT_STATUS.BAT_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: "BAT à faire",
    [SUPPORT_TYPES.RICH_SMS]: "BAT à faire",
    [SUPPORT_TYPES.PLV]: "BAT à faire",
  },
  [SUPPORT_STATUS.BAT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: "BAT à valider",
    [SUPPORT_TYPES.RICH_SMS]: "BAT à valider",
    [SUPPORT_TYPES.PLV]: "BAT numérique à valider",
  },
  [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: {
    [SUPPORT_TYPES.EMAIL]: "Intégration BAT à modifier",
    [SUPPORT_TYPES.RICH_SMS]: "Intégration BAT à modifier",
    [SUPPORT_TYPES.PLV]: "Intégration BAT num à modifier",
  },
  [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: {
    [SUPPORT_TYPES.EMAIL]: "",
    [SUPPORT_TYPES.RICH_SMS]: "",
    [SUPPORT_TYPES.PLV]: "BAT Print à valider",
  },
  [SUPPORT_STATUS.A_ENVOYER]: {
    [SUPPORT_TYPES.EMAIL]: "A envoyer",
    [SUPPORT_TYPES.RICH_SMS]: "A envoyer",
    [SUPPORT_TYPES.PLV]: "",
  },
  [SUPPORT_STATUS.STATS_A_FAIRE]: {
    [SUPPORT_TYPES.EMAIL]: "Stats à faire",
    [SUPPORT_TYPES.RICH_SMS]: "Stats à faire",
    [SUPPORT_TYPES.PLV]: "",
  },
  [SUPPORT_STATUS.A_LIVRER]: {
    [SUPPORT_TYPES.EMAIL]: "",
    [SUPPORT_TYPES.RICH_SMS]: "",
    [SUPPORT_TYPES.PLV]: "A Livrer",
  },
  [SUPPORT_STATUS.LIVRE]: {
    [SUPPORT_TYPES.EMAIL]: "",
    [SUPPORT_TYPES.RICH_SMS]: "",
    [SUPPORT_TYPES.PLV]: "Livré",
  },
  A_ANALYSER: {
    [SUPPORT_TYPES.EMAIL]: "A analyser",
    [SUPPORT_TYPES.RICH_SMS]: "A analyser",
    [SUPPORT_TYPES.PLV]: "",
  },
  // TERMINE: "Terminé",
  [SUPPORT_STATUS.TERMINE]: {
    [SUPPORT_TYPES.EMAIL]: "Analysé",
    [SUPPORT_TYPES.RICH_SMS]: "Analysé",
    [SUPPORT_TYPES.PLV]: "",
  },
  [SUPPORT_STATUS.BROUILLON]: {
    [SUPPORT_TYPES.EMAIL]: "Brouillon",
    [SUPPORT_TYPES.RICH_SMS]: "Brouillon",
    [SUPPORT_TYPES.PLV]: "Brouillon",
  },
};

export const ANALYSE_STATUT_TEXT = {
  A_RECONDUIRE: "A reconduire",
  A_ADAPTER: "A adapter",
  A_ARRETER: "A arrêter",
  NON_CONCERNE: "Non concerné",
};

export const LOGO_TYPES = {
  EPARGNE: "logo_eparne.png",
  GLB: "logo_glb.jpg",
  ENTREPRISE: "logo_entreprise.jpg",
  GROUPAMA: "logo_groupama.jpg",
};

export const REF_ML_TYPE_TEXT = {
  ACCELERATEUR: "Accélérateur",
  ORANGE_BANQUE: "Orange banque",
  EMAIL: "Email",
  SMS: "SMS",
  RGPD: "RGPD",
  PRODUITS_SSERVICES: "Produits / Services",
  AVANTAGE_FID: "Avantage Fid",
  AUTRE: "Autre",
};

export const FOLLOWUP_DATE_FILTERS = {
  TODAY: "Aujourd'hui",
  THIS_WEEK: "Cette semaine",
  LATE: "En retard",
};

export const FOLLOWUP_ACTION_TYPES = {
  RELANCE: "RELANCE",
};

export const FOLLOWUP_STEP_FROM_DATE_TYPE = {
  "brief_date": "BRIEF",
  "integration_date": "INTEGRATION",
  "mentions_legales_date": "ML",
  "bat_date": "BAT",
  "bat_num_date": "BAT_NUM",
  "bat_print_date": "BAT_PRINT",
  "html_date": "HTML",
  "date_envoi_souhaitee": "LIVRAISON"
};






