<template>
  <form-image />
</template>

<script>
import FormImage from "./FormImage.vue";

export default {
  components: { FormImage },
  name: "EditImage",
};
</script>

<style></style>
