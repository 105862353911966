<template>
  <div class="content tab-content">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7 minH-100">
        <div>
          <div class="form-col-title">Document final</div>
          <div class="flex-btn-group center">
            <a class="btn btn-secondary" :href="documentFinal" target="_blank">
              Voir le document final
            </a>
          </div>
        </div>
        <div v-if="isDone">
          <div>
            <div class="form-col-title mt-4">Livraison</div>
            <div class="text-center">
              Livraison validée par {{ this.livraison.user.prenom }}
              {{ this.livraison.user.nom }} le
              {{ $moment(this.livraison.date_validation).format("DD/MM/YYYY") }}
            </div>
            <div class="text-center">
              <template v-if="isOPE">
                <div v-if="livraison.date_reception_apf">
                  Date de réception APF :
                  {{ $moment(livraison.date_reception_apf).format("DD/MM/YYYY") }}
                </div>
                <div v-if="livraison.date_expedition_tcs">
                  Date d'expédition TCS :
                  {{ $moment(livraison.date_expedition_tcs).format("DD/MM/YYYY") }}
                </div>
                <div v-if="livraison.volume_final">
                  Volume :
                  {{ livraison.volume_final }}
                </div>
              </template>
              <template v-else>
                {{ livraisonTexte }}
              </template>
            </div>
          </div>
        </div>
        <!-- Livraison Form -->
        <div v-else>
          <div v-if="toValidate">
            <div class="form-col-title mt-4">Livraison</div>
            <template v-if="isOPE">
              <custom-input-type
                class="inline"
                type="date"
                label="Date de réception APF :"
                v-model="livraison.date_reception_apf"
                ref="livraison.date_reception_apf"
                :rules="validations.date_reception_apf"
              />
              <custom-input-type
                class="inline"
                type="date"
                label="Date d'expédition TCS :"
                v-model="livraison.date_expedition_tcs"
                ref="livraison.date_expedition_tcs"
                :rules="validations.date_expedition_tcs"
              />
              <custom-input-type
                class="inline"
                type="number"
                label="Volume :"
                v-model="livraison.volume_final"
                ref="livraison.volume_final"
                :rules="validations.volume_final"
              />
              <div class="flex-btn-group center">
                <button
                  class="btn btn-secondary"
                  @click="
                    validateAction = true;
                    submit();
                  "
                >
                  Valider
                </button>
                <button
                  class="btn btn-secondary"
                  @click="
                    livreAction = true;
                    submit();
                  "
                >
                  Livré
                </button>
              </div>
            </template>
            <template v-else>
              <custom-input-type
                class="inline"
                type="text"
                label="Lieu de stockage * :"
                v-model="livraison.lieu_stockage"
                ref="livraison.lieu_stockage"
                :rules="validations.lieu_stockage"
              />
              <custom-input-type
                class="inline"
                type="text"
                label="Lieu de livraison * :"
                v-model="livraison.lieu_livraison"
                ref="livraison.lieu_livraison"
                :rules="validations.lieu_livraison"
              />
              <custom-input-type
                class="inline"
                type="number"
                label="Volume final * :"
                v-model="livraison.volume_final"
                ref="livraison.volume_final"
                :rules="validations.volume_final"
              />
              <div class="flex-btn-group center">
                <button
                  class="btn btn-secondary"
                  @click="
                    livreAction = true;
                    submit();
                  "
                >
                  Document livré
                </button>
              </div>
            </template>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-7" v-else>
            <div v-if="isOPE && hasOPEField">
              <div v-if="livraison.date_reception_apf">
                Date de réception APF :
                {{ $moment(livraison.date_reception_apf).format("DD/MM/YYYY") }}
              </div>
              <div v-if="livraison.date_expedition_tcs">
                Date d'expédition TCS :
                {{ $moment(livraison.date_expedition_tcs).format("DD/MM/YYYY") }}
              </div>
              <div v-if="livraison.volume_final">
                Volume :
                {{ livraison.volume_final }}
              </div>
            </div>
            <div class="encours" v-else>Livraison en cours</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SUPPORT_STATUS } from "../../../../../config/Properties";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
import supportUrlVisuel from "../shared/supportUrlVisuel.vue";
export default {
  components: { supportUrlVisuel, CustomInputType },
  name: "WorkflowLivraison",
  mixins: [mixinAction],
  data() {
    return {
      livraison: {},
      validateAction: false,
      livreAction: false,
    };
  },
  computed: {
    isDone() {
      return this.livraison.is_valide && this.workflow.statut === SUPPORT_STATUS.LIVRE;
    },
    documentFinal() {
      return this.workflow?.etapes?.BAT_NUM.lien;
    },
    validations() {
      return {
        lieu_livraison: { required: "Merci de renseigner le lieu de livraison" },
        lieu_stockage: { required: "Merci de renseigner le lieu de stockage" },
        volume_final: { required: "Merci de renseigner le volume" },
        date_reception_apf: { required: "Merci de renseigner la date de réception APF" },
        date_expedition_tcs: {
          requiredIf: {
            model: this.livreAction,
            message: "Merci de renseigner la date d'expédition TCS",
          },
        },
      };
    },
    toValidate() {
      if (this.workflow.statut === SUPPORT_STATUS.A_LIVRER) {
        if (this.hasAction) return true;
        return this.supportEquipe?.LOGIST?.user?.email === this.currentUser?.email;
      }
      return false;
    },
    livraisonTexte() {
      return ` Lieu de stockage : ${this.livraison?.lieu_stockage} / Lieu de livraison : ${this.livraison?.lieu_livraison}`;
    },
    isOPE() {
      return this.workflow?.lieu_livraison?.indexOf("OPE_SOLEIL") >= 0;
    },
    hasOPEField() {
      return (
        this.isOPE &&
        (
          this.livraison?.date_reception_apf +
          this.livraison?.date_expedition_tcs +
          this.livraison?.volume_final?.toString()
        ).length > 0
      );
    },
  },
  methods: {
    ...mapActions(["addSupportEtapeBD", "updateSupportStatut", "updateSupportDetailsInfosBD"]),
    async submit() {
      await this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.livraison,
        statut: this.livreAction ? SUPPORT_STATUS.LIVRE : SUPPORT_STATUS.A_LIVRER,
        is_valide: this.livreAction,
        date_validation: this.livreAction ? this.$moment() : null,
        user_id_validation: this.livreAction ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => {
          return this.updateSupportDetailsInfosBD({
            support_id: this.livraison.support_id,
            volume_final: this.livraison.volume_final,
          });
        })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    async checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
  watch: {
    workflow: {
      handler(val) {
        this.livraison = { ...val.etapes?.LIVRAISON, volume_final: this.workflow.volume_final };
      },
      deep: true,
    },
  },
  mounted() {
    this.livraison =
      { ...this.workflow?.etapes?.LIVRAISON, volume_final: this.workflow.volume_final } || {};
  },
};
</script>

<style></style>
