<template>
  <h2>{{ title }}</h2>
  <nav class="tabs">
    <router-link
      :to="{ name: 'Admin.Supports', query: { type: 'actifs' }, params: { ...$route.params } }"
      :class="isActifs ? 'router-link-active' : ''"
      active-class="tab"
      >Les {{ title }}</router-link
    >
    <router-link
      :to="{
        name: 'Admin.Supports',
        query: { type: 'archives' },
        params: { ...$route.params },
      }"
      :class="isArchive ? 'router-link-active' : ''"
      active-class="tab"
      >Archives</router-link
    >
  </nav>
  <div class="content tab-content">
    <div class="table-toolbar flex-btn-group right">
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'Admin.CreateSupport', params: { typeSupport } }"
        v-if="hasAction"
      >
        Créer {{ typeSupportsText[typeSupport] }}
      </router-link>
    </div>

    <table class="table">
      <thead>
        <tr>
          <!-- reference -->
          <th v-if="hasProperties.reference">
            <div class="th-title">
              <a
                @click="
                  sorting.reference = !sorting.reference;
                  sortListe('reference', sorting.reference);
                "
              >
                <span>Référence</span>
                <i :class="`far  ${sorting.reference ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <custom-input-type type="text" v-model="searching.reference" @input="filterListe()" />
          </th>
          <!-- nom -->
          <th v-if="hasProperties.nom">
            <div class="th-title">
              <a
                @click="
                  sorting.nom = !sorting.nom;
                  sortListe('nom', sorting.nom);
                "
              >
                <span>Nom</span>
                <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <custom-input-type type="text" v-model="searching.nom" @input="filterListe()" />
          </th>
          <!-- date envoie -->
          <th v-if="hasProperties.date_envoi_souhaitee">
            <div class="th-title">
              <a
                @click="
                  sorting.date_envoi_souhaitee = !sorting.date_envoi_souhaitee;
                  sortListe('date_envoi_souhaitee', sorting.date_envoi_souhaitee);
                "
              >
                <span v-if="!this.isPlv">Date d'envoi</span>
                <span v-if="this.isPlv">Date de livraison</span>
                <i
                  :class="`far  ${sorting.date_envoi_souhaitee ? 'fa-angle-down' : 'fa-angle-up'}`"
                ></i>
              </a>
            </div>
            <field-date-interval v-model="searching.date_envoi_souhaitee" @input="filterListe()" />
          </th>
          <!-- referent -->
          <th v-if="hasProperties.referent_nom">
            <div class="th-title">
              <a
                @click="
                  sorting.referent_nom = !sorting.referent_nom;
                  sortListe('referent_nom', sorting.referent_nom);
                "
              >
                <span>Référent</span>
                <i :class="`far  ${sorting.referent_nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <custom-input-type
              type="text"
              v-model="searching.referent_nom"
              @input="filterListe()"
            />
          </th>
          <!-- date validité -->
          <th v-if="hasProperties.date_validite">
            <div class="th-title">
              <a
                @click="
                  sorting.date_validite = !sorting.date_validite;
                  sortListe('date_validite', sorting.date_validite);
                "
              >
                <span>Date de validité</span>
                <i :class="`far  ${sorting.date_validite ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <field-date-interval v-model="searching.date_validite" @input="filterListe()" />
          </th>
          <!-- statut -->
          <th v-if="hasProperties.statut">
            <div class="th-title">
              <a
                @click="
                  sorting.statut = !sorting.statut;
                  sortListe('statut', sorting.statut);
                "
              >
                <span>Statut</span>
                <i :class="`far  ${sorting.statut ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <custom-basic-select
              :options="statutOptions"
              v-model="searching.statut"
              @change="filterListe()"
            />
          </th>
          <!-- analyse statut -->
          <th v-if="hasProperties.analyse_stats_statut">
            <div class="th-title">
              <a
                @click="
                  sorting.analyse_stats_statut = !sorting.analyse_stats_statut;
                  sortListe('analyse_stats_statut', sorting.analyse_stats_statut);
                "
              >
                <span>Analyse</span>
                <i
                  :class="`far  ${sorting.analyse_stats_statut ? 'fa-angle-down' : 'fa-angle-up'}`"
                ></i>
              </a>
            </div>
            <custom-basic-select
              :options="statutAnalyseOptions"
              v-model="searching.analyse_stats_statut"
              @change="filterListe()"
            />
          </th>
          <th>
            <button class="btn" @click="resetFilter">
              <i class="far fa-sync" /><span>Réinitialiser</span>
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="pageListe.length > 0">
        <support-details
          v-for="support in pageListe"
          :key="support.support_id"
          :support="support"
          :hasProperties="hasProperties"
          @deleteSupport="onDelete"
          @editSupport="onEdit"
          @dupliquerSupport="onDupliquer"
          @archiverSupport="onArchiver"
          @relancerSupport="onRelancer"
        />
      </tbody>
      <div v-else>Aucun résultat</div>
    </table>
  </div>
  <div v-if="nbPages > 1">
    <div class="pagination">
      <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
        <i class="far fa-chevron-left" />
      </button>
      <button
        :class="[i === currentPage + 1 && 'active', 'page-number']"
        v-for="i in nbPages"
        :key="i"
        @click="nextPage(i)"
      >
        <span>{{ i }}</span>
      </button>
      <button
        class="page-number"
        v-if="currentPage < nbPages - 1"
        @click="nextPage(currentPage + 2)"
      >
        <i class="far fa-chevron-right" />
      </button>
    </div>
  </div>
  <custom-modal
    v-if="modal_props.showModal"
    @close="modal_props.showModal = false"
    @confirm="onModalAction(idSupportToAction, modal_props.modalAction)"
    :hasFooter="modal_props.hasFooter"
  >
    <template v-slot:modalTitle> Confirmation </template>
    <template v-slot:modalBody> {{ modal_props.modalMessages }}</template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ANALYSE_STATUT_TEXT, SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../config/Properties";
import CustomBasicSelect from "../../../components/customize/CustomBasicSelect.vue";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
import FieldDateInterval from "../../../components/customize/FieldDateInterval.vue";
import { mixinAction } from "../utils";
import SupportDetails from "./SupportDetails.vue";
import { map } from "lodash";
import CustomModal from "../../../components/customize/CustomModal.vue";

const ACTION_TYPES = {
  DELETE: "DELETE",
  ARCHIVE: "ARCHIVE",
  RELANCE: "RELANCE",
};

export default {
  components: {
    CustomBasicSelect,
    FieldDateInterval,
    CustomInputType,
    SupportDetails,
    CustomModal,
  },
  name: "ListeSupports",
  mixins: [mixinAction],
  data() {
    return {
      isActifs: true,
      nbPages: 0,
      nbPerPage: 30,
      currentPage: 0,
      idSupportToAction: 0,
      currentListe: [],
      sorting: {},
      searching: {},
      modal_props: {
        showModal: false,
        modalMessages: null,
        modaleAction: null,
        hasFooter: true,
      },
    };
  },
  computed: {
    ...mapGetters(["supportsListe"]),
    title() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.EMAIL:
          return "Emails";
        case SUPPORT_TYPES.RICH_SMS:
          return "Rich SMS";
        case SUPPORT_TYPES.PLV:
          return "PLVs";
        default:
          break;
      }
    },
    pageListe() {
      let n = this.currentPage * this.nbPerPage;
      let m = n + this.nbPerPage;
      this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
      return this.currentListe.slice(n, m);
    },
    statutOptions() {
      return {
        [SUPPORT_STATUS.BRIEF_A_FAIRE]: "Brief à faire",
        ...(!this.isPlv && { [SUPPORT_STATUS.A_INTEGRER]: "A intégrer" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: "Intégration à valider" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: "Intégration à modifier" }),
        ...(this.isPlv && { [SUPPORT_STATUS.A_INTEGRER]: "A Créer" }),
        ...(this.isPlv && {
          [SUPPORT_STATUS.INTEGRATION_A_VALIDER]: "Création graphique à valider",
        }),
        ...(this.isPlv && {
          [SUPPORT_STATUS.INTEGRATION_A_MODIFIER]: "Création graphique à modifier",
        }),
        [SUPPORT_STATUS.ML_A_FAIRE]: "ML à faire",
        [SUPPORT_STATUS.ML_A_INTEGRER]: "ML à intégrer",
        [SUPPORT_STATUS.ML_A_VALIDER]: "ML à valider",
        [SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER]: "Intégration ML à modifier",
        ...(!this.isPlv && { [SUPPORT_STATUS.HTML_A_VALIDER]: "HTML à valider" }),
        [SUPPORT_STATUS.BAT_A_FAIRE]: "BAT à faire",
        ...(!this.isPlv && { [SUPPORT_STATUS.BAT_A_VALIDER]: "BAT à valider" }),
        ...(!this.isPlv && {
          [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: "Intégration BAT à modifier",
        }),
        ...(this.isPlv && { [SUPPORT_STATUS.BAT_A_VALIDER]: "BAT numérique à valider" }),
        ...(this.isPlv && {
          [SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER]: "Intégration BAT num à modifier",
        }),
        ...(this.isPlv && { [SUPPORT_STATUS.BAT_PRINT_A_VALIDER]: "BAT Print à valider" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.A_ENVOYER]: "A envoyer" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.STATS_A_FAIRE]: "Stats à faire" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.A_ANALYSER]: "A analyser" }),
        ...(!this.isPlv && { [SUPPORT_STATUS.TERMINE]: "Analysé" }),
        ...(this.isPlv && { [SUPPORT_STATUS.A_LIVRER]: "A Livrer" }),
        ...(this.isPlv && { [SUPPORT_STATUS.LIVRE]: "Livré" }),
        [SUPPORT_STATUS.BROUILLON]: "Brouillon",
      };
    },
    statutAnalyseOptions() {
      return ANALYSE_STATUT_TEXT;
    },
    isArchive() {
      return !this.isActifs;
    },
    hasProperties() {
      return {
        reference: this.isPlv,
        nom: true,
        date_envoi_souhaitee: true,
        referent_nom: true,
        date_validite: this.isPlv,
        statut: true,
        analyse_stats_statut: this.isEmail || this.isRichSMS,
      };
    },
  },
  methods: {
    ...mapActions([
      "setSupportsListe",
      "deleteSupportDB",
      "archiverSupportDB",
      "dupliquerSupportDB",
      "relancerSupport",
    ]),
    initSupports() {
      return this.setSupportsListe({ type: this.typeSupport }).then(
        () =>
          (this.currentListe = [...this.supportsListe].filter(support =>
            this.isActifs ? !support.is_archive : support.is_archive
          ))
      );
    },
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = this.getFilterSupports(this.supportsListe);
      map(this.searching, (search, key) => {
        if (search) {
          search = ["statut", "analyse_stats_statut"].includes(key) ? "^" + search : search;
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(support => {
            switch (key) {
              case "date_envoi_souhaitee":
              case "date_validite":
                let supportDate = this.$moment(support[key], "YYYY-MM-DD");
                return search.startDate && search.endDate
                  ? supportDate.isBetween(search.startDate, search.endDate, null, "[]")
                  : search.startDate
                  ? supportDate.isSameOrAfter(search.startDate)
                  : search.endDate
                  ? supportDate.isSameOrBefore(search.endDate)
                  : true;
              default:
                return regex.test(support[key]);
            }
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = this.getFilterSupports(this.supportsListe);
      map(this.searching, (value, key) => {
        if (["date_envoi_souhaitee", "date_validite"].includes(key)) {
          this.searching[key].startDate = null;
          this.searching[key].endDate = null;
        } else {
          this.searching[key] = null;
        }
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
        return sortKey ? -1 : 1;
      }
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
        return sortKey ? 1 : -1;
      }
      return a[key] === null ? 1 : b[key] === null ? -1 : 0;
    },
    onDelete(id) {
      this.modal_props.showModal = true;
      this.idSupportToAction = id;
      this.modal_props.modalMessages = "Souhaitez-vous vraiment supprimer ce support ?";
      this.modal_props.modalAction = ACTION_TYPES.DELETE;
      this.modal_props.hasFooter = true;
    },
    onEdit(id) {
      this.isCreate = false;
      this.isEdit = true;
      this.idSupportToAction = id;
    },
    onArchiver(id) {
      this.modal_props.showModal = true;
      this.modal_props.modalMessages = "Souhaitez-vous vraiment archiver ce support ?";
      this.modal_props.modalAction = ACTION_TYPES.ARCHIVE;
      this.modal_props.hasFooter = true;
      this.idSupportToAction = id;
    },
    onDupliquer(id) {
      return this.dupliquerSupportDB({ id }).catch(e => {
        alert(e.response?.data?.message || e);
      });
    },
    onRelancer(id) {
      return this.relancerSupport(id)
        .then(() => {
          this.modal_props.showModal = true;
          this.modal_props.modalMessages = "L'email de relance a été envoyé";
          this.modal_props.modalAction = ACTION_TYPES.RELANCE;
          this.modal_props.hasFooter = false;
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        });
    },
    getFilterSupports(supports) {
      return supports.filter(support => (this.isActifs ? !support.is_archive : support.is_archive));
    },
    deleteSupport(id) {
      this.modal_props.showModal = false;
      return this.deleteSupportDB({ id }).catch(e => {
        alert(e.response?.data?.message || e);
      });
    },
    archiverSupport(id) {
      this.modal_props.showModal = false;
      return this.archiverSupportDB({ id }).catch(e => {
        alert(e.response?.data?.message || e);
      });
    },
    onModalAction(id, action) {
      switch (action) {
        case ACTION_TYPES.DELETE:
          return this.deleteSupport(id);
        case ACTION_TYPES.ARCHIVE:
          return this.archiverSupport(id);
      }
    },
  },
  watch: {
    supportsListe: {
      handler(val) {
        this.currentListe = this.getFilterSupports(val);
      },
      deep: true,
    },

    $route: {
      handler(val) {
        this.isActifs = val.query.type === "actifs";
        return this.initSupports();
      },
    },
  },
  created() {
    this.isActifs = this.$route.query.type === "actifs";
    return this.initSupports();
  },
};
</script>
