<template>
  <div class="row justify-content-between">
    <div class="col-12 col-lg-5 col-xl-4">
      <div class="form-col-title">Date d'envoi</div>
      <field-radio-buttons
        class="inline"
        label="AB / Testing * :"
        ref="ciblage.abTesting"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        :rules="validations.ab_testing"
        v-model="ciblage.ab_testing"
      />
      <div v-if="ciblage.ab_testing">
        <div>
          <div class="form-col-title">Test A</div>
          <custom-input-type
            class="inline"
            label="Date et Heure * :"
            type="datetime-local"
            v-model="ciblage.abtest_a_date"
            ref="ciblage.abtest_a_date"
            :rules="validations.abtest_date"
          />
          <custom-input-type
            class="inline"
            :label="`Objet ${isEmail ? '*' : ''} :`"
            type="text"
            v-model="ciblage.abtest_a_objet"
            ref="ciblage.abtest_a_objet"
            :rules="validations.abtest_objet"
          />
        </div>
        <div>
          <div class="form-col-title">Test B</div>
          <custom-input-type
            class="inline"
            label="Date et Heure * :"
            type="datetime-local"
            v-model="ciblage.abtest_b_date"
            ref="ciblage.abtest_b_date"
            :rules="validations.abtest_date"
          />
          <custom-input-type
            class="inline"
            label="Objet * :"
            type="text"
            v-model="ciblage.abtest_b_objet"
            ref="ciblage.abtest_b_objet"
            :rules="validations.abtest_objet"
          />
        </div>
        <div>
          <div class="form-col-title">Solde</div>
          <custom-input-type
            class="inline"
            label="Date et Heure :"
            type="datetime-local"
            v-model="ciblage.absolde_date"
          />
          <custom-input-type
            class="inline"
            label="Objet :"
            type="text"
            v-model="ciblage.absolde_objet"
          />
        </div>
      </div>
      <div class="form-col-title">Repasse</div>

      <div class="row" v-if="hasRepasse">
        <div class="col-sm-auto-center">
          <field-radio-buttons
            label="Repasse non ouvreur"
            class="inline center"
            :buttons="optionsRepasse"
            v-model="repasse"
          />
          <custom-input-type
            class="inline center"
            v-if="repasse === true"
            label="Précision"
            type="text"
            ref="repasse_autre"
            v-model="repasse_autre"
          />
        </div>
        <div class="col-sm-auto-center">
          <custom-input-type
            class="inline center suffixed input-jours"
            v-if="repasse === true"
            label="Jours"
            type="number"
            ref="repasse_nb_jours"
            v-model="repasse_nb_jours"
          />
        </div>
      </div>
      <!--  -->
      <div class="form-col-title">Type d'email</div>
      <span class="btn-explication">
        PAC (90) / Conq et Multi (80) / PRP (70) / Selfcare (60) / Rela (50) / NL (40)
      </span>
      <custom-multi-check-box
        class="center"
        :buttons="[
          { label: 'Parcours', value: 'Parcours' },
          { label: 'Institutionnel', value: 'Institutionnel' },
          { label: 'Gestion', value: 'Gestion' },
          { label: 'Enquête', value: 'Enquête' },
          { label: 'Trigger', value: 'Trigger' },
          { label: 'PAC', value: 'PAC' },
          { label: 'Conq', value: 'Conq' },
          { label: 'Multi', value: 'Multi' },
          { label: 'PRP', value: 'PRP' },
          { label: 'Selfcare', value: 'Selfcare' },
          { label: 'Relationnel', value: 'Relationnel' },
          { label: 'NL', value: 'NL' },
        ]"
        v-model="ciblage.type_email"
        ref="ciblage.type_email"
        :rules="validations.type_email"
      />
      <span v-if="showTypeMailMsg" style="color: #006a53"
        >Génération automatique d'un fichier cliqueurs dans les évènementiels</span
      >
      <div class="form-col-title">Relance</div>
      <field-radio-buttons
        class="center"
        label="Relance cliqueurs automatique Compass évènementiel * :"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        v-model="ciblage.relance"
        ref="ciblage.relance"
        :rules="validations.relance"
      />
      <custom-input-type
        class="inline"
        v-if="!ciblage.relance"
        type=" text"
        label="Précisez"
        placeholder="Type de relance si besoin"
        v-model="ciblage.relance_type"
      />

      <!-- ticket #8452 -->
      <!-- <field-radio-buttons
        class="inline"
        label="Campagne GRC * :"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        v-model="ciblage.campagne_grc"
        ref="ciblage.campagne_grc"
        :rules="validations.campagne_grc"
      />
      <custom-input-type
      class="inline"
      v-if="ciblage.campagne_grc"
      type=" text"
      label="Nom de la campagne"
      v-model="ciblage.campagne_grc_nom"
      />-->
    </div>
    <div class="col-12 col-lg-6 col-xl-7">
      <div class="form-col-title">Exclusions / Dédoublonnage*</div>
      <custom-multi-check-box
        class="center"
        :buttons="optionsBoitesExclusion"
        v-model="ciblage.exclusion"
        ref="ciblage.exclusion"
        :rules="validations.exslusion"
      />
      <custom-text-area
        class="inline"
        type="text"
        label="Autre :"
        v-model="ciblage.exclusion_autre"
      />

      <!-- Filtres -->
      <div class="form-col-title">Filtres</div>
      <custom-multi-check-box
        class="center"
        :buttons="[
          { label: 'Dédoublonnage Foyer', value: 'Dédoublonnage Foyer' },
          { label: 'Pression Email', value: 'Pression Email' },
        ]"
        v-model="ciblage.filtre.type"
      />

      <custom-multi-check-box
        class="inline"
        label="Marché * :"
        v-model="ciblage.filtre.marche"
        ref="ciblage.filtre.marche"
        :rules="validations.marche"
        :buttons="[
          { label: 'PART', value: 'PART' },
          { label: 'AGRI', value: 'AGRI' },
          { label: 'ACPS', value: 'ACPS' },
          { label: 'ENT', value: 'ENT' },
          { label: 'COLLECTIVE', value: 'COLLECTIVE' },
          { label: 'COLLECTIVITES', value: 'COLLECTIVITES' },
        ]"
      />
      <div class="col-12">
        <div class="row justify-content-between">
          <div class="col-12 col-lg-4">
            <custom-input-type
              class="inline"
              type="text"
              label="Age :"
              v-model="ciblage.filtre.age"
            />
          </div>
          <div class="col-12 col-lg-8 col-lg-6">
            <custom-multi-check-box
              class="inline"
              label="Type personne * :"
              :buttons="[
                { label: 'PP', value: 'PP' },
                { label: 'PM', value: 'PM' },
              ]"
              v-model="ciblage.filtre.type_personne"
              ref="ciblage.filtre.type_personne"
              :rules="validations.type_personne"
            />
          </div>
        </div>
      </div>
      <field-radio-buttons
        class="inline"
        label="Contactabilite * :"
        :buttons="contactabiliteBtns"
        v-model="ciblage.filtre.contactabilite"
        ref="ciblage.filtre.contactabilite"
        :rules="validations.contactabilite"
      />
      <div class="col-12">
        <div class="row">
          <div
            class="col-12 col-sm-6"
            v-for="(critere, index) in ciblage.filtre.criteres"
            :key="`Critère ${index}`"
          >
            <custom-input-type
              v-model="ciblage.filtre.criteres[index]"
              class="inline"
              :label="`Critère ${index + 1}`"
            />
          </div>
        </div>
      </div>
      <div class="flex-btn-group right">
        <div class="field">
          <button class="btn-link" @click="ciblage.filtre.criteres.push(null)">
            + de critères
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <!-- Commentaire -->
    <custom-text-area class="inline" label="Commentaire :" v-model="ciblage.ciblage_commentaire" />

    <field-drop-zone-file-multiple
      :hasLink="true"
      class="inline"
      specificPath="ciblage"
      label="PJ :"
      v-model="ciblage.pj"
      ref="image.image"
      :rules="validations.image"
    />

  </div>
  <div class="form-footer flex-btn-group center">
    <!-- Actions -->
    <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
    <button class="btn btn-secondary" @click="onBrouillon">Brouillon</button>
    <button class="btn btn-secondary" @click="submit">Valider</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomBasicSelect from "../../../../components/customize/CustomBasicSelect.vue";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import CustomMultiCheckBox from "../../../../components/customize/CustomMultiCheckBox.vue";
import CustomTextArea from "../../../../components/customize/CustomTextArea.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldDropZoneFileMultiple from "../../../../components/customize/FieldDropZoneFileMultiple.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../../components/customize/FieldSelect.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
export default {
  components: {
    FieldRadioButtons,
    CustomInputType,
    CustomMultiCheckBox,
    FieldSelect,
    CustomBasicSelect,
    CustomTextArea,
    FieldDropzoneFile,
    FieldDropZoneFileMultiple,
  },
  name: "FormFicheCiblage",
  props: {
    toEdit: {
      type: Boolean,
      default: false,
    },
    created: {
      type: Boolean,
      default: true,
    },
    ciblageModel: {
      type: Object,
      default: {
        ab_testing: false,
        abtest_a_date: null,
        abtest_a_objet: null,
        abtest_b_date: null,
        abtest_b_objet: null,
        absolde_date: null,
        absolde_objet: null,
        relance: true,
        type_email: [],
        relance_type: null,
        campagne_grc: false,
        campagne_grc_nom: null,
        exclusion: [
          "Collaborateurs",
          "DCD",
          "MR CTX",
          "Tutelle / Curatelle",
          "FM LAB LAT",
          "Client Amaline",
        ],
        exclusion_autre: null,
        ciblage_commentaire: null,
        brouillon: false,
        pj: [],
        filtre: {
          type: ["Dédoublonnage Foyer", "Pression Email"],
          marche: [],
          age: null,
          type_personne: [],
          contactabilite: null,
          criteres: [null, null, null, null],
        },
      },
    },
  },
  mixins: [mixinAction],
  data() {
    return {
      ciblage: this.ciblageModel,
      showTypeMailMsg: false,
      repasse: null,
      repasse_nb_jours: 7,
      repasse_autre: null,

      optionsRepasse: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["referentielBoitesListe"]),
    validations() {
      return {
        ab_testing: {
          required: "Merci de renseigner l'AB / Testing",
        },
        abtest_date: {
          required: "Merci de renseigner une date",
        },
        abtest_objet: {
          requiredIf: {
            model: this.isEmail,
            message: "Merci de renseigner un objet",
          },
        },
        relance: {
          required: "Merci de renseigner la relance",
        },
        campagne_grc: {
          required: "Merci de renseigner campagne grc",
        },
        exslusions: {
          required: "Merci de renseigner les excluisons",
        },
        type_personne: {
          required: "Merci de renseigner le type de personne",
        },
        contactabilite: {
          required: "Merci de renseigner la contactabilité",
        },
        marche: {
          required: "Merci de renseigner la marché",
        },
        type_email: {
          required: "Merci de renseigner le type d'email",
        },
        image: {
          weight: {
            max: 5, // en Mb
            message: "La taille maximum du fiche doit être 5 Mb",
          },
        },
      };
    },
    hasRepasse() {
      console.log("from has repasse", this.workflowType);
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
          return true;
        default:
          return false;
      }
    },
    contactabiliteBtns() {
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
          return [
            { label: "Email commercial", value: "Email commercial" },
            { label: "Email Relationnel", value: "Email Relationnel" },
          ];
        case SUPPORT_TYPES.RICH_SMS:
          return [
            { label: "Commercial", value: "Commercial" },
            { label: "Relationnel", value: "Relationnel" },
          ];
        default:
          return [];
      }
    },
    optionsBoitesExclusion() {
      return this.referentielBoitesListe
        .filter(b => b.is_actif)
        .map(b => ({ label: b.nom, value: b.nom }));
    },
  },
  methods: {
    ...mapActions(["addCiblageBD", "setReferentielBoitesListe", "updateSupportDetailsInfosBD"]),
    onBrouillon() {
      this.ciblage.brouillon = true;
      return this.submit();
    },
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      this.ciblage.toEdit = this.toEdit;
      this.ciblage.created = this.created;
      return (
        this.addCiblageBD({
          ...this.ciblage,
          validation_user: this.currentUser,
          validation_date: this.$moment(),
          fromFicheData: true
        })
          .then(() =>
            this.updateSupportDetailsInfosBD({
              support_id: this.ciblage.ciblage_id,
              repasse: this.repasse,
              repasse_autre: this.repasse ? this.repasse_autre : null,
              repasse_nb_jours: this.repasse ? this.repasse_nb_jours : 7,
            })
          )
          .then(() => this.$router.push(this.backRoute))
          .catch(e => alert(e.response?.data?.message || e))
      );
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
  watch: {
    "ciblage.relance": {
      handler(val) {
        if (val) {
          this.ciblage.relance_type = null;
        }
      },
    },
    "ciblage.campagne_grc": {
      handler(val) {
        if (!val) {
          this.ciblage.campagne_grc_nom = null;
        }
      },
    },
    "ciblage.type_email": {
      handler(val) {
        if (val.join().length > 0 && val.every(v => ["PAC", "Multi"].includes(v))) {
          this.ciblage.relance = true;
          this.showTypeMailMsg = true;
        } else {
          this.showTypeMailMsg = false;
        }
      },
    },
    "ciblage.ab_testing": {
      handler(val) {
        if (!val) {
          this.ciblage.abtest_a_date = null;
          this.ciblage.abtest_a_objet = null;
          this.ciblage.abtest_b_date = null;
          this.ciblage.abtest_b_objet = null;
          this.ciblage.absolde_date = null;
          this.ciblage.absolde_objet = null;
        }
      },
    },
  },
  mounted() {
    this.repasse = this.workflow.repasse;
    this.repasse_nb_jours = this.workflow.repasse_nb_jours;
    this.repasse_autre = this.workflow.repasse_autre;
    return this.setReferentielBoitesListe();
  }
};
</script>
