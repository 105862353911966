import { api } from "../../utils/api";

import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_LOADING = "SET_LOADING";
export const SET_SUPPORTS_LISTE = "SET_SUPPORTS_LISTE";
export const ADD_SUPPORT_TO_LISTE = "ADD_SUPPORT_TO_LISTE";
export const REMOVE_SUPPORT_FROM_LISTE = "REMOVE_SUPPORT_FROM_LISTE";
export const ARCHIVE_SUPPORT_FROM_LISTE = "ARCHIVE_SUPPORT_FROM_LISTE";
export const SET_WORKFLOW = "SET_WORKFLOW";

// axios.defaults.withCredentials = true;
// toutes les mutations sont appelées ici
// PAS DE PROMISE DANS LES MUTATIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// tous les changements du store doivent être fait ici
export const mutations = {
  [SET_SUPPORTS_LISTE]: (state, data) => {
    state.globals.supportsListe = data;
  },
  [ADD_SUPPORT_TO_LISTE]: (state, data) => {
    state.globals.supportsListe.push(data);
  },
  [REMOVE_SUPPORT_FROM_LISTE]: (state, data) => {
    state.globals.supportsListe = state.globals.supportsListe.filter(
      email => email.support_id !== data.support_id
    );
  },
  [ARCHIVE_SUPPORT_FROM_LISTE]: (state, data) => {
    state.globals.supportsListe = state.globals.supportsListe.map(email => {
      if (email.support_id === data.support_id) email.is_archive = true;
      return email;
    });
  },
};

export const actions = {
  deleteSupportDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .delete(`/supports/${data.id}`)
      .then(res => {
        commit(REMOVE_SUPPORT_FROM_LISTE, { support_id: data.id });
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        return false;
      });
  },
  getTasks({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .get("/supports/tasks")
      .then(res => {
        commit(SET_LOADING, false);
        return res.data.tasks;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  setSupportsListe({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .get(`${data?.type ? "/supports?type=" + data.type : "/supports"}`)
      .then(res => {
        commit(SET_SUPPORTS_LISTE, res.data.supports);
        commit(SET_LOADING, false);
      })
      .catch(e => {
        console.log(e);
        commit(SET_SUPPORTS_LISTE, []);
        return commit(SET_LOADING, false);
      });
  },
  addSupportDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api({
      method: data.support_id ? "PUT" : "POST",
      url: data.support_id ? `/supports/${data.support_id}` : "/supports",
      data: { ...data },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  archiverSupportDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .put(`supports/archive/${data.id}`)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(ARCHIVE_SUPPORT_FROM_LISTE, { support_id: data.id });
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  dupliquerSupportDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .put(`supports/dupliquer/${data.id}`)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(ADD_SUPPORT_TO_LISTE, res.data.support);
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  getSupportDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .get(`/supports/${data.id}`)
      .then(res => {
        let support = res.data.support;
        commit(SET_LOADING, false);
        return support;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  setSupportWorkflow({ commit }, data) {
    commit(SET_LOADING, true);
    if (data?.id) {
      return api
        .get(`/supports/workflow/${data.id}`)
        .then(res => {
          let workflow = res.data.workflow;
          commit(SET_WORKFLOW, workflow);
          return commit(SET_LOADING, false);
        })
        .catch(e => {
          console.log(e);
          commit(SET_LOADING, false);
          commit(SET_WORKFLOW, {});
          throw e;
        });
    }
    commit(SET_WORKFLOW, {});
    commit(SET_LOADING, false);
  },
  relancerSupport({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .get(`/supports/relance/${data}`)
      .then(res => {
        commit(SET_LOADING, false);
        return res;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  updateSupportStatut({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .put(`/supports/infos/${data.support_id}`, data)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  updateSupportDetailsInfosBD({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .put(`/support-details/${data.support_id}`, data)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  uploadStats({ commit }, data) {
    let formData = new FormData();
    formData.append("stats_globales", data.stats_globales);
    formData.append("stats_cta", data.stats_cta);
    formData.append("stats_performance", data.stats_performance);
    commit(SET_LOADING, true);
    return api
      .put(`/supports/stats/${data.support_id}`, formData)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
};

export const getters = {
  supportsListe: state => {
    return state.globals.supportsListe;
  },
  supportEquipe: state => {
    return state.globals.workflow.equipe;
  },
  hasStats: (state, getters) => {
    return getters.workflow?.stats_cta?.length > 0 && getters.workflow?.stats_globales?.length > 0;
  },
  statsGlobales: (state, getters) => {
    return getters.workflow?.stats_globales ?? [];
  },
  statsCTAs: (state, getters) => {
    return getters.workflow?.stats_cta ?? [];
  },
  statsPerformance: (state, getters) => {
    return getters.workflow?.stats_performance ?? [];
  },
};
