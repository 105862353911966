<template>
  <div class="block-stats" v-if="hasTaskRetard">
    <h2 class="titre-centre">En retard</h2>
    <div class="list-tasks">
      <card-task
        v-for="task in tasks.supports.tasksRetard"
        :key="task.nom"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
      />
      <card-task
        v-for="task in tasks.ciblages.tasksRetard"
        :key="`ciblage-${task.nom}`"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :isCiblage="true"
      />
    </div>
  </div>

  <div class="block-stats" v-if="hasTaskInWeek">
    <h2 class="titre-centre">Cette semaine</h2>
    <div class="list-tasks">
      <card-task
        v-for="task in tasks.supports.tasksInWeek"
        :key="task.nom"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
      />
      <card-task
        v-for="task in tasks.ciblages.tasksInWeek"
        :key="`ciblage-${task.nom}`"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :isCiblage="true"
      />
    </div>
  </div>

  <div class="block-stats" v-if="hasTaskNextWeek">
    <h2 class="titre-centre">La semaine prochaine</h2>
    <div class="list-tasks">
      <card-task
        v-for="task in tasks.supports.tasksNextWeek"
        :key="task.nom"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
      />
      <card-task
        v-for="task in tasks.ciblages.tasksNextWeek"
        :key="`ciblage-${task.nom}`"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :isCiblage="true"
      />
    </div>
  </div>

  <div class="block-stats" v-if="hasTaskFuturs">
    <h2 class="titre-centre">Prochainement</h2>
    <div class="list-tasks">
      <card-task
        v-for="task in tasks.supports.tasksFuturs"
        :key="task.nom"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
      />
      <card-task
        v-for="task in tasks.ciblages.tasksFuturs"
        :key="`ciblage-${task.nom}`"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :isCiblage="true"
      />
    </div>
  </div>
  <div class="block-stats" v-if="hasTaskToDo">
    <h2 class="titre-centre">À faire</h2>
    <div class="list-tasks">
      <card-task
        v-for="task in tasks.supports.tasksToDo"
        :key="task.nom"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :disabled="true"
      />
      <card-task
        v-for="task in tasks.ciblages.tasksToDo"
        :key="`ciblage-${task.nom}`"
        :typeSupport="task.type"
        :task="task"
        name="GBH"
        :isCiblage="true"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CardStat from "../../../components/CardStat.vue";
import CardTask from "../../../components/CardTask.vue";

export default {
  components: { CardStat, CardTask },
  name: "UserAccueil",
  computed: {
    hasTaskRetard() {
      return (
        this.tasks?.supports?.tasksRetard?.length > 0 ||
        this.tasks?.ciblages?.tasksRetard?.length > 0
      );
    },
    hasTaskInWeek() {
      return (
        this.tasks?.supports?.tasksInWeek?.length > 0 ||
        this.tasks?.ciblages?.tasksInWeek?.length > 0
      );
    },
    hasTaskNextWeek() {
      return (
        this.tasks?.supports?.tasksNextWeek?.length > 0 ||
        this.tasks?.ciblages?.tasksNextWeek?.length > 0
      );
    },
    hasTaskFuturs() {
      return (
        this.tasks?.supports?.tasksFuturs?.length > 0 ||
        this.tasks?.ciblages?.tasksFuturs?.length > 0
      );
    },
    hasTaskToDo() {
      return (
        this.tasks?.supports?.tasksToDo?.length > 0 ||
        this.tasks?.ciblages?.ciblageTasks?.tasksToDo?.length > 0
      );
    },
  },
  methods: {
    ...mapActions(["getTasks"]),
  },
  data() {
    return {
      tasks: {},
    };
  },
  mounted() {
    this.getTasks().then(tasks => {
      this.tasks = tasks;
    });
  },
};
</script>

<style></style>
