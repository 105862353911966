<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <select class="form-select" @change="onChange" :value="modelValue" :disabled="disabled">
        <option disabled value="">...Séléctionnez...</option>
        <option
          v-for="(option, value) in options"
          :key="option"
          :value="value"
          :selected="modelValue === option"
        >
          {{ option }}
        </option>
      </select>
    </div>
    <label class="form-label" v-if="label" :for="id"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    options: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Date, Boolean],
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(e) {
      return this.$emit("update:modelValue", e.target.value);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
