import { mapGetters } from "vuex";
import { SUPPORT_TYPES, SUPPORT_TYPES_TEXT } from "../../../config/Properties";

export const mixinAction = {
  computed: {
    ...mapGetters([
      "isAdmin",
      "isCoordinateur",
      "isUser",
      "loading",
      "workflow",
      "currentUser",
      "supportEquipe",
      "usersListe",
      "referentielImagesListe",
      "supportCiblage",
      "supportBrief",
      "getUserFromListe",
      "hasStats",
      "statsGlobales",
      "statsCTAs",
      "statsPerformance",
      "workflowCommentaires",
    ]),
    hasAction() {
      return this.isAdmin || this.isCoordinateur;
    },
    workflowType() {
      return this.workflow?.type;
    },
    backRoute() {
      return { name: "Admin.Supports", params: { typeSupport: this.workflowType } };
    },
    isEmail() {
      return this.workflow.type === SUPPORT_TYPES.EMAIL || this.typeSupport === SUPPORT_TYPES.EMAIL;
    },
    isRichSMS() {
      return (
        this.workflow.type === SUPPORT_TYPES.RICH_SMS || this.typeSupport === SUPPORT_TYPES.RICH_SMS
      );
    },
    isPlv() {
      return this.workflow.type === SUPPORT_TYPES.PLV || this.typeSupport === SUPPORT_TYPES.PLV;
    },
    typeSupport() {
      return this.$route.params.typeSupport;
    },
    typeSupportsText() {
      return SUPPORT_TYPES_TEXT;
    },
    supportTypes() {
      return SUPPORT_TYPES;
    },
  },
  methods: {},
};
