import Vuex from "vuex";
import { mutations, actions, getters } from "./modules";
import state from "./state";

// import createPersistedState from "vuex-persistedstate";

// TODO a vérfiier si le local storage est opérationnel
// add local session storage
// const vuexPersistState = createPersistedState({
//   key: "vuex-survey-starter",
//   storage: sessionStorage,
//   reducer: state => ({}),
// });

const store = new Vuex.Store({
  // plugins: [vuexPersistState],
  state,
  mutations,
  actions,
  getters,
  modules: {},
  // strict: !__PROD__,
});

export default store;
