<template>
  <loading v-if="loading" />
  <div v-else>
    <div data-label="Nom">
      <strong>Nom : </strong>
      <span>{{ boite.nom }}</span>
    </div>
    <div data-label="Id Adobe">
      <strong>Id Adobe : </strong>
      <span>{{ boite.id_adobe }}</span>
    </div>
    <div data-label="Description">
      <strong>Description : </strong>
      <span>{{ boite.description }}</span>
    </div>
    <div data-label="Précoché">
      <strong>Précoché : </strong>
      <span>{{ boite.pre_selectionne ? "Oui" : "Non" }}</span>
    </div>
    <div data-label="Actif">
      <strong>Actif : </strong>
      <span>{{ boite.is_actif ? "Oui" : "Non" }}</span>
    </div>
    <div data-label="Date de création">
      <strong>Date de création : </strong>
      <span>{{ getDateFormat(boite.created_at, "DD/MM/YYYY") }}</span>
    </div>
    <div data-label="Date de MAj">
      <strong>Date de MAj : </strong>
      <span>{{ getDateFormat(boite.updated_at, "DD/MM/YYYY") }}</span>
    </div>
    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-secondary" :to="{ name: 'Admin.Referentiel.Boite' }"
        >Retour à la liste</router-link
      >
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'Admin.Referentiel.Boite.Edit', params: { id: boite.referentiel_id } }"
      >
        Modifier</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../../../components/layouts/Loading.vue";
export default {
  components: { Loading },
  name: "DetailsBoite",
  data() {
    return {
      boite: {},
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["getReferentielBoite", "setLoading"]),
    getDateFormat(d, format) {
      return this.$moment(d).format(format);
    },
  },
  created() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielBoite = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielBoite = this.$route.params.id;
        }
        if (idReferentielBoite) {
          return this.getReferentielBoite({ id: idReferentielBoite });
        }
      })
      .then(boite => {
        if (boite) this.boite = { ...boite };
      })
      .then(() => this.setLoading(false));
  },
};
</script>
