<template>
  <div class="content tab-content">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7 minH-100">
        <div v-if="isDone">
          <div class="form-col-title">Validation</div>
          <div class="text-center">
            HTML validée par {{ this.html.user.prenom }} {{ this.html.user.nom }} le
            {{ $moment(this.html.date_validation).format("DD/MM/YYYY") }}
          </div>
        </div>
        <div class="h-100" v-else>
          <!-- Form Validation -->
          <div v-if="toValidate">
            <div class="form-col-title">Validation</div>
            <field-radio-buttons
              label="Je valide :"
              class="inline center"
              :buttons="[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]"
              v-model="html.statut_validation"
              ref="html.statut_validation"
              :rules="validations.statut_validation"
            />
            <div class="field" v-if="html.statut_validation === false">
              <custom-editor-text
                label="Commentaire * :"
                v-model="html.commentaire_validation"
                ref="html.commentaire_validation"
                :rules="validations.commentaire_validation"
              />
            </div>
            <div class="flex-btn-group center">
              <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
              <button class="btn btn-secondary" @click="submit">Valider</button>
            </div>
          </div>
          <div class="encours" v-else>Validation HTML en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import { mixinAction } from "../../utils";

export default {
  components: {
    FieldDropzoneFile,
    CustomInputType,
    FieldRadioButtons,
    CustomEditorText,
    SupportUrlVisuel,
  },
  name: "WorkflowValidationHTML",
  data() {
    return {
      html: {},
    };
  },
  mixins: [mixinAction],
  computed: {
    ...mapGetters(["getUserFromListe"]),
    validations() {
      return {
        statut_validation: { required: "Merci de répondre à la validation" },
        commentaire_validation: {
          requiredIf: {
            model: !this.html.statut_validation,
            message: "Merci de saisir un commentaire",
          },
        },
      };
    },
    toValidate() {
      if (this.workflow.statut === SUPPORT_STATUS.HTML_A_VALIDER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.CM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    isDone() {
      return this.html?.is_valide;
    },
  },
  methods: {
    ...mapActions(["addSupportEtapeBD", "updateSupportStatut"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.html,
        statut: this.statutToApi(),
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
        id_autre_etape: !this.isValidated()
          ? this.workflow.etapes.INTEGRATION.support_etape_id
          : null,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    statutToApi() {
      return this.toValidate && this.html.statut_validation
        ? SUPPORT_STATUS.BAT_A_FAIRE
        : SUPPORT_STATUS.INTEGRATION_A_MODIFIER;
    },
    isValidated() {
      return this.html?.statut_validation;
    },
  },
  mounted() {
    this.html = this.workflow?.etapes?.HTML || {};
  },
  watch: {
    workflow: {
      handler(val) {
        this.html = { ...val?.etapes?.HTML };
      },
      deep: true,
    },
    "html.statut_validation": {
      handler(val) {
        if (val !== "Non") this.html.commentaire_validation = null;
      },
    },
  },
};
</script>
