<template>

    <!-- VISUEL -->
    <template v-if="!isPlv">
      <div class="form-col-title">Maquette</div>
      <div class="preview-email">
        <img v-if="visuelSupport" :src="visuelSupport" alt="Visuel du mail" />
      </div>
      <div v-if="!visuelToEdit" class="text-center">
        <button @click="onEditVisuel" v-if="canVisuBeEdited" class="btn-link">
          modifier le visuel
        </button>
      </div>
      <div v-if="visuelToEdit">
        <div class="text-center">
          <field-dropzone-file
            class="inline"
            specificPath="referentiel"
            label="Visuel :"
            acceptFilesType="['image/png', 'image/jpeg', 'image/jpg', 'image/svg']"
            v-model="visuel"
          />
          <div class="flex-btn-group center">
            <button class="btn btn-login btn-secondary" @click="onSubmitVisu">Valider</button>
            <button class="btn btn-login btn-secondary" @click="visuelToEdit = false">Annuler</button>
          </div>
        </div>
      </div>
    </template>

    <div class="form-col-title mt-4">{{ title }}</div>

    <template v-if="isEmail || isRichSMS">
      <div v-if="!urlToEdit" class="text-center">
        <div class="flex-btn-group center" v-if="isEmail">
          <button class="btn btn-secondary" v-if="lienIntegrationSupport" @click="copy">
            Copier le lien
          </button>
          <span class="btn-explication" v-if="lienIntegrationSupport">puis le coller dans un onglet</span>
        </div>
        <button class="btn btn-secondary mt-3" v-if="lienIntegrationSupport && isRichSMS" @click="urlView">
        Voir l'intégration
        </button>
        <br/>
        <br/>
        <button @click="onEditUrl" v-if="canBeEdited && !urlToEdit" class="btn-link">modifier l'url</button>
      </div>
      <template v-else>
        <custom-input-type
          class="inline"
          label="URL * :"
          type="text"
          v-model="lien"
          ref="lien"
          :rules="validations.url"
        />
        <custom-input-type
          class="inline"
          v-if="isRichSMS"
          label="ID SINCH"
          type="text"
          v-model="numero_adobe"
        />
        <custom-input-type
          class="inline"
          label="Code tracking :"
          type="text"
          v-model="code_tracking"
          ref="code_tracking"
        />
        <custom-input-type
          class="inline"
          label="Commentaire :"
          type="text"
          v-model="commentaire"
          ref="commentaire"
        />
        <div class="flex-btn-group center">
          <button class="btn btn-login btn-secondary" @click="onSubmit">Valider</button>
          <button class="btn btn-login btn-secondary" @click="urlToEdit = false">Annuler</button>
        </div>
      </template>
    </template>

    <template v-if="isPlv">
      <div class="preview-email">
        <img v-if="visuelSupport" :src="visuelSupport" alt="Visuel du mail" />
      </div>
      <div v-if="!visuelToEdit" class="text-center">
        <button @click="onEditVisuel" v-if="canVisuBeEdited" class="btn-link">
          modifier le visuel
        </button>
      </div>
      <div v-if="visuelToEdit">
        <div class="text-center">
          <field-dropzone-file
            class="inline"
            specificPath="referentiel"
            label="Visuel :"
            acceptFilesType="['image/png', 'image/jpeg', 'image/jpg', 'image/svg']"
            v-model="visuel"
          />
          <div class="flex-btn-group center">
            <button class="btn btn-login btn-secondary" @click="onSubmitVisu">Valider</button>
            <button class="btn btn-login btn-secondary" @click="visuelToEdit = false">Annuler</button>
          </div>
        </div>
      </div>

      <div v-if="!urlToEdit" class="text-center">
        <div class="flex-btn-group center mt-3">
          <button class="btn btn-secondary" v-if="lienIntegrationSupport" @click="copy">
            Copier le lien
          </button>
          <span class="btn-explication" v-if="lienIntegrationSupport">puis le coller dans un onglet</span>
        </div>
        <button @click="onEditUrl" v-if="canBeEdited && !urlToEdit" class="btn-link mt-3">modifier l'url</button>
      </div>
      <div v-else>
        <div>
          <custom-input-type
            class="inline"
            label="URL * :"
            type="text"
            v-model="lien"
            ref="lien"
            :rules="validations.url"
          />
          <div class="flex-btn-group center">
            <button class="btn btn-login btn-secondary" @click="onSubmit">Valider</button>
            <button class="btn btn-login btn-secondary" @click="urlToEdit = false">Annuler</button>
          </div>
        </div>
      </div>
      <div v-if="!refToEdit">
        <div class="flex-btn-group center mt-3">
          <span> Référence : {{ referenceSupport }} </span>
          <button @click="onEditRef" v-if="canBeEdited" class="btn-link">modifier</button>
        </div>
      </div>
      <div v-if="refToEdit">
        <div class="mt-3">
          <custom-input-type
            class="inline"
            label="Référence * :"
            type="text"
            v-model="reference"
            ref="reference"
            :rules="validations.reference"
          />
          <div class="flex-btn-group center">
            <button class="btn btn-login btn-secondary" @click="onSubmitRef">Valider</button>
            <button class="btn btn-login btn-secondary" @click="refToEdit = false">Annuler</button>
          </div>
        </div>
      </div>
    </template>

</template>

<script>
import { mapActions } from "vuex";
import { SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
export default {
  components: { CustomInputType, FieldDropzoneFile },
  mixins: [mixinAction],
  data() {
    return {
      urlToEdit: false,
      visuelToEdit: false,
      refToEdit: false,
      lien: null,
      reference: null,
      formValid: false,
      visuel: null,
      code_tracking: null,
      numero_adobe: null,
      commentaire: null,
    };
  },
  computed: {
    validations() {
      return {
        url: { required: "Merci de renseigner l'URL" },
        reference: { required: "Merci de renseigner la référence de la PLV" },
      };
    },
    visuelSupport: function() {
      return this.workflow?.visuel
        ? this.workflow.visuel instanceof File ? URL.createObjectURL(this.workflow.visuel) : `${process.env.VUE_APP_API_URL}/uploads/${this.workflow?.visuel}`
        : null;
    },
    lienIntegrationSupport() {
      return this.lien ? this.lien : this.workflow?.etapes.INTEGRATION?.lien;
    },
    idSinchIntegrationSupport() {
      return this.numero_adobe ? this.numero_adobe : this.workflow?.etapes?.CIBLAGE?.numero_adobe;
    },
    codeIntegrationSupport() {
      return this.code_tracking ? this.code_tracking : this.workflow?.code_tracking;
    },
    commentaireIntegrationSupport() {
      return this.commentaire ? this.commentaire : this.workflow?.etapes.INTEGRATION?.commentaire;
    },
    visuelSupportIntegrationSupport() {
      return this.workflow?.visuel;
    },
    referenceSupport() {
      return this.workflow?.reference;
    },
    title() {
      switch (this.workflow.type) {
        case SUPPORT_TYPES.EMAIL:
          return "Email";
        case SUPPORT_TYPES.RICH_SMS:
          return "Rich SMS";
        case SUPPORT_TYPES.PLV:
          return "Document de travail";
        default:
          break;
      }
    },
    canBeEdited() {
      return (
        this.$route.params.etape === "integration" &&
        (this.hasAction || this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email || (this.isPlv && this.supportEquipe?.COM?.user?.email === this.currentUser?.email))
      );
    },
    canVisuBeEdited() {
      return (
        this.$route.params.etape === "integration" &&
        (this.hasAction || this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email || (this.isPlv && this.supportEquipe?.COM?.user?.email === this.currentUser?.email))
      );
    },
  },
  methods: {
    ...mapActions(["updateSupportEtapeInfos", "updateSupportDetailsInfosBD", "updateSupportStatut", "addSupportEtapeBD", "addCiblageBD"]),
    copy() {
      navigator.clipboard.writeText(this.lienIntegrationSupport);
    },
    urlView() {
      if (this.lienIntegrationSupport?.startsWith("https")) {
        window.open(this.lienIntegrationSupport, "_blank");
      } else {
        alert(`"${this.lienIntegrationSupport}" n'est pas un lien valide`);
      }
    },
    onSubmit() {
      this.visuelToEdit = false;
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.updateSupportEtapeInfos({
        support_etape_id: this.workflow.etapes.INTEGRATION.support_etape_id,
        lien: this.lien,
        commentaire: this.commentaire,
      }).then(
          this.updateSupportDetailsInfosBD({
            support_id: this.workflow.etapes.INTEGRATION.support_id,
            code_tracking: this.code_tracking,
          }))
          .then(() => {
          if (this.isPlv) return; // #9221 Pas de ciblage sur les PLV
          this.addCiblageBD({
            ciblage_id: this.workflow?.etapes?.CIBLAGE?.ciblage_id,
            numero_adobe: this.numero_adobe,
            fromFicheData: false,
          });
        })
        .then(() => {
          this.urlToEdit = false;
          this.workflow.etapes.INTEGRATION.lien = this.lien;
          this.workflow.etapes.INTEGRATION.commentaire = this.commentaire;
        })
        .catch(e => {
          alert(e);
        });
    },
    onSubmitVisu() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        support_etape_id: this.workflow.etapes.INTEGRATION.support_etape_id,
        support_id: this.workflow.etapes.INTEGRATION.support_id,
        lien: this.lien,
        commentaire: this.commentaire,
        visuel: this.visuel,
        notify: false,
      })
        .then(() => {
          this.visuelToEdit = false;
          this.workflow.visuel = this.visuel;
        })
        .catch(e => {
          alert(e);
        });
    },
    onSubmitRef() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.updateSupportStatut({
        support_id: this.workflow.support_id,
        reference: this.reference,
      })
        .then(() => {
          this.refToEdit = false;
          this.workflow.reference = this.reference;
        })
        .catch(e => {
          alert(e);
        });
    },
    checkValidations() {
      return (this.formValid = checkValidationRefs(this.$refs));
    },
    onEditUrl() {
      this.urlToEdit = true;
      this.lien = this.lienIntegrationSupport;
      this.numero_adobe = this.idSinchIntegrationSupport;
      this.code_tracking = this.codeIntegrationSupport;
      this.commentaire = this.commentaireIntegrationSupport;
    },
    onEditVisuel() {
      this.visuelToEdit = true;
    },
    onEditRef() {
      this.refToEdit = true;
      this.reference = this.referenceSupport;
    },
  },
};
</script>

<style></style>
