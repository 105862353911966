<template>
  <div class="content" v-if="!loading">
    <div class="row workflow-header">
      <div class="col-12 col-lg-4 workflow-header-infos workflow-header-description">
        <div class="support-info-nom" v-if="!isPlv">{{ workflow.nom }}</div>
        <div class="support-info-nom" v-if="isPlv">
          {{ workflow.reference }} - {{ workflow.nom }}
        </div>
        <div class="support-info-contexte">
          {{ workflow.context }}
        </div>
        <div class="support-info-contexte" v-if="workflow.code_tracking && !isPlv">
          <small>Code Tracking : {{ workflow.code_tracking }}</small>
        </div>
        <div class="support-info-tags">
          <div class="support-info-tag" v-if="workflow.tag && !isPlv">{{ workflow.tag }}</div>
          <div class="support-info-tag" v-if="workflow.details_type && isPlv">
            {{ detailsTypeTag }}
          </div>
          <div class="support-info-tag" v-if="workflow.nature_envoi">
            {{ workflow.nature_envoi }}
          </div>
          <div v-for="livraison in lieuxLivraison" class="support-info-tag" :key="livraison">
            {{ livraisonOptions[livraison] }}
          </div>
          <div class="support-info-tag" v-if="workflow.lieu_livraison_autre">
            {{ workflow.lieu_livraison_autre }}
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 workflow-header-infos workflow-header-tache">
        <div class="support-infos-tache">
          <div class="support-info-date">
            {{
              $moment(workflow.date_envoi_souhaitee).format(
                isPlv ? "DD/MM/YYYY" : "DD/MM/YYYY à HH[h]mm"
              )
            }}
          </div>
          <div v-if="isPlv">
            {{ workflow.etapes.BAT_PRINT.num_bdc }}
          </div>
          <div class="support-info-type_tache">{{ supportTypeTache }}</div>
          <div class="support-info-volume" v-if="workflow.volume_final">
            {{ workflow.volume_final }} exemplaires
          </div>
        </div>
        <div class="support-info-numero_adobe">
          {{ numero_adobe }}
        </div>

        <form-bilan-analyse v-if="toAnalyze" />
        <div v-else-if="isAnalysed">
          <div>
            Compte tenu de mon analyse des taux d’ouverture, taux de réactivité, taux de clics, cet
            email est : {{ analyseStatut }}.
          </div>
          <div v-if="texteAdaptation">{{ texteAdaptation }}</div>
        </div>
      </div>
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-4 workflow-header-infos workflow-header-intervenants"
      >
        <div class="support-info-referent text-uppercase">
          {{ initiaux_referent }}
        </div>
        <div class="support-info-intervenants">
          Equipe :
          <span class="text-capitalize" v-for="(typeUser, index) in workflow.equipe" :key="index"
            >{{ (typeUser.user && typeUser.user.prenom) || "" }}
            {{ (typeUser.user && typeUser.user.nom) || "" }}</span
          >
          <span class="text-capitalize" v-if="workflow.imprimeur">{{ workflow.imprimeur }}</span>
          <span class="text-capitalize" v-if="workflow.graphiste">{{ workflow.graphiste }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content" v-if="!isDone">
    <steps-line v-if="workflow.etapes_dates" :support="workflow" :steps="steps" />
  </div>
  <div class="tabs-container">
    <div class="tabs-nav-wrapper">
      <nav class="tabs">
        <template v-for="(btn, key) in workflowEtapeButtons" :key="key">
          <router-link
            v-if="btn.is"
            class="tab"
            :class="classRouteLink(routeLinks[key])"
            :to="{ name: 'Workflow.WorkflowEtape', params: { etape: key } }"
            >{{ btn.label }}</router-link
          >
        </template>
        <router-link
          v-for="index in statsCount"
          :key="`stat-${index}`"
          class="tab"
          :to="{ name: 'Workflow.WorkflowEtape', params: { etape: 'stats', id_stat: index } }"
        >
          Stat {{ String.fromCharCode(64 + index) }}</router-link
        >
      </nav>
      <button
        :class="['btn btn-blank', workflowCommentaires.length > 0 ? ' btn-blank-active' : '']"
        :disabled="workflowCommentaires.length === 0"
        @click="showCommentaires = !showCommentaires"
      >
        <i class="fa-thin fa-comment" />
        <span>Voir les commentaires</span>
      </button>
      <transition name="slide-fade">
        <workflow-commentaires
          :commentairesModel="workflowCommentaires"
          v-if="showCommentaires"
          @close="showCommentaires = !showCommentaires"
        />
      </transition>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { mapActions } from "vuex";

import { find } from "lodash";

import moment from "moment";

import CustomModal from "../../../components/customize/CustomModal.vue";

import Loading from "../../../components/layouts/Loading.vue";

import StepsLine from "../../../components/StepsLine.vue";
import { ANALYSE_STATUT_TEXT, SUPPORT_STATUS } from "../../../../config/Properties";
import FieldRadioButtons from "../../../components/customize/FieldRadioButtons.vue";
import CustomTextArea from "../../../components/customize/CustomTextArea.vue";
import FormBilanAnalyse from "./bilan/FormBilanAnalyse.vue";
import WorkflowCommentaires from "./WorkflowCommentaires.vue";
import { mixinAction } from "../utils";
import store from "./../../../store/index";
import { SUPPORT_TYPES } from "../../../../../bigoudenn-api/app/configs/Properties";

export default {
  components: {
    CustomModal,
    Loading,
    StepsLine,
    FieldRadioButtons,
    CustomTextArea,
    FormBilanAnalyse,
    WorkflowCommentaires,
  },
  name: "IndexWorkflow",
  mixins: [mixinAction],
  computed: {
    optionsDetailsType() {
      return {
        AFFICHE: "Affiche",
        FLAYERS: "Flyers",
        AFFICHETTE: "Affichette",
        VITRO: "Vitro",
        DEPLIANT: "Dépliant",
        TOTEM: "Totem",
        COVERING_PC: "Covering PC",
        GUIRLANDES: "Guirlandes",
        CARTE_GRATTER: "Carte à gratter",
        POCHETTE: "Pochette",
        BULLTIN_JEU_AUTRES: "Bulletins de jeu",
        AUTRES: "Autres",
      };
    },
    livraisonOptions() {
      return {
        OPE_SOLEIL: "Opé soleil",
        STOCKAGE: "Docsourcing",
        AGENCE: "Nouvelle agence",
        ACD_ACM: "ACD/ACM",
        AVM: "AVM",
        AUTRE: "Autre",
      };
    },
    lieuxLivraison() {
      let liste = this.workflow.lieu_livraison?.split("|");
      return this.workflow.lieu_livraison_autre?.length > 0
        ? liste.filter(l => l !== "AUTRE")
        : liste;
    },
    detailsTypeTag() {
      return (
        this.workflow.details_type_autre || this.optionsDetailsType[this.workflow.details_type]
      );
    },
    isDone() {
      return this.workflow.statut === SUPPORT_STATUS.TERMINE;
    },
    toAnalyze() {
      if (this.workflow.statut === SUPPORT_STATUS.A_ANALYSER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.CM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    analyseStatut() {
      return ANALYSE_STATUT_TEXT[this.workflow?.analyse_stats_statut];
    },
    texteAdaptation() {
      return this.workflow?.analyse_stats_adaptation;
    },
    isAnalysed() {
      return this.isDone && this.analyseStatut;
    },
    statsCount() {
      return this.workflow?.stats_globales?.length;
    },
    supportTypeTache() {
      switch (this.workflow.type) {
        case SUPPORT_TYPES.EMAIL:
          return "Envoi de l'email";
        case SUPPORT_TYPES.RICH_SMS:
          return "Envoi du rich sms";
        case SUPPORT_TYPES.PLV:
          return "";
        default:
          return "Envoi du support";
      }
    },
    initiaux_referent() {
      return `${this.supportEquipe?.REFERENT?.user?.prenom[0]}${this.supportEquipe?.REFERENT?.user?.nom[0]}`;
    },
    workflowEtapeButtons() {
      return {
        brief: { is: true, label: "Brief" },
        ciblage: { is: !this.isPlv, label: "Ciblage" },
        integration: { is: true, label: this.isPlv ? "Création graphique" : "Intégration" },
        ml: { is: true, label: "Mentions légales" },
        html: { is: !this.isPlv, label: "Validation HTML" },
        bat: { is: true, label: this.isPlv ? "BAT" : "BAT/Envoi" },
        livraison: { is: this.isPlv, label: "Livraison" },
        stats: { is: !this.isPlv, label: "Stats" },
      };
    },
    steps() {
      let stps = [];
      Object.keys(this.workflow.etapes).map(key => {
        stps.push(this.stepsOptions[key.toLowerCase()]);
      });
      !this.isPlv && stps.push(this.stepsOptions["stats"]);
      return stps.sort((a, b) => (a.ordre > b.ordre ? 1 : -1));
    },
    stepsOptions() {
      return {
        brief: { label: "Brief", icon: "glbicon-brief", type: "BRIEF", ordre: 1 },
        ciblage: { label: "Ciblage", icon: "glbicon-ciblage", type: "CIBLAGE", ordre: 2 },
        integration: {
          label: this.isPlv ? "Création graphique" : "Intégration",
          icon: this.isPlv ? "glbicon-creation" : "glbicon-integration",
          type: "INTEGRATION",
          ordre: 3,
        },
        ml: {
          label: "Mentions légales",
          icon: "glbicon-mentions",
          type: "MENTIONS_LEGALES",
          ordre: 4,
        },
        html: {
          label: "Validation HTML",
          icon: "glbicon-validation",
          type: "VALIDATION_HTML",
          ordre: 5,
        },
        bat: { label: "BAT/Envoi", icon: "glbicon-bat", type: "BAT_ENVOIE", ordre: 6 },
        bat_num: { label: "BAT Num", icon: "glbicon-bat_num", type: "BAT_NUM", ordre: 7 },
        bat_print: { label: "BAT Print", icon: "glbicon-bat_print", type: "BAT_PRINT", ordre: 8 },
        livraison: {
          label: "Livraison",
          icon: "glbicon-livraison",
          type: "LIVRAISON",
          ordre: 9,
        },
        stats: { label: "Stats", icon: "glbicon-stat", type: "STATS", ordre: 10 },
      };
    },
    numero_adobe() {
      return this.workflow?.etapes?.CIBLAGE?.numero_adobe || "";
    },
  },
  methods: {
    ...mapActions(["setSupportWorkflow", "setUsersListe", "getReferentielImagesListe"]),
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    user(userId) {
      const user = find(this.usersListe, u => u.user_id === userId);
      return user;
    },
    classRouteLink(routeLink) {
      return this.hasRouteLink(routeLink) ? "tab" : "tab disabled";
    },
    hasRouteLink(routeLink) {
      switch (routeLink) {
        case this.routeLinks.brief:
        case this.routeLinks.ciblage:
          return true;
        case this.routeLinks.integration:
          return ![SUPPORT_STATUS.BRIEF_A_FAIRE].includes(this.workflow.statut);
        case this.routeLinks.ml:
          return (
            this.hasRouteLink(this.routeLinks.integration) &&
            ![
              SUPPORT_STATUS.A_INTEGRER,
              SUPPORT_STATUS.INTEGRATION_A_VALIDER,
            ].includes(this.workflow.statut)
          );
        case this.routeLinks.html:
          return (
            this.hasRouteLink(this.routeLinks.ml) &&
            ![
              SUPPORT_STATUS.ML_A_FAIRE,
              SUPPORT_STATUS.ML_A_INTEGRER,
              SUPPORT_STATUS.ML_A_VALIDER,
              SUPPORT_STATUS.INTEGRATION_ML_A_MODIFIER,
              SUPPORT_STATUS.INTEGRATION_A_MODIFIER,
            ].includes(this.workflow.statut)
          );
        case this.routeLinks.bat:
          return (
            this.hasRouteLink(this.routeLinks.html) &&
            ![SUPPORT_STATUS.HTML_A_VALIDER].includes(this.workflow.statut)
          );
        case this.routeLinks.livraison:
          return (
            this.hasRouteLink(this.routeLinks.bat) &&
            ![
              SUPPORT_STATUS.BAT_PRINT_A_VALIDER,
              SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER,
              SUPPORT_STATUS.BAT_A_VALIDER,
              SUPPORT_STATUS.BAT_A_FAIRE,
            ].includes(this.workflow.statut)
          );
        case this.routeLinks.stats:
          return (
            this.hasRouteLink(this.routeLinks.bat) &&
            ![
              SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER,
              SUPPORT_STATUS.BAT_A_VALIDER,
              SUPPORT_STATUS.BAT_A_FAIRE,
              SUPPORT_STATUS.A_ENVOYER,
            ].includes(this.workflow.statut)
          );
      }
    },
  },
  data() {
    return {
      showCommentaires: false,
      referentUser: {},

      routeLinks: {
        brief: "brief",
        ciblage: "ciblage",
        integration: "integration",
        ml: "ml",
        html: "html",
        bat: "bat",
        stats: "stats",
      },
    };
  },
  beforeRouteEnter() {
    return store.dispatch("setSupportWorkflow", {});
  },
  created() {
    let supportId = this.$route.params.id;

    return this.getReferentielImagesListe()
      .then(() => this.setUsersListe())
      .then(() => {
        if (supportId) return this.setSupportWorkflow({ id: supportId });
      })
      .catch(e => {
        console.log("catch error", e);
        alert(e.response?.data?.message || e);
        this.$router.push({ name: "Admin.Accueil" });
      });
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.slide-fade-enter-active {
  transition: all 1s;
  animation: slide-in 0.5s;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
@keyframes slide-in {
  from {
    right: 0;
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
