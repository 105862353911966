<template>
  <div class="currentComponent">
    <component :is="currentComponent" :key="this.etape" />
  </div>
</template>

<script>
import WorkflowBatEnvoie from "./bat-envoie/WorkflowBatEnvoie.vue";
import WorkflowBatPlv from "./bat-envoie/WorkflowBatPlv.vue";
import WorkflowBrief from "./brief/WorkflowBrief.vue";
import WorkflowCiblage from "./ciblage/WorkflowCiblage.vue";
import WorkflowIntegration from "./integration/WorkflowIntegration.vue";
import WorkflowML from "./ml/WorkflowML.vue";
import WorkflowStats from "./stats/WorkflowStats.vue";
import WorkflowValidationHtml from "./validation-html/WorkflowValidationHtml.vue";
import WorkflowLivraison from "./livraison/WorkflowLivraison.vue";
import { mixinAction } from "../utils";
export default {
  mixins: [mixinAction],
  components: {
    WorkflowBrief,
    WorkflowCiblage,
    WorkflowML,
    WorkflowValidationHtml,
    WorkflowStats,
    WorkflowIntegration,
    WorkflowBatEnvoie,
  },
  name: "WorkflowEtape",
  computed: {
    workflowComponents() {
      return {
        brief: WorkflowBrief,
        ciblage: WorkflowCiblage,
        integration: WorkflowIntegration,
        ml: WorkflowML,
        html: WorkflowValidationHtml,
        bat: this.isPlv ? WorkflowBatPlv : WorkflowBatEnvoie,
        stats: WorkflowStats,
        livraison: WorkflowLivraison,
      };
    },
    currentComponent() {
      return this.workflowComponents[this.etape];
    },
    etape() {
      return this.$route.params.etape;
    },
  },
};
</script>
