<template>
  <div class="steps" v-if="steps.length > 0">
    <div
      :class="['step', renderStatutClass(step.type)]"
      v-for="(step, index) in steps"
      :key="index"
    >
      <div class="icon-block">
        <i :class="step.icon"></i>
        <span
          >{{ step.label }}
          <span>{{ renderDate(step.type) }}</span>
        </span>
      </div>
      <span class="line"></span>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import {
  STEP_CIBLAGE_CLASS,
  STEP_CREATION_CLASS,
  DATE_TYPE_FROM_STEP_TYPE,
} from "../../config/Properties";

export default {
  name: "StepsLine",
  props: ["steps", "support"],
  methods: {
    renderStatutClass(type) {
      if (type === "CIBLAGE") {
        if (STEP_CIBLAGE_CLASS[this.support?.ciblage_statut]) {
          return STEP_CIBLAGE_CLASS[this.support?.ciblage_statut];
        } else {
          return "inactive";
        }
      } else {
        if (
          STEP_CREATION_CLASS[this.support?.statut] &&
          STEP_CREATION_CLASS[this.support?.statut][type]
        ) {
          return STEP_CREATION_CLASS[this.support?.statut][type];
        } else {
          return "inactive";
        }
      }
    },
    renderDate(type) {
      if (type !== "STATS") {
        const date = this.support?.etapes_dates[DATE_TYPE_FROM_STEP_TYPE[type]];
        return moment(date).format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
  },
};
</script>
