<template>
  <loading v-if="loading" />

  <div class="table-toolbar flex-btn-group right">
    <router-link class="btn btn-primary" :to="{ name: 'Admin.Referentiel.ML.Create' }">
      Ajouter une ML
    </router-link>
  </div>

  <table class="table table-referentiel">
    <thead>
      <tr>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.created_at = !sorting.created_at;
                sortListe('created_at', sorting.created_at);
              "
            >
              <span>Date de création</span>
              <i :class="`far  ${sorting.created_at ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <field-date-interval v-model="searching.created_at" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.nom = !sorting.nom;
                sortListe('nom', sorting.nom);
              "
            >
              <span>Nom</span>
              <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.nom" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.updated_at = !sorting.updated_at;
                sortListe('updated_at', sorting.updated_at);
              "
            >
              <span>Date de MAj</span>
              <i :class="`far  ${sorting.updated_at ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <field-date-interval v-model="searching.updated_at" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.date_validite = !sorting.date_validite;
                sortListe('date_validite', sorting.date_validite);
              "
            >
              <span>Date de validité</span>
              <i :class="`far  ${sorting.date_validite ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <field-date-interval v-model="searching.date_validite" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.created_by = !sorting.created_by;
                sortListe('created_by', sorting.created_by);
              "
            >
              <span>Crée par</span>
              <i :class="`far  ${sorting.created_by ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.created_by" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.type = !sorting.type;
                sortListe('type', sorting.type);
              "
            >
              <span>Type</span>
              <i :class="`far  ${sorting.type ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <custom-basic-select
            :options="typeOptions"
            v-model="searching.type"
            @change="filterListe()"
          />
        </th>
        <th>
          <button class="btn" @click="resetFilter">
            <i class="far fa-sync" /><span>Réinitialiser</span>
          </button>
        </th>
      </tr>
    </thead>
    <tbody v-if="pageListe && pageListe.length > 0">
      <item-ml
        v-for="(ml, index) in pageListe"
        :key="index"
        :ml="ml"
        @deleteML="onDelete"
        @dupliquerML="onDupliquer"
      />
    </tbody>
    <div v-else>Aucun résultat</div>
  </table>
  <div class="pagination-wrapper">
    <div v-if="nbPages > 1">
      <div class="pagination">
        <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
          <i class="far fa-chevron-left" />
        </button>
        <button
          :class="[i === currentPage + 1 && 'active', 'page-number']"
          v-for="i in nbPages"
          :key="i"
          @click="nextPage(i)"
        >
          <span>{{ i }}</span>
        </button>
        <button
          class="page-number"
          v-if="currentPage < nbPages - 1"
          @click="nextPage(currentPage + 2)"
        >
          <i class="far fa-chevron-right" />
        </button>
      </div>
    </div>
    <custom-basic-select :options="nbpageOptions" v-model="nbPerPage" />
  </div>
  <custom-modal
    v-if="showModalDelete"
    @close="showModalDelete = false"
    @confirm="deleteReferentiel(idReferentielML)"
  >
    <template v-slot:modalTitle> Confirmation </template>
    <template v-slot:modalBody> Merci de confirmer la suppression</template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomModal from "../../../../components/customize/CustomModal.vue";
import { map } from "lodash";
import Loading from "../../../../components/layouts/Loading.vue";
import ItemMl from "./ItemML.vue";
import FieldDateInterval from "../../../../components/customize/FieldDateInterval.vue";
import CustomBasicSelect from "../../../../components/customize/CustomBasicSelect.vue";
import { REF_ML_TYPE_TEXT } from "../../../../../config/Properties";

export default {
  components: { CustomModal, Loading, ItemMl, FieldDateInterval, CustomBasicSelect },
  name: "ListeReferentielML",
  data() {
    return {
      nbPages: 0,
      nbPerPage: 15,
      currentPage: 0,
      showModalDelete: false,
      idReferentielML: 0,
      currentListe: [],
      sorting: {
        nom: false,
        created_at: false,
        updated_at: false,
        created_by: false,
        date_validite: false,
        type: false,
      },
      searching: {
        nom: null,
        created_at: { startDate: null, endDate: null },
        updated_at: { startDate: null, endDate: null },
        created_by: null,
        date_validite: { startDate: null, endDate: null },
        type: null,
      },
      nbpageOptions: { 15: "15/page", 30: "30/page", 50: "50/page" },
    };
  },
  computed: {
    ...mapGetters(["referentielMLsListe", "loading"]),
    pageListe() {
      if (this.currentListe?.length > 0) {
        let n = this.currentPage * this.nbPerPage;
        let m = n + this.nbPerPage;
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
        return this.currentListe.slice(n, m);
      }
      return this.currentListe;
    },
    typeOptions() {
      return REF_ML_TYPE_TEXT;
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setReferentielMLsListe",
      "deleteReferentielML",
      "dupliquerReferentielML",
    ]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = [...this.referentielMLsListe];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(ml => {
            switch (key) {
              case "created_at":
              case "updated_at":
              case "date_validite":
                let dateML = this.$moment(ml[key], "YYYY-MM-DD");
                return search.startDate && search.endDate
                  ? dateML.isBetween(search.startDate, search.endDate, null, "[]")
                  : search.startDate
                  ? dateML.isSameOrAfter(search.startDate)
                  : search.endDate
                  ? dateML.isSameOrBefore(search.endDate)
                  : true;
              default:
                return regex.test(ml[key]);
            }
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = [...this.referentielMLsListe];
      map(this.searching, (value, key) => {
        if (["created_at", "updated_at", "date_validite"].includes(key)) {
          this.searching[key].startDate = null;
          this.searching[key].endDate = null;
        } else {
          this.searching[key] = null;
        }
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
        return sortKey ? -1 : 1;
      }
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
        return sortKey ? 1 : -1;
      }
      return a[key] === null ? 1 : b[key] === null ? -1 : 0;
    },
    onDelete(id) {
      this.showModalDelete = true;
      this.idReferentielML = id;
    },
    deleteReferentiel(id) {
      this.showModalDelete = false;
      return this.setLoading(true)
        .then(() => this.deleteReferentielML(id))
        .then(isDeleted => {
          if (!isDeleted) {
            alert("impossible de supprimer la ML");
          }
        })
        .then(() => this.setLoading(false));
    },
    onDupliquer(id) {
      return this.setLoading(true)
        .then(() => this.dupliquerReferentielML({ id }))
        .catch(e => {
          alert(e.response?.data?.message || e);
        })
        .then(() => this.setLoading(false));
    },
  },
  watch: {
    referentielMLsListe: {
      handler(val) {
        this.currentListe = [...val];
      },
      deep: true,
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.setReferentielMLsListe())
      .then(() => {
        this.currentListe = this.referentielMLsListe;
        return this.setLoading(false);
      });
  },
};
</script>
