<template>
  <tr>
    <td class="image">
      <img :src="apiUrlUploads + image.image" />
    </td>
    <td data-label="Nom">
      <span>{{ image.nom }}</span>
    </td>
    <td data-label="Description">
      <span>{{ image.description }}</span>
    </td>
    <td data-label="Type">
      <span>{{ imageType }}</span>
    </td>
    <td data-label="Source">
      <span>{{ imageSource }}</span>
    </td>

    <td data-label="Marché">
      <span>{{ imageMarche }}</span>
    </td>
    <td class="buttons">
      <button class="btn tooltip-btn" @click="$emit('editImage', image.referentiel_id)">
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Éditer</span>
      </button>
      <button class="btn tooltip-btn" @click="$emit('deleteImage', image.referentiel_id)">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
  </tr>
</template>

<script>
import { capitalize } from "lodash";
export default {
  name: "ReferentielImageDetails",
  emits: ["deleteImage", "editImage"],
  props: {
    image: {
      type: Object,
      default: {},
    },
  },
  computed: {
    imageType() {
      return capitalize(this.image.type);
    },
    imageSource() {
      return capitalize(this.image.source);
    },
    imageMarche() {
      return this.image.marche;
      // return capitalize(this.image.marche);
    },
    apiUrlUploads() {
      return `${process.env.VUE_APP_API_URL}/uploads/referentiel/`;
    },
  },
  methods: {},
};
</script>

<style></style>
