<template>
  <loading v-if="loading" />

  <div class="table-toolbar flex-btn-group right">
    <router-link class="btn btn-primary" :to="{ name: 'Admin.Referentiel.Images.Create' }">
      Ajouter une image
    </router-link>
  </div>

  <table class="table table-referentiel">
    <thead>
      <tr>
        <th class="image"></th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.nom = !sorting.nom;
                sortListe('nom', sorting.nom);
              "
            >
              <span>Nom</span>
              <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.nom" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.description = !sorting.description;
                sortListe('description', sorting.description);
              "
            >
              <span>Desc</span>
              <i :class="`far  ${sorting.description ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.description" @input="filterListe()" />
        </th>
        <th class="image-type">
          <div class="th-title">
            <a
              @click="
                sorting.type = !sorting.type;
                sortListe('type', sorting.type);
              "
            >
              <span>Type</span>
              <i :class="`far  ${sorting.type ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.type" @input="filterListe()" />
        </th>
        <th>
          <div class="th-title">
            <a
              @click="
                sorting.source = !sorting.source;
                sortListe('source', sorting.source);
              "
            >
              <span>Source</span>
              <i :class="`far  ${sorting.source ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.source" @input="filterListe()" />
        </th>
        <th class="image-marche">
          <div class="th-title">
            <a
              @click="
                sorting.marche = !sorting.marche;
                sortListe('marche', sorting.marche);
              "
            >
              <span>Marché</span>
              <i :class="`far  ${sorting.marche ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
            </a>
          </div>
          <input type="text" v-model="searching.marche" @input="filterListe()" />
        </th>
        <th class="two-btn"></th>
        <!-- <th>
            <button class="btn" @click="resetFilter">
              <i class="far fa-sync" /><span>Réinitialiser</span>
            </button>
          </th> -->
      </tr>
    </thead>
    <tbody v-if="pageListe && pageListe.length > 0">
      <template v-for="(image, index) in pageListe" :key="index">
        <referentiel-image-details :image="image" @deleteImage="onDelete" @editImage="onEdit" />
      </template>
    </tbody>
    <div v-else>Aucun résultat</div>
  </table>

  <div v-if="nbPages > 1">
    <div class="pagination">
      <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
        <i class="far fa-chevron-left" />
      </button>
      <button
        :class="[i === currentPage + 1 && 'active', 'page-number']"
        v-for="i in nbPages"
        :key="i"
        @click="nextPage(i)"
      >
        <span>{{ i }}</span>
      </button>
      <button
        class="page-number"
        v-if="currentPage < nbPages - 1"
        @click="nextPage(currentPage + 2)"
      >
        <i class="far fa-chevron-right" />
      </button>
    </div>
  </div>

  <custom-modal
    v-if="showModalDelete"
    @close="showModalDelete = false"
    @confirm="deleteReferentiel(idReferentielImages)"
  >
    <template v-slot:modalTitle> Confirmation </template>
    <template v-slot:modalBody>
      Merci de confirmer la suppression de l'image {{ getImage() }}</template
    >
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CustomModal from "../../../../components/customize/CustomModal.vue";
import { map, find } from "lodash";
import Loading from "../../../../components/layouts/Loading.vue";
import ReferentielImageDetails from "./ReferentielImageDetails.vue";

export default {
  components: { CustomModal, Loading, ReferentielImageDetails },
  name: "ListeReferentielImage",
  data() {
    return {
      nbPages: 0,
      nbPerPage: 30,
      currentPage: 0,
      showModalDelete: false,
      idReferentielImages: 0,
      currentListe: [],
      sorting: {
        nom: false,
        description: false,
        type: false,
        source: false,
        marche: false,
      },
      searching: {
        nom: null,
        description: null,
        type: null,
        source: null,
        marche: null,
      },
    };
  },
  computed: {
    ...mapGetters(["referentielImagesListe", "loading"]),
    pageListe() {
      if (this.currentPage && this.currentPage.length > 0) {
        let n = this.currentPage * this.nbPerPage;
        let m = n + this.nbPerPage;
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
        return this.currentListe.slice(n, m);
      }
      return this.currentListe;
    },
  },
  methods: {
    ...mapActions(["setLoading", "getReferentielImagesListe", "deleteReferentielImages"]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    getImage() {
      return (
        find(this.currentListe, ref => ref.referentiel_id === this.idReferentielImages) &&
        find(this.currentListe, ref => ref.referentiel_id === this.idReferentielImages).nom
      );
    },
    filterListe() {
      this.currentPage = 0;
      this.currentListe = [...this.referentielImagesListe];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(referentielImage => {
            return regex.test(referentielImage[key]);
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = [...this.referentielImagesListe];
      map(this.searching, (value, key) => {
        this.searching[key] = null;
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
        return sortKey ? -1 : 1;
      }
      if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
        return sortKey ? 1 : -1;
      }
      return a[key] === null ? 1 : b[key] === null ? -1 : 0;
    },
    onDelete(id) {
      this.showModalDelete = true;
      this.idReferentielImages = id;
    },
    onEdit(id) {
      // pour l'edit ou on ouvre la page du form avec l'image en donné
      this.idReferentielImages = id;
      this.$router.push(`/admin/referentiel/images/edit/${id}`);
    },

    deleteReferentiel(id) {
      this.showModalDelete = false;
      return this.setLoading(true)
        .then(() => this.deleteReferentielImages(id))
        .then(isDeleted => {
          if (!isDeleted) {
            alert("impossible de supprimer l'image");
          }
        })
        .then(() => this.setLoading(false));
    },
  },
  watch: {
    referentielImagesListe: {
      handler(val) {
        this.currentListe = [...val];
      },
      deep: true,
    },
    $route: {
      handler(val, old) {
        this.isActifs = val.query.type;
        this.currentListe = this.referentielImagesListe;
      },
    },
  },
  mounted() {
    this.isActifs = this.$route.query.type;
    return this.setLoading(true)
      .then(() => this.getReferentielImagesListe())
      .then(() => {
        this.currentListe = this.referentielImagesListe;
        return this.setLoading(false);
      });
  },
};
</script>
