<template>
  <div class="content tab-content">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7 minH-100">
        <div v-if="isDone">
          <div class="form-col-title">Document final</div>
          <div class="flex-btn-group center">
            <a class="btn btn-secondary" :href="bat.lien" target="_blank">
              Voir le document final
            </a>
          </div>

          <div>
            <div class="form-col-title mt-4">Validation Numérique</div>
            <div class="text-center">
              BAT numérique validé par {{ this.workflow.etapes.BAT_NUM.user.prenom }}
              {{ this.workflow.etapes.BAT_NUM.user.nom }} le
              {{ $moment(this.workflow.etapes.BAT_NUM.date_validation).format("DD/MM/YYYY") }}
            </div>
          </div>
          <div>
            <div class="form-col-title mt-4">Validation Print</div>
            <div class="text-center">
              BAT print validé par {{ this.workflow.etapes.BAT_PRINT.user.prenom }}
              {{ this.workflow.etapes.BAT_PRINT.user.nom }} le
              {{ $moment(this.workflow.etapes.BAT_PRINT.date_validation).format("DD/MM/YYYY") }}
            </div>
          </div>
        </div>
        <div class="h-100" v-else>
          <div v-if="toValidate || toModify">
            <div class="form-col-title">Document final</div>
            <div class="flex-btn-group center">
              <div v-if="!lienToEdit">
                <a class="btn btn-secondary" :href="bat.lien" target="_blank">
                  Voir le document final
                </a>
                <button @click="onLienEdit" class="btn-link">Modifier</button>
              </div>
              <div v-if="lienToEdit">
                <div>
                  <custom-input-type
                    class="inline"
                    label="URL * :"
                    type="text"
                    v-model="url"
                    ref="url"
                    :rules="validations.url"
                  />
                  <button class="btn btn-login btn-secondary" @click="lienToEdit = false">
                    Annuler
                  </button>
                  <button class="btn btn-login btn-secondary" @click="onSubmitUrlEdit">
                    Valider
                  </button>
                </div>
              </div>
            </div>

            <div v-if="!batNum">
              <div class="form-col-title mt-4">Validation Numérique</div>
              <div class="text-center">
                BAT numérique validé par {{ this.workflow.etapes.BAT_NUM.user.prenom }}
                {{ this.workflow.etapes.BAT_NUM.user.nom }} le
                {{ $moment(this.workflow.etapes.BAT_NUM.date_validation).format("DD/MM/YYYY") }}
              </div>
            </div>
          </div>

          <!-- Form BAT -->
          <div v-if="toDo">
            <div class="form-col-title">Document final</div>
            <div class="field-indications">
              Le document final doit être mis sur le portail sharepoint
            </div>
            <div>
              <custom-input-type
                class="inline"
                type="text"
                label="URL *:"
                v-model="this.bat.lien"
                ref="bat.lien"
                :rules="validations.lien"
              />
            </div>
            <div class="flex-btn-group center">
              <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
              <button class="btn btn-secondary" @click="submit">Valider</button>
            </div>
          </div>

          <!-- Form Modification -->
          <div v-else-if="toModify">
            <div class="form-col-title mt-4">Retour</div>
            <retour-validation v-if="bat.commentaires" :commentairesModel="bat.commentaires" />
            <div class="flex-btn-group center">
              <button class="btn btn-secondary" @click="onRetourValidation">
                Retour pris en compte
              </button>
            </div>
          </div>
          <!-- Form Validation -->
          <div v-else-if="toValidate">
            <div v-if="batNumToValidate">
              <div class="form-col-title mt-4">Validation numérique</div>
              <field-radio-buttons
                label="Je valide le BAT :"
                class="inline center"
                :buttons="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]"
                v-model="bat.statut_validation"
                ref="bat.statut_validation"
                :rules="validations.statut_validation"
              />
              <div v-if="bat.statut_validation === false">
                <custom-editor-text
                  label="Commentaire * :"
                  v-model="bat.commentaire_validation"
                  ref="bat.commentaire_validation"
                  :rules="validations.commentaire_validation"
                />
              </div>
              <div class="flex-btn-group center">
                <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
                <button class="btn btn-secondary" @click="submit">Valider</button>
              </div>
            </div>
            <div v-if="batPrintToValidate">
              <div class="form-col-title mt-4">Validation Print</div>
              <custom-input-type
                type="text"
                label="N° de BDC :"
                class="inline"
                v-model="bat.num_bdc"
                ref="bat.num_bdc"
                :rules="validations.num_bdc"
              />
              <div class="flex-btn-group center">
                <button class="btn btn-secondary" @click="onValidationBatPrint">
                  BAT PRINT VALIDÉ
                </button>
              </div>
            </div>
          </div>
          <div class="encours" v-else>BAT en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { SUPPORT_STATUS } from "../../../../../config/Properties";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import RetourValidation from "../shared/RetourValidation.vue";
import Loading from "../../../../components/layouts/Loading.vue";
import { mixinAction } from "../../utils";

export default {
  components: {
    FieldDropzoneFile,
    CustomInputType,
    FieldRadioButtons,
    CustomEditorText,
    SupportUrlVisuel,
    RetourValidation,
    Loading,
  },
  name: "WorkflowBatPlv",
  data() {
    return {
      bat: {},
      lienToEdit: false,
      url: null,
    };
  },
  mixins: [mixinAction],
  computed: {
    validations() {
      return {
        url: { required: "Merci de renseigner l'url" },
        statut_validation: { required: "Merci de répondre à la validation" },
        commentaire_validation: {
          requiredIf: {
            model: !this.bat.statut_validation,
            message: "Merci de saisir un commentaire",
          },
        },
        num_bdc: { required: "Merci de renseigner le numéro BDC" },
      };
    },
    toDo() {
      if (this.workflow.statut === SUPPORT_STATUS.BAT_A_FAIRE) {
        if (this.hasAction) return true;
        return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
      }
      return false;
    },
    toValidate() {
      return [SUPPORT_STATUS.BAT_A_VALIDER, SUPPORT_STATUS.BAT_PRINT_A_VALIDER].includes(
        this.workflow.statut
      );
    },
    batNumToValidate() {
      if (!this.toValidate) return false;
      if (this.workflow.statut === SUPPORT_STATUS.BAT_A_VALIDER) {
        if (this.hasAction) return true;
        return this.batNum && this.supportEquipe?.CM?.user?.email === this.currentUser?.email;
      }
      return false;
    },
    batPrintToValidate() {
      if (!this.toValidate) return false;
      if (this.workflow.statut === SUPPORT_STATUS.BAT_PRINT_A_VALIDER) {
        if (this.hasAction) return true;
        return !this.batNum && this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
      }
      return false;
    },
    toModify() {
      if (this.workflow.statut === SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER) {
        if (this.hasAction) return true;
        return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
      }
      return false;
    },
    isDone() {
      return this.workflow.etapes.BAT_NUM.is_valide && this.workflow.etapes.BAT_PRINT.is_valide;
    },
    batByStatut() {
      switch (this.workflow.statut) {
        case SUPPORT_STATUS.BAT_A_FAIRE:
        case SUPPORT_STATUS.BAT_A_VALIDER:
        case SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER:
          return "BAT_NUM";
        default:
          return "BAT_PRINT";
      }
    },
    batNum() {
      return this.batByStatut === "BAT_NUM";
    },
  },
  methods: {
    ...mapActions([
      "addSupportEtapeBD",
      "updateSupportStatut",
      "updateSupportDetailsInfosBD",
      "updateSupportEtapeInfos",
    ]),
    submit() {
      this.checkValidations(this.$refs);
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.bat,
        statut: this.statutToApi(),
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    onSubmitUrlEdit() {
      this.checkValidations([this.$refs.url]);
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.updateSupportEtapeInfos({
        support_etape_id: this.bat.support_etape_id,
        lien: this.url,
      })
        .then(() => {
          this.lienToEdit = false;
          this.bat.lien = this.url;
        })
        .catch(e => {
          alert(e);
        });
    },

    checkValidations(refs) {
      this.formValid = checkValidationRefs(refs);
    },
    statutToApi() {
      return this.toDo || this.toModify
        ? SUPPORT_STATUS.BAT_A_VALIDER
        : this.toValidate && this.bat.statut_validation
        ? this.batNum
          ? SUPPORT_STATUS.BAT_PRINT_A_VALIDER
          : SUPPORT_STATUS.LIVRAISON_A_FAIRE
        : SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER;
    },
    isValidated() {
      return this.bat?.statut_validation;
    },
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    onRetourValidation() {
      return this.updateSupportStatut({
        support_id: this.bat.support_id,
        statut: this.statutToApi(),
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    onValidationBatPrint() {
      this.checkValidations(this.$refs);
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.bat,
        statut: SUPPORT_STATUS.A_LIVRER,
        is_valide: true,
        date_validation: this.$moment(),
        user_id_validation: this.currentUser.user_id,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    onLienEdit() {
      this.lienToEdit = true;
      this.url = this.bat.lien;
    },
  },
  mounted() {
    this.bat = this.workflow?.etapes[this.batByStatut] || {};
  },
  watch: {
    // workflow: {
    //   handler(val) {
    //     this.bat = { ...val?.etapes[this.batByStatut] };
    //   },
    //   deep: true,
    // },
    "bat.statut_validation": {
      handler(val) {
        if (val) this.bat.commentaire_validation = null;
      },
    },
  },
};
</script>
