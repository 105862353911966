<template>
  <tr>
    <td data-label="Date de création">
      <span>{{ getDateFormat(ml.created_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Nom">
      <span>{{ ml.nom }}</span>
    </td>
    <td data-label="Date de MAj">
      <span>{{ getDateFormat(ml.updated_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Date de validité">
      <span>{{ getDateFormat(ml.date_validite, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Crée par">
      <span>{{ ml.created_by }}</span>
    </td>
    <td data-label="Type">
      <ul>
        <li v-for="(t, index) in ml.type.split('|')" :key="index">{{ getTypeText(t) }}</li>
      </ul>
    </td>
    <td class="buttons">
      <button class="btn tooltip-btn" @click="$emit('dupliquerML', ml.referentiel_id)">
        <i class="fal fa-copy"></i>
        <span class="tooltip-text">Dupliquer</span>
      </button>
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.ML.Details', params: { id: ml.referentiel_id } }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.ML.Edit', params: { id: ml.referentiel_id } }"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Modifier</span>
      </router-link>
      <button class="btn tooltip-btn" @click="$emit('deleteML', ml.referentiel_id)">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
  </tr>
</template>

<script>
import { REF_ML_TYPE_TEXT } from "../../../../../config/Properties";
export default {
  name: "ItemML",
  emits: ["deleteML", "dupliquerML"],
  props: {
    ml: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    getDateFormat(d, format) {
      return d ? this.$moment(d).format(format) : "-";
    },
    getTypeText(t) {
      return REF_ML_TYPE_TEXT[t];
    },
  },
};
</script>
