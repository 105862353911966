import IndexWorkflow from "./IndexWorkflow";
import WorkflowEtape from "./WorkflowEtape";

export const routes = [
  {
    path: "workflow",
    name: "Workflow",
    component: IndexWorkflow,
    children: [
      {
        path: ":etape/:id/:id_stat?",
        name: "Workflow.WorkflowEtape",
        component: WorkflowEtape,
      },
    ],
  },
];
