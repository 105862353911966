<template>
  <div class="content tab-content">
    <div v-if="isFicheCiblage || toEdit">
      <form-fiche-ciblage :ciblageModel="supportCiblage" :toEdit="toEdit" :created="created" />
    </div>
    <div v-else-if="isDataCiblage">
      <form-data-ciblage />
    </div>
    <div v-else-if="ciblageDone">
      <form-data-ciblage />
      <!-- <details-ciblage /> -->
    </div>
    <div class="encours" v-else>Ciblage en cours</div>
    <div class="form-footer flex-btn-group center" v-if="canBeModify">
      <button class="btn btn-secondary" @click="onEdit">Modifier</button>
    </div>
  </div>
</template>

<script>
import { CIBLAGE_STATUTS } from "../../../../../config/Properties";
import { mixinAction } from "../../utils";

import DetailsCiblage from "./DetailsCiblage.vue";
import FormDataCiblage from "./FormDataCiblage.vue";
import FormFicheCiblage from "./FormFicheCiblage.vue";
export default {
  components: { FormFicheCiblage, DetailsCiblage, FormDataCiblage },
  name: "WorkflowCiblage",
  mixins: [mixinAction],
  data() {
    return {
      created: true,
      toEdit: false,
    };
  },
  methods: {
    onEdit() {
      this.toEdit = true;
      this.created = false;
    }
  },
  computed: {
    isFicheCiblage() {
      return (
        this.supportCiblage?.statut === CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE &&
        (this.supportEquipe?.CM?.user?.email === this.currentUser?.email || this.hasAction)
      );
    },
    isDataCiblage() {
      console.log("Support ciblage", this.supportCiblage);
      console.log("From condition", this.supportCiblage?.statut);
      return (
        this.supportCiblage?.statut === CIBLAGE_STATUTS.CIBLAGE_A_FAIRE &&
        (this.supportEquipe?.DATA?.user?.email === this.currentUser?.email ||
          this.hasAction ||
          this.supportEquipe?.CM?.user?.email === this.currentUser?.email ||
          this.supportEquipe?.REFERENT?.user?.email === this.currentUser?.email ||
          this.supportEquipe?.COM?.user?.email === this.currentUser?.email ||
          this.supportEquipe?.ML?.user?.email === this.currentUser?.email ||
          this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email)
      );
    },
    ciblageDone() {
      return this.supportCiblage?.statut === CIBLAGE_STATUTS.CIBLAGE_FAIT;
    },
    canBeModify() {
      return (
        !this.toEdit &&
        this.supportCiblage?.statut !== CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE &&
        (this.supportEquipe?.CM?.user_id === this.currentUser?.user_id || this.hasAction)
      );
    },
  },
};
</script>
