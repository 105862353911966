<template>
  <div class="content tab-content">
    <div class="row justify-content-between minH-100">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7 minH-100">
        <div v-if="isDone">
          <div>
            <div class="form-col-title">BAT</div>
            <div class="text-center">
              BAT validé par {{ this.bat_envoi.user.prenom }} {{ this.bat_envoi.user.nom }} le
              {{ $moment(this.bat_envoi.date_validation).format("DD/MM/YYYY") }}
            </div>
          </div>
          <div v-if="toSend">
            <div class="form-col-title mt-5">Envoi</div>
            <custom-input-type
              class="inline"
              label="Lien miroir :"
              type="text"
              v-model="lien_miroir"
            />
            <div class="flex-btn-group center">
              <button class="btn btn-secondary" @click="onUpdateEmail">Email envoyé</button>
            </div>
          </div>
          <div v-else-if="isSended" class="text-center mt-4">Envoi effectué</div>
          <div v-else class="text-center mt-4">Envoi à faire</div>
        </div>
        <div class="h-100" v-else>
          <div v-if="isWaiting">
            <div class="form-col-title">Statut</div>
            <div class="text-center">En attente du ciblage</div>
          </div>

          <!-- Form BAT -->
          <div v-else-if="toDo">
            <div class="form-col-title">BAT</div>
            <div class="flex-btn-group center">
              <button class="btn btn-secondary" @click="onRetourValidation">BAT ENVOYÉ</button>
            </div>
          </div>

          <!-- Form Modification -->
          <div v-else-if="toModify">
            <div class="form-col-title">Retour</div>
            <retour-validation :commentairesModel="workflow.etapes.BAT.commentaires" />
            <div class="field">
              <custom-editor-text
                label="Commentaire :"
                v-model="bat_envoi.commentaire_validation"
                ref="bat_envoi.commentaire_validation"
              />
            </div>
            <div class="flex-btn-group center">
              <button class="btn btn-secondary" @click="submit">
                Retour BAT pris en compte
              </button>
            </div>
          </div>
          <!-- Form Validation -->
          <div v-else-if="toValidate">
            <div class="form-col-title">Validation</div>
            <field-radio-buttons
              label="Je valide :"
              class="inline center"
              :buttons="[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]"
              v-model="bat_envoi.statut_validation"
              ref="bat_envoi.statut_validation"
              :rules="validations.statut_validation"
            />
            <div v-if="bat_envoi.statut_validation === false">
              <custom-editor-text
                label="Commentaire * :"
                v-model="bat_envoi.commentaire_validation"
                ref="bat_envoi.commentaire_validation"
                :rules="validations.commentaire_validation"
              />
            </div>
            <div class="flex-btn-group center">
              <router-link class="btn btn-secondary" :to="this.backRoute">Annuler</router-link>
              <button class="btn btn-secondary" @click="submit">Valider</button>
            </div>
          </div>
          <div class="encours" v-else>BAT en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { CIBLAGE_STATUTS, SUPPORT_STATUS } from "../../../../../config/Properties";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import RetourValidation from "../shared/RetourValidation.vue";
import Loading from "../../../../components/layouts/Loading.vue";
import { mixinAction } from "../../utils";

export default {
  components: {
    FieldDropzoneFile,
    CustomInputType,
    FieldRadioButtons,
    CustomEditorText,
    SupportUrlVisuel,
    RetourValidation,
    Loading,
  },
  name: "WorkflowBATEnvoi",
  data() {
    return {
      bat_envoi: {},
      lien_miroir: null,
    };
  },
  mixins: [mixinAction],
  computed: {
    ...mapGetters(["getUserFromListe"]),
    validations() {
      return {
        lien: { required: "Merci de renseigner le lien" },
        statut_validation: { required: "Merci de répondre à la validation" },
        commentaire_validation: {
          requiredIf: {
            model: !this.bat_envoi.statut_validation,
            message: "Merci de saisir un commentaire",
          },
        },
      };
    },
    isDATA() {
      return this.supportEquipe?.DATA?.user?.email === this.currentUser?.email;
    },
    toDo() {
      return (
        !this.isWaiting &&
        this.workflow.statut === SUPPORT_STATUS.BAT_A_FAIRE &&
        (this.isDATA || this.hasAction)
      );
    },
    toValidate() {
      return (
        !this.isWaiting &&
        this.workflow.statut === SUPPORT_STATUS.BAT_A_VALIDER &&
        (this.supportEquipe?.CM?.user?.email === this.currentUser?.email || this.hasAction)
      );
    },
    toModify() {
      return (
        this.workflow.statut === SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER &&
        (this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email || this.hasAction)
      );
    },
    isDone() {
      return this.bat_envoi?.is_valide;
    },
    toSend() {
      return this.workflow.statut === SUPPORT_STATUS.A_ENVOYER && (this.isDATA || this.hasAction);
    },
    isSended() {
      return [SUPPORT_STATUS.STATS_A_FAIRE, SUPPORT_STATUS.TERMINE].includes(this.workflow.statut);
    },
    isWaiting() {
      return this.supportCiblage?.statut !== CIBLAGE_STATUTS.CIBLAGE_FAIT;
    },
  },
  methods: {
    ...mapActions(["addSupportEtapeBD", "updateSupportStatut", "updateSupportDetailsInfosBD"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addSupportEtapeBD({
        ...this.bat_envoi,
        statut: this.statutToApi(),
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    statutToApi() {
      return this.toDo
        ? SUPPORT_STATUS.BAT_A_VALIDER
        : this.toModify
        ? SUPPORT_STATUS.BAT_A_FAIRE
        : this.toValidate && this.bat_envoi.statut_validation
        ? SUPPORT_STATUS.A_ENVOYER
        : SUPPORT_STATUS.INTEGRATION_BAT_A_MODIFIER;
    },
    isValidated() {
      return this.bat_envoi?.statut_validation;
    },
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    onRetourValidation() {
      return this.updateSupportStatut({
        support_id: this.bat_envoi.support_id,
        statut: this.statutToApi(),
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    onUpdateEmail() {
      return this.updateSupportDetailsInfosBD({
        support_id: this.bat_envoi.support_id,
        envoi_lien: this.lien_miroir,
      })
        .then(() =>
          this.updateSupportStatut({
            support_id: this.bat_envoi.support_id,
            statut: SUPPORT_STATUS.STATS_A_FAIRE,
          })
        )
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
  },
  mounted() {
    this.bat_envoi = this.workflow?.etapes?.BAT || {};
  },
  watch: {
    workflow: {
      handler(val) {
        this.bat_envoi = { ...val?.etapes?.BAT };
      },
      deep: true,
    },
    "bat_envoi.statut_validation": {
      handler(val) {
        if (val) this.bat_envoi.commentaire_validation = null;
      },
    },
  },
};
</script>
