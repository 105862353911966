import { api } from "../../utils/api";

import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_LOADING = "SET_LOADING";

export const mutations = {};

export const actions = {
  uploadsFiles({ commit }, data) {
    const formData = new FormData();

    data.forEach(file => formData.append("attachment", file, file.name));

    return api({
      method: "POST",
      url: "/uploads",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },

  editBrief({ commit }, data) {
    return api
      .put(`/briefs/${data.brief_id}`, { ...data })
      .then(res => {
        return res.data.data;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
};

export const getters = {
  supportBrief: state => {
    return state.globals.workflow?.etapes?.supportBrief;
  },
};
