import { api } from "../../utils/api";
import { SET_LOADING } from "./globals";

export const mutations = {};
export const actions = {
  addSupportEtapeBD({ commit }, data) {
    commit(SET_LOADING, true);
    let formData = new FormData();
    formData.append("etape", JSON.stringify(data));
    if (data.visuel) formData.append("etape_visuel", data.visuel);
    return api
      .put(`/support-etapes/${data.support_etape_id}`, formData)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  updateSupportEtapeInfos({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .put(`/support-etapes/infos/${data.support_etape_id}`, data)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        console.log("res from update infos etape", res);
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  getSupportEtapes({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .get(`/support-etapes/support/${data.support_id}`, data)
      .then(res => {
        commit(SET_LOADING, false);
        return res.data;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
  addCommentaireDB({ commit }, data) {
    commit(SET_LOADING, true);
    return api
      .post("/commentaires", data)
      .then(() => commit(SET_LOADING, false))
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
};
export const getters = {};
