<template>
  <tr>
    <td data-label="Nom">
      <span>{{ boite.nom }}</span>
    </td>
    <td data-label="ID Adobe">
      <span>{{ boite.id_adobe }}</span>
    </td>
    <td data-label="Date de création">
      <span>{{ getDateFormat(boite.created_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Date de MAj">
      <span>{{ getDateFormat(boite.updated_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Description">
      <span>{{ boite.description }}</span>
    </td>
    <td data-label="Précoché">
      <span>{{
        boite.pre_selectionne === true ? "Oui" : boite.pre_selectionne === false ? "Non" : ""
      }}</span>
    </td>
    <td data-label="Actif">
      <span>{{ boite.is_actif ? "Oui" : "Non" }}</span>
    </td>
    <td class="buttons">
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.Boite.Details', params: { id: boite.referentiel_id } }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.Boite.Edit', params: { id: boite.referentiel_id } }"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Modifier</span>
      </router-link>
      <button class="btn tooltip-btn" @click="$emit('deleteBoite', boite.referentiel_id)">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
      <button
        class="btn tooltip-btn"
        @click="$emit('desactiverBoite', boite.referentiel_id, !boite.is_actif)"
      >
        <i class="fal fa-power-off"></i>
        <span class="tooltip-text">{{ boite.is_actif ? "Désactiver" : "Activer" }} </span>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ItemBoite",
  emits: ["deleteBoite", "desactiverBoite"],
  props: {
    boite: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    getDateFormat(d, format) {
      return d ? this.$moment(d).format(format) : "-";
    },
  },
};
</script>
