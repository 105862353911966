<template>
  <tr>
    <td>
      <span>{{ user.nom }}</span>
    </td>
    <td>
      <span>{{ user.prenom }}</span>
    </td>
    <td>
      <span>{{ user.email }}</span>
    </td>
    <td>
      <span>{{ user.role }}</span>
    </td>
    <td>
      <button :class="['switch', user.disabled ? 'disabled' : 'enabled']" @click="$emit('toggleDisable', user.user_id)">
        <div className="switch-option">
          <i className="fas fa-unlock-alt"></i>
        </div>
        <div className="switch-option">
          <i className="fas fa-lock-alt"></i>
        </div>
        <span className="switch-state"></span>
      </button>
    </td>
    <td>
      <button class="btn tooltip-btn" @click="$emit('resetPass', user.email)">
        <i class="fal fa-envelope"></i>
        <span class="tooltip-text">Renvoyer l’email de création&nbsp;de&nbsp;compte</span>
      </button>
      <button class="btn tooltip-btn" @click="$emit('editUser', user.user_id)">
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Editer</span>
      </button>
      <button class="btn tooltip-btn" @click="$emit('deleteUser', user.user_id)">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ["toggleDisable", "resetPass", "editUser", "deleteUser"],
  name: "UserDetails",
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style></style>
