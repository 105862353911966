<template>
  <h2 class="titre-centre">Créer un Support : {{ typeSupportsText[typeSupport] }}</h2>
  <div class="content">
    <support-form />
  </div>
</template>

<script>
import { mixinAction } from "../utils";
import SupportForm from "./SupportForm.vue";
export default {
  components: { SupportForm },
  name: "CreateSupport",
  mixins: [mixinAction],
};
</script>

<style></style>
