<template>
  <div class="row justify-content-between" v-if="!loading">
    <div class="col-12 col-lg-12 col-xl-5">
      <div class="form-col-title">TYPE</div>
      <custom-multi-check-box
        v-if="isPlv"
        class="center"
        :buttons="optionsTypes"
        v-model="brief.type"
        ref="brief.type"
        :rules="validations.type"
        @change="onTypeChange"
      />
      <field-radio-buttons
        v-if="!isPlv"
        class="center"
        :buttons="optionsTypes"
        v-model="brief.type[0]"
        ref="brief.type"
        :rules="validations.type"
      />
      <field-dropzone-file
        class="inline"
        acceptFilesType="application/pdf"
        label="PDF du PLV existante"
        v-model="this.brief.plv_existante_path"
        v-if="isPlvExistante"
        :rules="validations.plv_existante"
        ref="validations.plv_existante"
      />
      <div v-if="hasProperties.objet">
        <custom-input-type
          class="inline"
          label="Objet * :"
          type="text"
          ref="brief.objet"
          v-model="brief.objet"
          :rules="validations.objet"
        />
        <div class="form-col-title">PRE HEADER</div>
        <custom-input-type
          class="inline"
          label="Texte * :"
          type="text"
          ref="brief.pre_header"
          v-model="brief.pre_header"
          :rules="validations.pre_header"
        />
      </div>
      <div v-else-if="hasProperties.sms">
        <div class="form-col-title">CONTENU DU SMS</div>
        <custom-text-area
          v-model="brief.pre_header"
          :rules="validations.pre_header"
          ref="brief.pre_header"
          :countLetters="true"
          :maxLength="149"
        />
      </div>

      <div v-if="hasProperties.logo">
        <div class="form-col-title">LOGO</div>
        <custom-multi-check-box
          class="center"
          :buttons="optionsLogo"
          v-model="brief.logo"
          ref="brief.logo"
          :rules="validations.logo"
        />
        <custom-input-type
          v-if="isEmail"
          class="inline"
          label="Lien :"
          type="link"
          ref="brief.logo_lien"
          v-model="brief.logo_lien"
        />
        <field-dropzone-file
          v-if="brief && brief.logo && brief.logo.includes('LOGO_AUTRE')"
          class="inline"
          label="Logo autre * :"
          v-model="brief.logo_autre"
          ref="brief.logo_autre"
          :rules="validations.logo_autre"
          acceptFilesType="['image/png', 'image/jpeg', 'image/jpg', 'image/svg']"
        />
        <custom-input-type
          class="inline"
          v-if="brief && brief.logo && brief.logo.includes('LOGO_AUTRE')"
          label="Autre Lien :"
          type="link"
          ref="brief.logo_autre_lien"
          v-model="brief.logo_autre_lien"
        />
      </div>
      <div v-if="hasProperties.entete">
        <div class="form-col-title">ENTÊTE</div>
        <div class="row">
          <div class="col-12 col-md">
            <field-dropzone-file
              class="inline"
              :label="`Visuel ${isEmail ? '*' : ''} :`"
              v-if="!brief.entete_visuel_referentiel"
              v-model="brief.entete_visuel"
              ref="brief.entete_visuel"
              :rules="validations.entete_visuel"
              acceptFilesType="['image/png', 'image/jpeg', 'image/jpg', 'image/svg']"
            />
          </div>

          <div
            class="col-12 col-md-auto"
            v-if="!(brief.entete_visuel + brief.entete_visuel_referentiel)"
          >
            <div class="text-center">ou</div>
          </div>
          <div v-if="!brief.entete_visuel" class="col-12 col-md">
            <modal-select-image :images="referentiels" v-model="brief.entete_visuel_referentiel" />
          </div>
        </div>

        <custom-input-type
          class="inline"
          label="Accroche :"
          type="text"
          ref="brief.entete_accroche"
          v-model="brief.entete_accroche"
        />

        <div class="row">
          <div class="col-12 col-md-6">
            <custom-input-type
              class="inline"
              label="CTA :"
              type="text"
              ref="brief.entete_cta"
              v-model="brief.entete_cta"
            />
          </div>
          <div class="col-12 col-md-6">
            <custom-input-type
              class="inline"
              label="URL :"
              type="text"
              ref="brief.entete_url"
              v-model="brief.entete_url"
            />
          </div>
        </div>
      </div>

      <div v-if="hasProperties.marche">
        <div class="form-col-title">MARCHÉ</div>
        <custom-multi-check-box class="center" :buttons="optionsMarche" v-model="brief.marche" />
      </div>

      <div>
        <div class="form-col-title">INFOS</div>
        <field-radio-buttons
          v-if="hasProperties.creation_page_groupama"
          label="Création page g.fr :"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.creation_page_groupama"
        />
        <field-radio-buttons
          :label="`Visuel cerise ${isPlv ? '*' : ''} :`"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.visuel_cerise"
          :rules="validations.visuel_cerise"
          ref="brief.visuel_cerise"
        />
        <field-radio-buttons
          v-if="isPlv"
          :label="`Logo triman *`"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.logo_triman"
          :rules="validations.logo_triman"
          ref="brief.logo_triman"
        />
        <field-radio-buttons
          v-if="hasProperties.bandeau_multicanal"
          label="Bandeau multicanal *:"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.bandeau_multicanal"
          :rules="validations.question_infos"
          ref="brief.bandeau_multicanal"
        />
        <field-radio-buttons
          v-if="hasProperties.mentions_orange_banque"
          label="Mentions orange banque *:"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.mentions_orange_banque"
          ref="brief.mentions_orange_banque"
          :rules="validations.question_infos"
        />
        <field-radio-buttons
          v-if="hasProperties.refm"
          label="REFM *:"
          class="inline glue"
          :buttons="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          v-model="brief.refm"
          ref="brief.refm"
          :rules="validations.question_infos"
        />
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xl-6">
      <div class="form-col-title" v-if="isEmail">Contenu</div>
      <div class="form-col-title" v-else-if="isRichSMS">Contenu de la page Rich Sms</div>
      <div class="form-col-title" v-else>Contenu</div>
      <custom-editor-text
        v-model="brief.contenu"
        ref="brief.contenu"
        :rules="validations.contenu"
      />
<br/>
      <field-drop-zone-file-multiple
        :hasLink="true"
        v-if="hasProperties.visuels"
        label="Pièces jointes:"
        class="inline"
        type="file"
        v-model="brief.visuels"
        ref="brief.visuels"
        @change="handleFileChange"
      />
      <p v-if="fileError" style="color: red;">{{ fileError }}</p>
      <br/>
      <div class="row">
        <div class="col-12 col-lg-6" v-if="hasProperties.perso">
          <field-select
            class="inline"
            searchable="true"
            :label="`Perso ${isEmail ? '*' : ''} :`"
            :options="[
              { label: 'Civ Nom Prénom', value: 'CIVILITE_NOM_PRENOM' },
              { label: 'Cher(e) sociétaire', value: 'SOCIETAIRE' },
              { label: 'Bonjour', value: 'BONJOUR' },
              { label: 'Rien', value: 'RIEN' },
              { label: 'Autres', value: 'AUTRES' },
            ]"
            v-model="brief.perso"
            ref="brief.perso"
            :rules="validations.perso"
          />
        </div>
        <div class="col-12 col-lg-6" v-if="hasProperties.signature">
          <field-select
            class="inline"
            searchable="true"
            :label="`Signature ${isEmail ? '*' : ''}:`"
            :options="[
              { label: 'Votre conseiller(e)', value: 'CONSEILLER' },
              { label: 'Votre chargé(e) de clientèle', value: 'CH_CLI' },
              { label: 'Chargé(e) de clientèle Agricole', value: 'CH_CLI_AGRICOLE' },
              {
                label: 'Chargé de clientèle Artisans commerçants',
                value: 'CH_CLI_ARTISAN_COMMERCANT',
              },
              { label: 'Chargé d\'affaires agricoles', value: 'CH_AFF_AGRICOLE' },
              { label: 'Chargé d\'affaires collectivités', value: 'CH_AFF_COLLECTIVE' },
              { label: 'Chargé d\'affaires entreprises', value: 'CH_AFF_ENTREPRISE' },
              { label: 'Groupama Loire Bretagne', value: 'Groupama_Loire_Bretagne' },
              { label: 'Autres', value: 'AUTRES' },
              { label: 'Rien', value: 'RIEN' },
            ]"
            v-model="brief.signature"
            ref="brief.signature"
            :rules="validations.signature"
          />
        </div>
      </div>
    </div>
    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
      <button class="btn btn-secondary" @click="onSubmit(true)">Brouillon</button>
      <button class="btn btn-secondary" @click="onSubmit(false)" :disabled="!canValidate">
        Valider
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../../components/customize/FieldSelect.vue";

import Loading from "../../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../../utils/validate";

import { map, isNumber, isString } from "lodash";

import CustomCheckBox from "../../../../components/customize/CustomCheckBox.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import SelectImage from "../../../../components/customize/SelectImage.vue";
import ModalSelectImage from "../../../../components/ModalSelectImage.vue";
import FieldDropZoneFileMultiple from "../../../../components/customize/FieldDropZoneFileMultiple.vue";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import CustomMultiCheckBox from "../../../../components/customize/CustomMultiCheckBox.vue";
import { mixinAction } from "../../utils";
import { SUPPORT_TYPES } from "../../../../../../bigoudenn-api/app/configs/Properties";
import CustomTextArea from "../../../../components/customize/CustomTextArea.vue";

export default {
  components: {
    CustomInputType,
    Loading,
    FieldRadioButtons,
    FieldSelect,
    CustomCheckBox,
    FieldDropzoneFile,
    FieldDropZoneFileMultiple,
    SelectImage,
    ModalSelectImage,
    CustomEditorText,
    CustomMultiCheckBox,
    CustomTextArea,
  },
  name: "FormCreateBrief",
  mixins: [mixinAction],
  props: {
    briefModel: {
      type: Object,
      default() {
        return {
          type: ["NOUVELLE_CREATION"],
          contenu: `[PERSO],<br><br>

                Votre texte ici<br><br>

                [SIGNATURE]`,
          objet: null,
          logo: ["GLB"],
          pj: [],
          logo_lien: null,
          logo_autre_lien: null,
          logo_autre: null,
          pre_header: null,
          entete_accroche: null,
          entete_visuel: null,
          entete_visuel_type: "UPLOAD",
          entete_visuel_referentiel: null,
          entete_cta: null,
          entete_url: null,
          creation_page_groupama: false,
          perso: null,
          signature: null,
          visuels: [null],
          visuel_cerise: null,
          logo_triman: null,
          bandeau_multicanal: null,
          marche: [],
          mentions_orange_banque: null,
          refm: null,
        };
      },
    },
    hasProperties: {
      type: Object,
      default: {},
      visuels: true,
      doc: ["fileData"],
    },
  },
  data() {
    return {
      fileURL: "",
      isImage: false,
      isText: false,
      files: [],
      fileData: [],
      selectedFiles: [],
      fileError: null,
      optionsLogo: [
        { label: "Groupama", value: "GROUPAMA" },
        { label: "GLB", value: "GLB" },
        { label: "Entreprise", value: "ENTREPRISE" },
        { label: "Epargne", value: "EPARGNE" },
        { label: "Autre", value: "LOGO_AUTRE" },
      ],
      brief: {
        ...this.briefModel,
      },
      formValid: true,
    };
  },
  computed: {
    users() {
      console.log("files", this.files);
      return map(this.usersListe, u => {
        return {
          label: `${u.prenom} ${u.nom}`,
          value: u.user_id,
        };
      });
    },
    Liste() {
      return this.$store.state.maListe;
    },
    referentiels() {
      return this.referentielImagesListe;
    },
    validations() {
      return {
        logo: {
          required: "Merci de renseigner un type de logo",
        },
        objet: {
          required: "Merci de renseigner un objet",
        },
        entete_visuel: {
          requiredIf: {
            model: this.brief.entete_visuel_referentiel === null && this.isEmail,
            message: "Merci de renseigner un visuel pour l'entête",
          },
        },
        pre_header: {
          required: "Merci de renseigner un texte",
        },
        perso: {
          requiredIf: {
            model: this.isEmail,
            message: "Merci de renseigner un perso",
          },
        },
        signature: {
          requiredIf: {
            model: this.isEmail,
            message: "Merci de renseigner une signature",
          },
        },
        visuels: {
          required: "Merci de renseigner un visuel",
        },
        type: {
          required: "Merci de renseigner un type",
        },
        contenu: {
          required: "Merci de renseigner un contenu",
        },
        question_infos: {
          required: "Merci de répondre à la question",
        },
        logo_autre: {
          requiredIf: {
            model: this.brief.logo && this.brief.logo.includes("LOGO_AUTRE") && this.brief.logo,
            message: "Merci de renseigner un logo",
          },
        },
        visuel_cerise: {
          requiredIf: {
            model: this.brief.entete_visuel || this.isPlv,
            message: "Merci de renseigner Visuel Cerise",
          },
        },
        logo_triman: {
          requiredIf: {
            model: this.brief.entete_visuel || this.isPlv,
            message: "Merci de renseigner Logo Triman",
          },
        },
        plv_existante: {
          accept: {
            message: "Les formats autorisés sont que de pdf",
            extensions: ["pdf"],
          },
        },
        image: {
          weight: {
            max: 5, // en Mb
            message: "La taille maximum du fiche doit être 5 Mb",
          },
        },
      };
    },
    canValidate() {
      if (this.hasAction) return true;
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
          return this.supportEquipe?.CM?.user_id === this.currentUser?.user_id;
        case SUPPORT_TYPES.PLV:
          return this.supportEquipe?.COM?.user_id === this.currentUser?.user_id;
        default:
          return false;
      }
    },
    optionsTypes() {
      switch (this.workflow.type) {
        case SUPPORT_TYPES.EMAIL:
          return [
            { label: "Nouvelle création", value: "NOUVELLE_CREATION" },
            { label: "Email existant ", value: "EMAIL_EXISTANT" },
            { label: "GMA ", value: "GMA" },
          ];
        case SUPPORT_TYPES.RICH_SMS:
          return [
            { label: "Nouvelle création", value: "NOUVELLE_CREATION" },
            { label: "Rich Sms existant ", value: "RICHSMS_EXISTANT" },
          ];
        case SUPPORT_TYPES.PLV:
          return [
            { label: "Nouvelle création", value: "NOUVELLE_CREATION" },
            { label: "PLV existante GLB", value: "PLV_EXISTANT_GLB" },
            { label: "PLV existante GMA/GGVIE", value: "PLV_EXISTANT_GMA" },
            { label: "Adaptation", value: "ADAPTATION" },
            { label: "Temporaire", value: "TEMPORAIRE" },
            { label: "Pérenne", value: "PERENNE" },
          ];

        default:
          return [];
      }
    },
    optionsMarche() {
      return [
        { label: "PART", value: "PART" },
        { label: "AGRI", value: "AGRI" },
        { label: "ACPS", value: "ACPS" },
        { label: "COLLECTIVITÉ", value: "COLLECTIVITE" },
        { label: "ENT", value: "ENT" },
        { label: "CFI / CGP", value: "CFI_CGP" },
        { label: "BANQUE", value: "BANQUE" },
        { label: "AUTRE", value: "AUTRE" },
      ];
    },
    isPlvExistante() {
      return this.brief?.type?.some(t =>
        ["PLV_EXISTANT_GLB", "PLV_EXISTANT_GMA", "ADAPTATION"].includes(t)
      );
    },
  },
  watch: {
    "brief.entete_visuel_referentiel": {
      handler(val) {
        if (val) {
          this.brief.entete_visuel = null;
        }
      },
    },
    "brief.entete_visuel": {
      handler(val) {
        if (val) {
          this.brief.entete_visuel_referentiel = null;
        }
      },
    },
  },
  methods: {
    ...mapActions(["editBrief", "setWorkflow", "uploadsFiles", "setLoading", "addCiblageBD"]),
    onSubmit(isBrouillon) {
      if (!isBrouillon) {
        this.checkValidations();
        if (!this.formValid) {
          return window.scroll(0, 0);
        }
      }
      const uploadsFiles = [];

      function formatFileName(file) {
        console.log("Format file", file);
        if (file.name !== undefined || file.name != null) {
          let name = file.name.replace("&", "");
          return new File([file], `${moment().format("YYYYMMDD-HHmmss")}_${name}`);
        } else {
        return new File([file], file);
        }
      }
      if (this.brief.entete_visuel && !isString(this.brief.entete_visuel)) {
        uploadsFiles.push(
          (this.brief.entete_visuel.name && formatFileName(this.brief.entete_visuel)) ||
            this.brief.entete_visuel
        );
      }
      if (this.brief.plv_existante_path && !isString(this.brief.plv_existante_path)) {
        uploadsFiles.push(
          (this.brief.plv_existante_path.name && formatFileName(this.brief.plv_existante_path)) ||
            this.brief.plv_existante_path
        );
      }

      if (this.brief.logo_autre && !isString(this.brief.logo_autre)) {
        uploadsFiles.push(
          (this.brief.logo_autre.name && formatFileName(this.brief.logo_autre)) ||
            this.brief.logo_autre
        );
      }

      if (this.brief.visuels) {
        map(this.brief.visuels, (v) => {
          if (v && v instanceof File){ // Fixed maj Node 20 : il faut vérifier que la valeur retournée est bien un File sinon le contenu du fichier est remplacé par une string
            return uploadsFiles.push(formatFileName(v)); 
          }
        });
      }
      const visuelsArray =
        (this.brief.visuels &&
          this.brief.visuels[0] &&
          map(this.brief.visuels, v => (v.name && formatFileName(v).name) || v)) ||
        [];

      const briefData = {
        ...this.brief,
        isBrouillon: isBrouillon,
        brief_id: this.workflow.etapes.BRIEF.brief_id,
        entete_visuel:
          (this.brief.entete_visuel && this.brief.entete_visuel.name
            ? formatFileName(this.brief.entete_visuel).name
            : this.brief.entete_visuel) || null,
        entete_visuel_referentiel: this.brief.entete_visuel_referentiel || null,
        entete_visuel_type:
          this.brief.entete_visuel_referentiel && isNumber(this.brief.entete_visuel_referentiel)
            ? "REFERENTIEL"
            : "UPLOAD",
        logo: this.brief.logo.join("|"),
        logo_autre:
          (this.brief.logo_autre && this.brief.logo_autre.name
            ? formatFileName(this.brief.logo_autre).name
            : this.brief.logo_autre) || null,
        visuels: visuelsArray.join("|"),
        type: this.brief.type.join("|"),
        marche: this.brief.marche.join("|"),
        validation_brief_user: this.currentUser,
        validation_date: this.$moment(),
        // fromFicheData: true,
        plv_existante_path:
          (this.brief.plv_existante_path && this.brief.plv_existante_path.name
            ? formatFileName(this.brief.plv_existante_path).name
            : this.brief.plv_existante_path) || null,
      };

      return this.setLoading(true)
        .then(() => {
          if (uploadsFiles && uploadsFiles.length > 0) {
            this.uploadsFiles(uploadsFiles);
          }
        })
        .then(() => this.editBrief(briefData))
        .then(() => {
          return this.$router.push(this.backRoute);
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        })
        .then(() => this.setLoading(false));
    },
    handleFileChange() {
    this.fileError = null;
    const allowedFormats = ["csv", "xls", "xlsx"]; // Ajoutez les formats autorisés
    const files = this.$refs["brief.visuels"].files;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      if (fileName !== undefined) {
      const fileFormat = fileName.split(".").pop().toLowerCase();
      if (allowedFormats.includes(fileFormat)) {
        this.fileError = `Le fichier ${fileName} n'est pas au format autorisé (PNG, JPG, PDF, TXT).`;
        // Réinitialiser la valeur du champ pour empêcher l'ajout du fichier non autorisé
        this.brief.visuels = [];
        return;
      }
    }
    }
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    onTypeChange() {
      let newType = this.brief.type[this.brief.type.length - 1];
      switch (newType) {
        case "PERENNE":
          this.brief.type = this.brief.type.filter(t => t !== "TEMPORAIRE");
          break;
        case "TEMPORAIRE":
          this.brief.type = this.brief.type.filter(t => t !== "PERENNE");
          break;
        // break;
        case "NOUVELLE_CREATION":
        case "PLV_EXISTANT_GLB":
        case "PLV_EXISTANT_GMA":
        case "ADAPTATION":
          this.brief.type = this.brief.type.filter(t => ["TEMPORAIRE", "PERENNE"].includes(t));
          this.brief.type.push(newType);
          break;
        default:
          break;
      }
    },

    previewFiles(event) {
          this.files = [];
          const selectedFiles = event.target.files;
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = event.target.files[i];
            const fileData = {
              url: URL.createObjectURL(file),
              isImage: file.type.startsWith("image/"),
              isText: file.type.startsWith("text/")
            };
            this.files.push(fileData);
            this.brief.visuels.push(fileData);
          }
    },
  },
};
</script>

<style></style>
