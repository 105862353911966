<template>
  <div class="calendar">
    <vue-cal
      locale="fr"
      :time="false"
      active-view="month"
      :disable-views="['years', 'day']"
      :events="events"
      :selected-date="selectedDate"
      events-on-month-view="large"
      events-count-on-year-view
      :on-event-click="onEventClick"
    >
    </vue-cal>
    <button @click="selectedDate = new Date()" class="vuecal__view-btn btn-today">
      Aujourd'hui
    </button>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
// import "vue-cal/dist/i18n/fr.cjs";

export default {
  components: { VueCal },
  name: "CalendarEvents",
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedDate: new Date(),
  }),
  methods: {
    onEventClick(event, e) {
      this.$router.push({
        name: "Workflow.WorkflowEtape",
        params: { etape: event.goTo, id: event.support_id },
      });
    },
  },
};
</script>
<style>
.vuecal {
  box-shadow: none;
  width: 100%;
  min-height: 600px;
}
.vuecal__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.vuecal__header .vuecal__menu {
  background-color: transparent;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.16);
}
.vuecal__view-btn,
.vuecal__arrow {
  border: 1px solid #ced4da;
  background-color: #fff;
  padding: 8px 14px;
  line-height: 1;
  height: auto;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
  border-radius: 6px;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.16);
}
.vuecal__view-btn.vuecal__view-btn--active {
  background-color: #006a53;
  color: #fff;
}
.vuecal__header .vuecal__menu .vuecal__view-btn {
  border-radius: 0;
  box-shadow: none;
}
.vuecal__header .vuecal__menu .vuecal__view-btn:first-child,
.vuecal__arrow--prev {
  border-radius: 6px 0 0 6px;
}
.vuecal__header .vuecal__menu .vuecal__view-btn:last-child,
.vuecal__arrow--next {
  border-radius: 0 6px 6px 0;
}
.vuecal__header .vuecal__title-bar {
  order: -1;
  background-color: transparent;
  margin-left: 124px;
  line-height: 1;
  min-height: unset;
}
.vuecal__header .vuecal__title-bar .vuecal__title {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 80px;
}
.vuecal__header .vuecal__weekdays-headings {
  flex: 1 0 100%;
  margin-top: 50px;
  padding: 0;
  background-color: #e5e5e5;
}
.vuecal--year-view .vuecal__body {
  margin-top: 50px;
}
.vuecal__event {
  cursor: pointer;
  color: white;
  margin-bottom: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 3px;
  text-align: left;
}

.vuecal__event.blue-event {
  background-color: rgba(0, 100, 255, 0.5);
}
.vuecal__event.green-event {
  background-color: #006a53;
}
.vuecal__event.red-event {
  background-color: rgba(255, 0, 0, 0.5);
}

.vuecal__cell-events {
  padding: 3px;
}
.vuecal__cell-events-count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 30px;
  height: 30px;
  background-color: #006a53;
}
.vuecal__cells.month-view .vuecal__cell,
.vuecal__cells.week-view .vuecal__cell,
.vuecal__cells.years-view .vuecal__cell {
  min-height: 80px;
}
.vuecal--month-view .vuecal__cell-content,
.vuecal--year-view .vuecal__cell-content,
.vuecal--years-view .vuecal__cell-content {
  justify-content: flex-start;
}
.vuecal__cell-date {
  text-align: right;
  padding: 5px 7px 0 0;
  font-size: 14px;
}
.vuecal--month-view .vuecal__event-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.vuecal--month-view .vuecal__event-content {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 1.2;
  overflow: hidden;
}

.btn-today {
  order: -1;
  position: absolute;
}
.btn-today:active {
  transform: translateY(3px);
}
</style>
