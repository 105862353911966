import { createApp } from "vue";

import DOMPurify from "dompurify";
import CKEditor from "@ckeditor/ckeditor5-vue";
import moment from "moment-business-days";

import lodash from "lodash";
import router from "./router";
import App from "./App.vue";
import store from "./store";

import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

import "./assets/styles/app.scss";
moment.locale("fr");
// Jours fériés en france
moment.updateLocale("fr", {
  holidays: ["14-07", "01-05", "08-05", "15-08", "01-11", "25-12", "11-11"],
  holidayFormat: "DD-MM",
});

const preload = async () => {
  await store.dispatch("getLoggedUser");
  const app = createApp(App);
  app.config.globalProperties.$moment = moment;
  app.use(moment);
  app.config.globalProperties.$lodash = lodash;
  app.use(lodash);
  app.use(Donut);

  // utilisation de  VueRouter
  app.use(router);

  // utilisation de stor  { state, actions, mutations}
  app.use(store);
  app.use(CKEditor);

  // Mountage de l'app
  app.mount("#root");

  app.directive("sane-html", (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  });
};

// creation de l'App
preload();
